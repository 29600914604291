import * as React from "react";
import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import { Field, Formik, Form } from "formik";
import { Select } from "formik-mui";
import { Chip } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import * as Yup from "yup";
import { TextField } from "formik-mui";
import LinearProgress from "@mui/material/LinearProgress";
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { useStateValue } from "../utils/app-state-context";
import Header from "../components/Header";
import { API_URL } from "../utils/api";
import { useFeedBack, FeedBack } from "../components/FeedBack";
import UsernamePasswordModal, {
  useUsernamePasswordModal,
} from "../components/UsernamePasswordModal";
import { nigerianLgas, nigerianStates } from "../utils/stateAndLga";
import { nigerianLgasCodes } from "../utils/statesLgaCode";

export default function DonorProfile() {
  const [selectedNames, setSelectedNames] = React.useState([]);
  const navigate = useNavigate();
  const [{ authDonor, facilityId, donorId }, dispatch] = useStateValue();
  const { openSnack, setOpenSnack, msg, setMsg, handleCloseSnackbar } =
    useFeedBack();
  const [selectedState, setSelectedState] = React.useState();
  const [selectedLga, setSelectedLaselectedLga] = React.useState();
  const handleStateChange = (selectedState) => setSelectedState(selectedState);
  const handleCreateId = (e) => setSelectedLaselectedLga(e);
  React.useEffect(() => {
    if (!authDonor) {
      navigate("/sign-in");
    }
  }, [authDonor]);
  React.useEffect(() => {
    if (!authDonor) {
      navigate("/sign-in");
    }
  }, [authDonor]);
  const howUKnow = [
    "A relative",
    "The Media Television",
    "Radio",
    "Newspaper",
    "Poster",
    "Friends",
    "A Blood Donor",
    "Awareness Health Talk Meeting",
    "Blood Donor Center",
    "Others",
  ];
  return (
    <React.Fragment>
      <Header />
      <FeedBack
        openSnack={openSnack}
        msg={msg}
        handleCloseSnackbar={handleCloseSnackbar}
      />
      <Box
        sx={{
          maxWidth: 500,
          margin: "auto",
          p: 2,
        }}
      >
        <Formik
          enableReinitialize={true}
          initialValues={{
            fname: "",
            surname: "",
            gender: "",
            telephone: "",
            email: "",
            age: "",
            occupation: "",
            previousSurname: "",
            nationality: "",
            dob: "",
            maritalStatus: "",
            formOfIdentification: "",
            homeAdd: "",
            employer: "",
            placeOfBirth: "",
            tribe: "",
            stateOfOrigin: "",
            lga: "",
            howToBeReminded: "",
            donatedB4: "",
            everBeenRefusedBlood: "",
            donatedAtAnotherService: "",
            whereDonatedAtAnotherService: "",
            whenDonatedAtAnotherService: "",
            noOfPrevoiusDonation: "",
            areUStudent: "",
            howDidULearnAboutDonation: null,
          }}
          validationSchema={Yup.object({
            dob: Yup.string().required("*Date of birth is required"),
            nationality: Yup.string().required("*Nationality is required"),
            maritalStatus: Yup.string().required("*Marital status is required"),
            formOfIdentification: Yup.string().required(
              "*Form of identification is required"
            ),
            homeAdd: Yup.string().required("*home address is required"),
            employer: Yup.string().required("*Employers name is required"),
            placeOfBirth: Yup.string().required("*This field is required"),
            tribe: Yup.string().required("*This field is required"),
            stateOfOrigin: Yup.string().required("*This field is required"),
            howToBeReminded: Yup.string().required("*This field is required"),
            donatedB4: Yup.string().required("*This field is required"),
            everBeenRefusedBlood: Yup.string().required(
              "*This field is required"
            ),
            donatedAtAnotherService: Yup.string().required(
              "*This field is required"
            ),
            // whereDonatedAtAnotherService: Yup.string().required(
            //   "*This field is required"
            // ),
            // whenDonatedAtAnotherService: Yup.string().required(
            //   "*This field is required"
            // ),
            // noOfPrevoiusDonation: Yup.string().when("donatedB4", {
            //   is: "Yes",
            //   then: Yup.string().required("*This field is required"),
            //   otherwise: () => Yup.string().nullable(),
            // }),

            areUStudent: Yup.string().required("*This field is required"),
            // howDidULearnAboutDonation: Yup.string().required(
            //   "*This field is required"
            // ),
            fname: Yup.string().required("*First name is required"),
            mname: Yup.string().required("*Middle name is required"),
            surname: Yup.string().required("*Surname name is required"),
            gender: Yup.string().required("*Gender is required"),
            telephone: Yup.string().required("*Telephone is required"),
            email: Yup.string().required("*Email is required"),
            age: Yup.string().required("*Age is required"),
            occupation: Yup.string().required("*Occupation name is required"),
          })}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            const codes =
              selectedState &&
              selectedLga &&
              nigerianLgasCodes(selectedState, selectedLga);
            const assignedId = `${codes.stateCode}/${codes.lgaCode}/${donorId}`;
            try {
              const response = await fetch(`${API_URL}/dornorProfile`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  ...values,
                  userId: donorId,
                  whenDonatedAtAnotherService:
                    values.donatedAtAnotherService === "Yes"
                      ? values.whenDonatedAtAnotherService
                      : "",
                  whereDonatedAtAnotherService:
                    values.donatedB4 === "Yes"
                      ? values.whereDonatedAtAnotherService
                      : "",
                  noOfPrevoiusDonation:
                    values.donatedB4 === "Yes"
                      ? values.noOfPrevoiusDonation
                      : "",
                  createdBy: facilityId || "Self",
                  howDidULearnAboutDonation: selectedNames.toString(),
                  // assignedId,
                }),
              });
              if (!response.ok) {
                throw new Error(response.status);
              }
              const res = await response.json();
              dispatch({
                type: "USER-PROFILE",
                payload: {
                  userProfile: res,
                },
              });
              if (res.length !== 0) {
                setMsg(`Donor's Profile Created Successfully.`);
                setOpenSnack(true);
                resetForm();
              }
              setTimeout(() => navigate("/donor-dashboard"), 9000);
              setSubmitting(false);
            } catch (e) {
              console.error("Error adding document: ", e);
              setSubmitting(false);
            }
          }}
        >
          {({
            submitForm,
            isSubmitting,
            values,
            setSubmitting,
            handleChange,
          }) => (
            <Form>
              <Box
                sx={{
                  borderRadius: 5,
                  width: "100%",
                  margin: "auto",
                  // background:
                  //   "linear-gradient(135deg, rgba(0,127,128,0.47942927170868344) 7%, rgba(118,148,216,0.4962359943977591) 35%, rgba(162,231,227,0.4990371148459384) 60%, rgba(229,255,239,1) 93%)",
                }}
              >
                {" "}
                {isSubmitting && <LinearProgress sx={{ my: 2 }} />}
                <Stack
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                  style={{ margin: 10 }}
                >
                  <h2
                    component="h1"
                    noWrap
                    style={{ marginTop: "20", marginBottom: "20" }}
                  >
                    Create Your Profile
                  </h2>
                  <FormControl fullWidth>
                    <Field
                      component={TextField}
                      fullWidth
                      name="fname"
                      type="text"
                      size="small"
                      label="First Name"
                      variant="standard"
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      component={TextField}
                      fullWidth
                      name="mname"
                      size="small"
                      label="Middle Name"
                      variant="standard"
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      component={TextField}
                      fullWidth
                      name="surname"
                      size="small"
                      label="Surname Name"
                      variant="standard"
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      component={TextField}
                      fullWidth
                      name="previousSurname"
                      size="small"
                      label="Previous Surname"
                      variant="standard"
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      component={TextField}
                      fullWidth
                      name="nationality"
                      size="small"
                      label="Nationality"
                      variant="standard"
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      sx={{ mt: 1 }}
                      component={TextField}
                      name="dob"
                      type="date"
                      size="small"
                      label="Date of Birth"
                      variant="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      component={Select}
                      name="gender"
                      size="small"
                      label="Gender"
                      variant="standard"
                    >
                      <MenuItem value={"Male"}>Male</MenuItem>
                      <MenuItem value={"Female"}>Female</MenuItem>
                    </Field>
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      component={Select}
                      name="formOfIdentification"
                      size="small"
                      label="Form of Identification"
                      variant="standard"
                    >
                      <MenuItem value={"Driving License"}>
                        Driving License
                      </MenuItem>
                      <MenuItem value={"National Identification Number (NIN)"}>
                        National Identification Number (NIN)
                      </MenuItem>
                      <MenuItem value={"Voter's Card"}>Voters Card</MenuItem>
                      <MenuItem value={"International Passport"}>
                        International Passport
                      </MenuItem>
                    </Field>
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      component={Select}
                      name="maritalStatus"
                      size="small"
                      label="Marital Status"
                      variant="standard"
                    >
                      <MenuItem value={"Single"}>Single</MenuItem>
                      <MenuItem value={"Married"}>Married</MenuItem>
                      <MenuItem value={"Widow/Widower"}>Widow/Widower</MenuItem>
                      <MenuItem value={"Divorced"}>Divorced</MenuItem>
                    </Field>
                  </FormControl>

                  <FormControl fullWidth>
                    <Field
                      component={TextField}
                      fullWidth
                      name="homeAdd"
                      size="small"
                      label="Home Address"
                      variant="standard"
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      component={TextField}
                      fullWidth
                      name="telephone"
                      size="small"
                      label="Telephone"
                      variant="standard"
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      component={TextField}
                      fullWidth
                      name="employer"
                      size="small"
                      label="Employer"
                      variant="standard"
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      component={TextField}
                      fullWidth
                      name="placeOfBirth"
                      size="small"
                      label="Place of Birth"
                      variant="standard"
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      component={TextField}
                      fullWidth
                      name="tribe"
                      size="small"
                      label="Tribe"
                      variant="standard"
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      component={Select}
                      name="stateOfOrigin"
                      size="small"
                      label="State of Origin"
                      variant="standard"
                      onChange={(e) => handleStateChange(e.target.value)}
                    >
                      {nigerianStates()}
                    </Field>
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      fullWidth
                      name="lga"
                      size="small"
                      label="Local Government Area"
                      variant="standard"
                      onChange={(e) => handleCreateId(e.target.value)}
                    >
                      {selectedState && nigerianLgas(selectedState)}
                    </Field>
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      component={Select}
                      name="howToBeReminded"
                      size="small"
                      label="How you would like to be reminded to donate blood?"
                      variant="standard"
                      helperText="(How you would like to be reminded to donate blood?)"
                    >
                      <MenuItem value={"SMS"}>SMS</MenuItem>
                      <MenuItem value={"Phone"}>Phone</MenuItem>
                      <MenuItem value={"Email"}>Email</MenuItem>
                    </Field>
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      component={Select}
                      name="donatedB4"
                      size="small"
                      label="Have you donated blood before?"
                      variant="standard"
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Field>
                  </FormControl>
                  {values.donatedB4 === "Yes" && (
                    <FormControl fullWidth>
                      <Field
                        component={TextField}
                        fullWidth
                        name="whereDonatedAtAnotherService"
                        size="small"
                        label="Where did you donate?"
                        variant="standard"
                      />
                    </FormControl>
                  )}
                  <FormControl fullWidth>
                    <Field
                      component={Select}
                      name="everBeenRefusedBlood"
                      size="small"
                      label="Have you ever been refused to donate blood?"
                      variant="standard"
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Field>
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      component={Select}
                      name="donatedAtAnotherService"
                      size="small"
                      label="Have you ever donated at another service?"
                      variant="standard"
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Field>
                  </FormControl>

                  {values.donatedAtAnotherService === "Yes" && (
                    <FormControl fullWidth>
                      <Field
                        component={TextField}
                        fullWidth
                        name="whenDonatedAtAnotherService"
                        size="small"
                        label="When did you donate at another service?"
                        variant="standard"
                      />
                    </FormControl>
                  )}
                  {values.donatedB4 === "Yes" && (
                    <FormControl fullWidth>
                      <Field
                        component={TextField}
                        fullWidth
                        name="noOfPrevoiusDonation"
                        size="small"
                        label="Number of previous donations"
                        variant="standard"
                      />
                    </FormControl>
                  )}
                  <FormControl fullWidth>
                    <Field
                      component={Select}
                      name="areUStudent"
                      size="small"
                      label="Are you a student?"
                      variant="standard"
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Field>
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      component={Select}
                      multiple
                      name="howDidULearnAboutDonation"
                      size="small"
                      label="How did you learn about donation?"
                      variant="standard"
                      value={selectedNames}
                      onChange={(e) => setSelectedNames(e.target.value)}
                      renderValue={(selected) => (
                        <Stack gap={1} direction="row" flexWrap="wrap">
                          {selected.map((value) => (
                            <Chip
                              key={value}
                              label={value}
                              onDelete={() =>
                                setSelectedNames(
                                  selectedNames.filter((item) => item !== value)
                                )
                              }
                              deleteIcon={
                                <CancelIcon
                                  onMouseDown={(event) =>
                                    event.stopPropagation()
                                  }
                                />
                              }
                            />
                          ))}
                        </Stack>
                      )}
                    >
                      {howUKnow.map((name) => (
                        <MenuItem
                          key={name}
                          value={name}
                          sx={{ justifyContent: "space-between" }}
                        >
                          {name}
                          {selectedNames.includes(name) ? (
                            <CheckIcon color="info" />
                          ) : null}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      component={TextField}
                      fullWidth
                      name="email"
                      size="small"
                      label="Email"
                      variant="standard"
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      component={TextField}
                      fullWidth
                      name="age"
                      size="small"
                      type="number"
                      label="Age"
                      variant="standard"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      component={TextField}
                      fullWidth
                      name="occupation"
                      size="small"
                      label="Occupation"
                      variant="standard"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </FormControl>
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                  style={{ margin: 10 }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={submitForm}
                    style={{
                      margin: 20,
                      background: "#0C2340",
                      paddingLeft: "40px",
                      paddingRight: "40px",
                      textTransform: "none",
                    }}
                  >
                    {isSubmitting ? (
                      <span style={{ color: "yellow" }}>Submitting... </span>
                    ) : (
                      "Submit"
                    )}
                  </Button>
                  {isSubmitting && (
                    <Button onClick={() => setSubmitting(false)}>Cancel</Button>
                  )}
                </Stack>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </React.Fragment>
  );
}
