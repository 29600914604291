import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";

export default function MobileMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();
  return (
    <div>
      <IconButton
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{ color: "inherit" }}
      >
        <Tooltip title="Menu">
          <MenuIcon />
        </Tooltip>
      </IconButton>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={handleClose}>
          <Button sx={{ textTransform: "none" }} onClick={() => navigate("/")}>
            Home
          </Button>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Button
            sx={{ textTransform: "none" }}
            onClick={() => navigate("/about")}
          >
            About
          </Button>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Button
            sx={{ textTransform: "none" }}
            onClick={() => navigate("/contact")}
          >
            Contact
          </Button>
        </MenuItem>
      </Menu>
    </div>
  );
}
