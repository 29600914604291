import React, { Suspense } from "react";
import Header from "../components/Header";
import PhoneIcon from "@mui/icons-material/Phone";
import Skeleton from "@mui/material/Skeleton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
const Footer = React.lazy(() => import("../components/Footer"));

export function Skeleton2() {
  return (
    <>
      <Skeleton
        sx={{ height: 190, mb: 5 }}
        animation="wave"
        variant="rectangular"
      />
      <Skeleton
        sx={{ height: 190, mb: 5 }}
        animation="wave"
        variant="rectangular"
      />
    </>
  );
}
export default function Contact() {
  return (
    <>
      <Header />
      <p style={{marginLeft:20}}>For more information, please contact:</p>
      <List
          sx={{ width: "100%", maxWidth: 350, bgcolor: "background.paper" }}
        >
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar><PhoneIcon /></Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Olufemi Amoo, PhD"
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    +234 705 431 1237
                  </Typography>
                  </React.Fragment>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
            <Avatar><PhoneIcon /></Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Emmanuel Ejim-Eze"
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                   +234 904 723 0068
                  </Typography>                 
                </React.Fragment>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
            <Avatar><PhoneIcon /></Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Michael Awoyemi"
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    +234 706 426 7499
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
          </List>
      
      <Suspense fallback={<Skeleton2 />}>
        <div style={{position:"absolute", bottom:0, width:"100%"}}>
          <Footer />
        </div>
        
      </Suspense>
    </>
  );
}
