import * as React from "react";
import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import { Field, Formik, Form } from "formik";
import * as Yup from "yup";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { TextField, Select } from "formik-mui";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import { FormHelperText } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { useStateValue } from "../utils/app-state-context";
import Header from "../components/Header";
import { API_URL } from "../utils/api";
import { useFeedBack, FeedBack } from "../components/FeedBack";
import { nigerianLgas, nigerianStates } from "../utils/stateAndLga";

export default function FacilityProfile() {
  const [
    { activateProfileLink, authfacilityId, facilityId, authFacility },
    dispatch,
  ] = useStateValue();
  const navigate = useNavigate();
  const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };
  const { openSnack, setOpenSnack, msg, setMsg, handleCloseSnackbar } =
    useFeedBack();
  React.useEffect(() => {
    if (!authFacility) {
      return navigate("/facility/sign-in-facility");
    }

    if (facilityId) {
      return navigate("/facility-profile-view");
    }
  }, [facilityId, authFacility]);
  const [selectedState, setSelectedState] = React.useState();
  const handleStateChange = (selectedState) => setSelectedState(selectedState);

  return (
    <React.Fragment>
      <Header />
      <FeedBack
        openSnack={openSnack}
        msg={msg}
        handleCloseSnackbar={handleCloseSnackbar}
      />
      <Box
        sx={{
          maxWidth: 500,
          p: 1,
          margin: "auto",
          marginTop: 5,
          borderRadius: 5,
        }}
      >
        <Formik
          enableReinitialize={true}
          initialValues={{
            facilityName: "",
            privatePublic: "",
            stateFederal: "",
            facilityType: "",
            registered: "",
            registrationBody: "",
            registeredWithNBSC: "",
            bloodBankAvailable: "",
            telephone: "",
            email: "",
            addressStreet: "",
            addressTown: "",
            lga: "",
            addressState: "",
            contactPerson: "",
          }}
          validationSchema={Yup.object({
            facilityName: Yup.string().required(
              "*Name of Facility is required"
            ),
            privatePublic: Yup.string().required("*Facility Type is required"),
            // stateFederal: Yup.string().required("*Facility Type is required"),
            registeredWithNBSC: Yup.string().required(
              "*This field is required"
            ),
            facilityType: Yup.string().required("*Facility Type is required"),
            telephone: Yup.string().required("*Telephone is required"),
            email: Yup.string().required("*Email is required"),
            lga: Yup.string().required("*LGA is required"),
            addressState: Yup.string().required("*State is required"),
          })}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            try {
              const response = await fetch(`${API_URL}/facilityProfile`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  ...values,
                  facilityId: authfacilityId,
                }),
              });
              if (!response.ok) {
                throw new Error(response.status);
              }
              const res = await response.json();
              if (res) {
                setMsg(`Facility's Profile Created Successfully. `);
                setOpenSnack(true);
                resetForm();
              }
              setSubmitting(false);
              await sleep(900);
              navigate("/facility-created-successfully");
              dispatch({
                type: "PROFILE-NOT-CREATED",
                payload: {
                  profileNotCreated: true,
                },
              });
            } catch (e) {
              console.error("Error adding document: ", e);
              setSubmitting(false);
            }
          }}
        >
          {({ submitForm, isSubmitting, values, handleChange }) => (
            <Form>
              <Box
                sx={{
                  p: 2,
                  borderRadius: 5,
                  width: "100%",
                  margin: "auto",
                  // background:
                  //   "linear-gradient(135deg, rgba(0,127,128,0.47942927170868344) 7%, rgba(118,148,216,0.4962359943977591) 35%, rgba(162,231,227,0.4990371148459384) 60%, rgba(229,255,239,1) 93%)",
                }}
              >
                {" "}
                {isSubmitting && <LinearProgress sx={{ my: 2 }} />}
                <Stack
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                  style={{ margin: 10 }}
                >
                  <Typography
                    component="div"
                    noWrap
                    style={{ marginTop: "20", marginBottom: "20" }}
                  >
                    Create Facility's Profile
                  </Typography>

                  <Field
                    component={TextField}
                    fullWidth
                    name="facilityName"
                    type="text"
                    size="small"
                    label="Name of Facility "
                    variant="standard"
                  />
                  <FormHelperText error></FormHelperText>
                  <FormControl fullWidth>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="privatePublic"
                      size="small"
                      label="Is the Facility Government or Private?"
                      variant="standard"
                    >
                      <MenuItem value={"Government"}>Government</MenuItem>
                      <MenuItem value={"Private"}>Private</MenuItem>
                    </Field>
                  </FormControl>
                  {values.privatePublic == "Government" && (
                    <FormControl fullWidth>
                      <Field
                        sx={{ mb: 3 }}
                        component={Select}
                        name="stateFederal"
                        size="small"
                        label="Is it State Government or Federal Government?"
                        variant="standard"
                      >
                        <MenuItem value={"Federal Government"}>
                          Federal Government
                        </MenuItem>
                        <MenuItem value={"State Government"}>
                          State Government
                        </MenuItem>
                      </Field>
                    </FormControl>
                  )}
                  <FormControl fullWidth>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="facilityType"
                      size="small"
                      label="What is the facility type?"
                      variant="standard"
                    >
                      <MenuItem value={"Blood Bank"}>Blood Bank</MenuItem>
                      <MenuItem value={"Medical Centre"}>
                        Medical Centre
                      </MenuItem>
                      <MenuItem value={"Hospital"}>Hospital</MenuItem>
                      <MenuItem value={"Maternity Homes / Hospital"}>
                        Maternity Homes / Hospital
                      </MenuItem>
                      <MenuItem value={"Convalescent Home"}>
                        Convalescent Home
                      </MenuItem>
                      <MenuItem value={"Others"}>
                        Others(Please Specify)
                      </MenuItem>
                    </Field>
                  </FormControl>

                  <FormControl fullWidth>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="registered"
                      size="small"
                      label="Is the Facility Registered?"
                      variant="standard"
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Field>
                    {values.registered === "No" && (
                      <span
                        style={{
                          color: "white",
                          padding: 10,
                          backgroundColor: "green",
                        }}
                      >
                        Your Facility needs to be registered with CAC, SMOTH or
                        both and NBSC
                      </span>
                    )}
                  </FormControl>
                  {values.registered === "Yes" && (
                    <FormControl fullWidth>
                      <Field
                        sx={{ mb: 3 }}
                        component={Select}
                        name="registrationBody"
                        size="small"
                        label="Which body was it registered with CAC, SMOTH, or Both?"
                        variant="standard"
                      >
                        <MenuItem value={"CAC"}>CAC</MenuItem>
                        <MenuItem value={"SMOTH"}>SMOTH</MenuItem>
                        <MenuItem value={"Both"}>Both</MenuItem>
                      </Field>
                    </FormControl>
                  )}

                  <FormControl fullWidth>
                    <FormHelperText sx={{ mb: 1 }}>
                      Is the Facility Registered with National Blood Service
                      Commission(NBSC)?
                    </FormHelperText>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="registeredWithNBSC"
                      size="small"
                      label="Is the Facility Registered with National Blood Service Commission(NBSC)?"
                      variant="standard"
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Field>
                  </FormControl>
                  {values.registeredWithNBSC === "No" && (
                    <span
                      style={{
                        color: "white",
                        padding: 10,
                        backgroundColor: "green",
                      }}
                    >
                      Your Facility needs to be registered with CAC, SMOTH or
                      both and NBSC
                    </span>
                  )}
                  <FormControl fullWidth>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="bloodBankAvailable"
                      size="small"
                      label="Blood Bank Available(i.e Does this facility have an exisiting bank or not?)"
                      variant="standard"
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Field>
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      sx={{ mb: 3 }}
                      component={TextField}
                      fullWidth
                      name="telephone"
                      size="small"
                      label="Telephone"
                      variant="standard"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      sx={{ mb: 3 }}
                      component={TextField}
                      fullWidth
                      name="email"
                      size="small"
                      label="Email"
                      variant="standard"
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="addressState"
                      size="small"
                      label="Name of State Where the Facility is Located"
                      variant="standard"
                      onChange={(e) => handleStateChange(e.target.value)}
                    >
                      {nigerianStates()}
                    </Field>
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      fullWidth
                      name="lga"
                      size="small"
                      label="Local Government Area"
                      variant="standard"
                    >
                      {selectedState && nigerianLgas(selectedState)}
                    </Field>
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      sx={{ mb: 3 }}
                      component={TextField}
                      fullWidth
                      name="addressStreet"
                      size="small"
                      label="Street Number and details Where the Facility is Located"
                      variant="standard"
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      sx={{ mb: 3 }}
                      component={TextField}
                      fullWidth
                      name="addressTown"
                      size="small"
                      label="Name of Town Where the Facility is Located"
                      variant="standard"
                    />
                  </FormControl>

                  <FormControl fullWidth>
                    <Field
                      sx={{ mb: 3 }}
                      component={TextField}
                      fullWidth
                      name="contactPerson"
                      size="small"
                      label="Name of Contact Person"
                      variant="standard"
                    />
                  </FormControl>
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                  style={{ margin: 10 }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={submitForm}
                    style={{
                      margin: 20,
                      background: "#0C2340",
                      paddingLeft: "40px",
                      paddingRight: "40px",
                      textTransform: "none",
                    }}
                  >
                    {isSubmitting ? (
                      <span style={{ color: "yellow" }}> Submitting ...</span>
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </Stack>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </React.Fragment>
  );
}
