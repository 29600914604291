import React from "react";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";

export default class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = { dataSource: [], searchVal: "", searchRes: [] };
  }
  //   const focusSearch = React.useRef(null);

  componentDidMount() {
    // PREVENTS RERENDER FLICKERING AS USER TYPES IN SEARCH
    const sleep = (ms) => {
      return new Promise((resolve) => setTimeout(resolve, ms));
    };
    let currentsearchVal = true;
    const loadSearch = async () => {
      if (!this.state.searchVal) return this.setState({ searchRes: [] });
      await sleep(350);
      const lstSearch = this.state.searchVal.split(" ");
      if (currentsearchVal) {
        const lst = [];
        for (const element of lstSearch) {
          lst.push(
            this.props.dataSource.filter(
              (item) =>
                new RegExp(element, "i").exec(item.addressTown) ||
                new RegExp(element, "i").exec(item.addressState) ||
                new RegExp(element, "i").exec(item.lga) ||
                new RegExp(element, "i").exec(item.facilityName)
            )
          );
        }
        this.setState({ searchRes: lst.flat() });
      }
    };
    loadSearch();
    return () => {
      currentsearchVal = false;
    };
  }
  handleSearch = (e) => this.setState({ searchVal: e.target.value });
  render() {
    return (
      <>
        <TextField
          sx={{ maxWidth: 600 }}
          size="small"
          fullWidth
          id="outlined-search"
          type="search"
          placeholder="Search ..."
          variant="outlined"
          //   ref={focusSearch}
          onChange={this.handleSearch}
          value={this.state.searchVal}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        {() => this.state.searchRes}
      </>
    );
  }
}
