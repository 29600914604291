import * as React from "react";
import { Box } from "@mui/system";
import IconButton from "@mui/material/IconButton";
// import useFetch from "./use-fetch";
import Button from "@mui/material/Button";
import { Field, Formik, Form } from "formik";
import * as Yup from "yup";
import { TextField } from "formik-mui";
import FormControl from "@mui/material/FormControl";
import { Select } from "formik-mui";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import { FormHelperText } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Header from "../components/Header";
import { API_URL } from "../utils/api";
import Chip from "@mui/material/Chip";
import LoginIcon from "@mui/icons-material/Login";

export default function SiginUpFacility() {
  const [pvalues, setPvalues] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });
  const [errMsg, setErrMsg] = React.useState("");
  const handleClickShowPassword = () => {
    setPvalues({
      ...pvalues,
      showPassword: !pvalues.showPassword,
    });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const navigate = useNavigate();
  const [acctStatus, setAcctStatus] = React.useState(null);

  const createAcct = (data) => {
    fetch(`${API_URL}/signup`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...data }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && data.username && data.password) {
          // { id: 27, username: 'ludo', role: 'facility', password: 'ludo' }
          return navigate("/facility/sign-up-facility-successful");
        } else {
          return setAcctStatus(data);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  //   const { signinAsync, authenticated, setAuthenticated, status, error } =
  //     useFetch();

  //   React.useEffect(() => {
  //     authenticated && navigate("/useraccount", { replace: true });
  //     authenticated &&
  //       window.localStorage.setItem("authenticated", authenticated);
  //   }, [authenticated]);
  return (
    <React.Fragment>
      <Header />
      <Box
        sx={{
          maxWidth: 400,
          margin: "auto",
          marginTop: 5,
          borderRadius: 5,
          p: 1,
        }}
      >
        <div
          style={{
            backgroundImage: "url(/blood3.jpg)",
            backgroundRepeat: "no-repeat",
            height: 100,
            backgroundSize: "cover",
          }}
        >
          <Box
            sx={{
              position: "relative",
              width: "100%",
              height: 100,
              color: "white",
              bgcolor: "rgba(0, 0, 0,0.4)",
              "&:hover": { bgcolor: "rgba(0, 0, 0,.6)" },
              borderRadius: 2,
            }}
          >
            <span
              style={{
                color: "rgba(242, 242, 242, 0.9)",
                fontSize: 18,
                fontWeight: "bold",
                position: "relative",
                top: "40%",
                left: "5%",
                width: "100%",
                margin: "auto",
              }}
            >
              Medical Facility & Blood Bank Sign Up
            </span>
          </Box>
        </div>
        <Formik
          enableReinitialize={true}
          initialValues={{
            role: "facility",
            username: "",
            password: "",
            createdBy: "self",
          }}
          validationSchema={Yup.object({
            username: Yup.string().email().required("*Username is required"),
            password: Yup.string().required("*Password is required"),
          })}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            createAcct(values);
            setSubmitting(false).catch((error) => {
              setSubmitting(false);
              console.log(error);
            });
          }}
        >
          {({ submitForm, isSubmitting, values, handleChange }) => (
            <Form>
              <Box
                sx={{
                  pr: 2,
                  pt: 2,
                  borderRadius: 5,
                  width: "100%",
                  margin: "auto",
                  // background:
                  // "linear-gradient(135deg, rgba(0,127,128,0.47942927170868344) 7%, rgba(118,148,216,0.4962359943977591) 35%, rgba(162,231,227,0.4990371148459384) 60%, rgba(229,255,239,1) 93%)",
                }}
              >
                {" "}
                {isSubmitting && <LinearProgress sx={{ my: 2 }} />}
                <Stack
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                  style={{ margin: 10 }}
                >
                  <div style={{ marginTop: 20, marginBottom: 30 }}>
                    <span style={{ color: "maroon" }}>
                      Already have an account?{" "}
                    </span>{" "}
                    &nbsp;&nbsp;{" "}
                    <Chip
                      icon={<LoginIcon />}
                      label="Sign In"
                      variant="standard"
                      onClick={() =>
                        navigate("/facility/sign-in-facility", {
                          replace: true,
                        })
                      }
                    />
                  </div>
                  {acctStatus !== null && acctStatus.signedup === "taken" && (
                    <span style={{ color: "red" }}>
                      The username has already been taken
                    </span>
                  )}
                  <Field
                    component={TextField}
                    fullWidth
                    name="username"
                    size="small"
                    type="email"
                    label="Username(User Email)"
                    variant="standard"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <FormHelperText error>
                    {errMsg === "auth/email-already-in-use" && (
                      <span style={{ color: "red" }}>
                        The username has already been taken
                      </span>
                    )}
                    {errMsg === "auth/invalid-email" && (
                      <span style={{ color: "red" }}>The email is invalid</span>
                    )}
                  </FormHelperText>

                  <Field
                    component={TextField}
                    fullWidth
                    name="password"
                    size="small"
                    label="Password"
                    variant="standard"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type={pvalues.showPassword ? "text" : "password"}
                    value={values.password}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {pvalues.showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <FormHelperText error>
                    {errMsg === "auth/weak-password" && (
                      <span style={{ color: "red" }}>
                        Password is weak or too short
                      </span>
                    )}
                  </FormHelperText>
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                  style={{ margin: 10 }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={submitForm}
                    style={{
                      margin: 20,
                      background: "#089000",
                      paddingLeft: "40px",
                      paddingRight: "40px",
                      textTransform: "none",
                    }}
                  >
                    {isSubmitting ? (
                      <span style={{ color: "yellow" }}>Submitting ... </span>
                    ) : (
                      "Sign Up"
                    )}
                  </Button>
                </Stack>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </React.Fragment>
  );
}
