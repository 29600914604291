import * as React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Header from "../components/Header";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../utils/app-state-context";
import { API_URL } from "../utils/api";

export default function DonationHealthResultSubmitted() {
  const navigate = useNavigate();
  const [{ donorId }, _] = useStateValue();
  const [donorLatestHealthStatus, setDonorLatestHealthStatus] =
    React.useState(null);
  const latestHealthStatus = async () => {
    const response = await fetch(
      `${API_URL}/donorLatestHealthStatus/${donorId}`
    );
    if (!response.ok) {
      throw new Error(response.status);
    }
    const res = await response.json();
    setDonorLatestHealthStatus(res);
  };
  React.useEffect(() => {
    latestHealthStatus();
  }, []);
  console.log(
    "donorLatestHealthStatus, donorLatestHealthStatus",
    donorLatestHealthStatus
  );
  return (
    <>
      <Header />
      <Stack
        direction="column"
        spacing={2}
        alignItems="center"
        justifyContent="center"
        style={{ margin: 10 }}
      >
        <h3 style={{ color: "green" }}>
          Donor's Health Result Submitted Successfully
        </h3>
        <img
          src="/done.png"
          alt=""
          style={{ height: "150px", width: "150px" }}
        />
        {donorLatestHealthStatus &&
          donorLatestHealthStatus.donorAccepted === "No" && (
            <Typography
              ariant="span"
              component="span"
              sx={{ fontFamily: "fantasy", textAlign: "center" }}
            >
              The donor is NOT accepted!
            </Typography>
          )}
        {donorLatestHealthStatus &&
          donorLatestHealthStatus.donorAccepted === "Yes" && (
            <>
              <Typography
                ariant="span"
                component="span"
                sx={{ fontFamily: "fantasy", textAlign: "center" }}
              >
                The donor is accepted!
              </Typography>
              <Button
                sx={{ textTransform: "none" }}
                variant="contained"
                onClick={() => navigate("/blood-bank")}
              >
                Store Blood
              </Button>
            </>
          )}
      </Stack>
    </>
  );
}
