import React from "react";
import Header from "../components/Header";
import { useStateValue } from "../utils/app-state-context";
import { API_URL } from "../utils/api";
import FacilityDetailViewModal from "./FacilityDetailViewModal";
import DonorDetailViewModal from "./DonorDetailViewModal";
import { Field, Formik, Form } from "formik";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import { Select } from "formik-mui";
import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import useRetrieve from "../utils/useRetrieve";
import { nigerianLgas, nigerianStates } from "../utils/stateAndLga";

export default function BloodBankView() {
  const navigate = useNavigate();
  const [{ authAdmin }, _] = useStateValue();
  const facilityProfile = useRetrieve(`${API_URL}/facilityProfile`);
  const bloodBanks = useRetrieve(`${API_URL}/bloodBank`);

  //   SELECT `id`, `facilityId`, `donorId`,
  // `bagNumber`, `boodType`, `dateCollected`,
  // `dateTested`, `dateReleased`,
  // `dateDiscarded`, `comment`
  // FROM `bloodbank` WHERE 1

  // SELECT `id`, `facilityId`, `facilityName`,
  // `privatePublic`, `registered`,
  // `registrationBody`, `bloodBankAvailable`,
  // `telephone`, `email`, `addressStreet`,
  // `addressTown`, `addressState`, `lga`,
  // `contactPerson`
  // FROM `facilityprofile` WHERE 1

  // SELECT `donorId`, `userId`, `createdBy`,
  // `fname`, `mname`, `gender`, `surname`,
  // `telephone`, `email`, `age`,
  // `occupation` FROM `donorprofile` WHERE 1
  const [rBloodTyp, setRBloodTyp] = React.useState();
  const [rState, setRState] = React.useState();
  const [rLga, setRLga] = React.useState();
  const [lstSearch, setLstSearch] = React.useState([]);

  const handleSearch = () => {
    const stateFacilities = facilityProfile.filter(
      (item) => item.addressState === rState || item.lga === rLga
    );
    
    const lstFacilityId = stateFacilities.map((item) => item.facilityId);
    const lst = [];
    for (const id of lstFacilityId) {
      lst.push(
        bloodBanks.filter(
          (item) => item.facilityId === id && item.boodType === rBloodTyp
        )
      );
    }
    // console.log("stateFacilities: ", stateFacilities);
    // console.log("lstFacilityId: ", lstFacilityId);
    // console.log("lst.flat(): ", lst.flat());
    setLstSearch(lst.flat());
  };
  if (!authAdmin) {
    return navigate("/admin");
  }
  return (
    <>
      <Header />
      <Formik
        enableReinitialize={true}
        initialValues={{
          addressState: "",
          bloodType: "",
          lga: "",
        }}
        validationSchema={Yup.object({
          addressState: Yup.string().required("*Required"),
          bloodType: Yup.string().required("*Required"),
          lga: Yup.string().required("*Required"),
        })}
      >
        {() => (
          <Form>
            <Box
              sx={{
                p: 2,
                borderRadius: 5,
              }}
            >
              <Typography component="div" noWrap style={{ marginBottom: 10 }}>
                Search Blood Bank Based On:
              </Typography>
              <Stack
                spacing={2}
                direction={{ xs: "column", sm: "column", md: "row" }}
                alignItems="center"
                justifyContent="center"
              >
                <FormControl fullWidth>
                  <Field
                    component={Select}
                    // formHelperText={{ children: "Please Select a Blood Type" }}
                    name="bloodType"
                    size="small"
                    label="Select Blood Type"
                    validate={(bloodType) =>
                      !bloodType ? "You must select a blood type" : ""
                    }
                    onChange={(e) => setRBloodTyp(e.target.value)}
                  >
                    <MenuItem value={"A"}>A</MenuItem>
                    <MenuItem value={"B"}>B</MenuItem>
                    <MenuItem value={"O"}>O</MenuItem>
                    <MenuItem value={"AB"}>AB</MenuItem>
                  </Field>
                </FormControl>
                <FormControl fullWidth>
                  <Field
                    component={Select}
                    // formHelperText={{
                    //   children: "Please Select the Facility's State of Origin ",
                    // }}
                    name="addressState"
                    size="small"
                    label="Name of State Where the Facility is Located"
                    variant="outlined"
                    onChange={(e) => setRState(e.target.value)}
                  >
                    {nigerianStates()}
                  </Field>
                </FormControl>
                <FormControl fullWidth>
                  <Field
                    sx={{ m: 1 }}
                    component={Select}
                    // formHelperText={{
                    //   children: "Enter a valid local goverment area",
                    // }}
                    fullWidth
                    name="lga"
                    size="small"
                    label="Select Local Government Area"
                    variant="outlined"
                    onChange={(e) => setRLga(e.target.value)}
                  >
                    {rState && nigerianLgas(rState)}
                  </Field>
                </FormControl>
              </Stack>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSearch}
                style={{
                  background: "#0C2340",
                  paddingLeft: "40px",
                  paddingRight: "40px",
                  textTransform: "none",
                }}
              >
                Search
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
      {lstSearch.length > 0 ? (
        lstSearch.map((bloodBanks) => (
          <div
            style={{
              margin: 10,
              padding: 10,
              borderBottom: "solid 1px grey",
            }}
          >
            <FacilityDetailViewModal facilityId={bloodBanks.facilityId} />

            <DonorDetailViewModal userId={bloodBanks.donorId} />
            <p>
              Bag Number:{" "}
              <span style={{ color: "purple", fontWeight: "bold" }}>
                {bloodBanks.bagNumber}
              </span>
            </p>
            <p>
              Blood Type?:{" "}
              <span style={{ color: "purple", fontWeight: "bold" }}>
                {bloodBanks.boodType}
              </span>
            </p>
            <p>
              Date Collected:{" "}
              <span style={{ color: "purple", fontWeight: "bold" }}>
                {bloodBanks.dateCollected}
              </span>
            </p>
            <p>
              Date Tested:{" "}
              <span style={{ color: "purple", fontWeight: "bold" }}>
                {bloodBanks.dateTested}
              </span>
            </p>
            <p>
              Date Released:{" "}
              <span style={{ color: "purple", fontWeight: "bold" }}>
                {bloodBanks.dateReleased}
              </span>
            </p>
            <p>
              Date Discarded:{" "}
              <span style={{ color: "purple", fontWeight: "bold" }}>
                {bloodBanks.dateDiscarded}
              </span>
            </p>
            <p>
              Comment / Remark:{" "}
              <span style={{ color: "purple", fontWeight: "bold" }}>
                {bloodBanks.comment}
              </span>
            </p>
          </div>
        ))
      ) : (
        <div
          style={{
            padding: 2,
            minWidth: 300,
            marginLeft: 20,
            margin: "auto",
          }}
        ></div>
      )}
    </>
  );
}
