import React from "react";
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { useStateValue } from "../utils/app-state-context";
import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import Header from "../components/Header";
import { API_URL } from "../utils/api";
import { Field, Formik, Form } from "formik";
import * as Yup from "yup";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { TextField, Select } from "formik-mui";
import { useFeedBack, FeedBack } from "../components/FeedBack";
import LinearProgress from "@mui/material/LinearProgress";
import AdminRetrieveFacilityBloodBanks from "./AdminRetrieveFacilityBloodBanks";
import Menu from "@mui/material/Menu";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import Search from "../components/Search";

export default function AdminVerification() {
    const { openSnack, setOpenSnack, msg, setMsg, handleCloseSnackbar } =
    useFeedBack();
    const [facilities, setFacilities] = React.useState(null);
    const [result, setResult] = React.useState(null);
    const retrieveFaclities = () => {
        fetch(`${API_URL}/facilityProfile`)
          .then((response) => response.json())
          .then((data) => setFacilities(data))
          .catch((error) => {
            console.error("Error:", error);
          });
      };
      React.useEffect(() => {
        retrieveFaclities()
      },[facilities])
      const unverifiedList = facilities && facilities.filter((item) => item.verified === "");
      const handleClick = (facilityId)=>{
         const res = unverifiedList !== null && unverifiedList.filter((item) => item.facilityId === facilityId)
        setResult(res[0])}
      return<>
      <div>
        <Header/>
        <FeedBack
        openSnack={openSnack}
        msg={msg}
        handleCloseSnackbar={handleCloseSnackbar}
      />
        <p style={{marginBottom:20, margin:20, fontWeight:"bold"}}>List of Medical Facility and Blood Bank for Verification</p>
        <Stack direction={{sm:"column",xs:"column", md:"row"}} spacing={4}>
            
            {/* Sidebar */}
            <Stack>
            {unverifiedList && unverifiedList.map((item)=><Box sx={{m:1, p:1, borderBottom:"solid 1px grey"}}><Button onClick={()=>handleClick(item.facilityId)}>{item.facilityName}</Button></Box>)}
            </Stack>
             {/* Content */}
            <Box>
            
            {result ? <div
                style={{
                    padding: 2,
                    
                    minWidth: 300,
                    margin: 10,
                    margin: "auto",
                }}
                >
                
                <p>
                    Facility's Name:{" "}
                    <span style={{ color: "purple", fontWeight: "bold" }}>
                    {result.facilityName}
                    </span>
                </p>
                <p>
                    Facility's Type:{" "}
                    <span style={{ color: "purple", fontWeight: "bold" }}>
                    {result.facilityType}
                    </span>
                </p>
                
                <p>
                    Sector:{" "}
                    <span style={{ color: "purple", fontWeight: "bold" }}>
                    {result.privatePublic}
                    </span>
                </p>
                <p>
                Is facility state or federal:{" "}
                    <span style={{ color: "purple", fontWeight: "bold" }}>
                    {result.stateFederal}
                    </span>
                </p>
                <p>
                    Registered?:{" "}
                    <span style={{ color: "purple", fontWeight: "bold" }}>
                    {result.registered}
                    </span>
                </p>
                <p>
                    Registered By:{" "}
                    <span style={{ color: "purple", fontWeight: "bold" }}>
                    {result.registrationBody}
                    </span>
                </p>
                <p>
                Is it registered with Nigeran Blood Service Commision(NBSC):{" "}
                    <span style={{ color: "purple", fontWeight: "bold" }}>
                    {result.registeredWithNBSC}
                    </span>
                </p>
                <p>
                    Blood Bank Available:{" "}
                    <span style={{ color: "purple", fontWeight: "bold" }}>
                    {result.bloodBankAvailable}
                    </span>
                </p>
                <p>
                    Telephone:{" "}
                    <span style={{ color: "purple", fontWeight: "bold" }}>
                    {result.telephone}
                    </span>
                </p>
                <p>
                    Email:{" "}
                    <span style={{ color: "purple", fontWeight: "bold" }}>
                    {result.email}
                    </span>
                </p>
                <p>
                    Local Government Area:{" "}
                    <span style={{ color: "purple", fontWeight: "bold" }}>
                    {result.lga}
                    </span>
                </p>
                <p>
                    Address:{" "}
                    <span style={{ color: "purple", fontWeight: "bold" }}>
                    {`${result.addressStreet}, ${result.addressTown}`}
                    </span>
                </p>
                <p>
                    LGA & State:{" "}
                    <span style={{ color: "purple", fontWeight: "bold" }}>
                    {result.lga + ", " + result.addressState}
                    </span>
                </p>
                <p>
                    Contact Person:{" "}
                    <span style={{ color: "purple", fontWeight: "bold" }}>
                    {result.contactPerson}
                    </span>
                </p>
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        verified: "",
                        }}
                    validationSchema={Yup.object({
                            verified: Yup.string().required(
                              "*Please select Yes or No to verify"
                            ), 
                            })
                        } 
                    onSubmit={async (values, { setSubmitting,  }) => {
                            setSubmitting(true);
                            try {
                              const response = await fetch(`${API_URL}/facilityProfile`, {
                                method: "PUT",
                                headers: {
                                  "Content-Type": "application/json",
                                },
                                body: JSON.stringify({
                                  ...values,
                                  facilityId: result.facilityId,
                                }),
                              });
                              if (!response.ok) {
                                throw new Error(response.status);
                              }
                              const res = await response.json();
                              if (res) {
                                setMsg(`Facility's Profile Created Successfully. `);
                                setOpenSnack(true);
                                }
                              setSubmitting(false);
                              } catch (e) {
                              console.error("Error adding document: ", e);
                              setSubmitting(false);
                            }
                          }} 
                    >
                         {({ submitForm, isSubmitting }) => (
                        <Form>
                             {isSubmitting && <LinearProgress sx={{ my: 2 }} />}
                            <FormControl fullWidth>
                                <Field
                                sx={{ mb: 3 }}
                                component={Select}
                                name="verified"
                                size="small"
                                label="Verify the Facility"
                                variant="standard"
                                >
                                <MenuItem value={"Yes"}>Yes</MenuItem>
                                <MenuItem value={"No"}>No</MenuItem>
                                </Field>
                            </FormControl>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={isSubmitting}
                                onClick={submitForm}
                                style={{
                                marginTop: 10,
                                background: "#0C2340",
                                paddingLeft: "40px",
                                paddingRight: "40px",
                                textTransform: "none",
                                }}
                            >
                                {isSubmitting ? (
                                <span style={{ color: "yellow" }}> Submitting ...</span>
                                ) : (
                                "Verify"
                                )}
                            </Button>
                        </Form>
                        )}
                </Formik>
                </div>:""}
                    </Box>
                   </Stack>
                 </div>
      </>
}