import React from "react";
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { useStateValue } from "../utils/app-state-context";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Header from "../components/Header";
import { API_URL } from "../utils/api";
import useRetrieve from "../utils/useRetrieve";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { stateAndLga } from "../utils/stateAndLga";
import PieChart from "../components/PieChart";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";

export default function AdminDashboard() {
  const navigate = useNavigate();
  const [{ authAdmin }, _] = useStateValue();
  const [facilityProfile, setFacilityProfile] = React.useState(null);
  const [donors, setDonors] = React.useState(null);
  const [bloodBanks, setBloodBanks] = React.useState(null);
  const [state, setState] = React.useState("");
  const [facilityProfileByState, setFacilityProfileByState] = React.useState();

  const nigerianLgas = () =>
    state &&
    stateAndLga[state].map((item) => (
      <MenuItem value={item} key={item}>
        {item}
      </MenuItem>
    ));
  const nigerianStates = () => {
    const lst = [];
    for (const key in stateAndLga) {
      lst.push(<MenuItem value={key}>{key}</MenuItem>);
    }
    return lst;
  };
  const handleChange = (event) => {
    setState(event.target.value);
    setFacilityProfileByState(
      facilityProfile.filter((item) => item.addressState === event.target.value)
    );
  };
  const unverifiedList = facilityProfile && facilityProfile.filter((item) => item.verified === "");
  const example = [
    {
      id: 2,
      facilityId: 6,
      facilityName: "Our Health",
      privatePublic: "Government",
      registered: "Yes",
      registrationBody: "SMOTH",
      bloodBankAvailable: "No",
      telephone: "+2347064267499",
      email: "awomtm@gmail.com",
      addressStreet: "5b Iloromu2",
      addressTown: "Ile Ife",
      addressState: "Abia",
      lga: "Aba North",
      contactPerson: "Olubukola",
    },
    {
      id: 3,
      facilityId: 7,
      facilityName: "Water",
      privatePublic: "Private",
      registered: "Yes",
      registrationBody: "CAC",
      bloodBankAvailable: "Yes",
      telephone: "+2347064267499",
      email: "awomtm@gmail.com",
      addressStreet: "5b Iloromu2",
      addressTown: "Ile Ife",
      addressState: "Abia",
      lga: "Isiala Ngwa North",
      contactPerson: "Awoyemi Michael M",
    },
  ];

  const totalByLgas = () => {
    if (!state || !facilityProfileByState) {
      return;
    }
    const result = [];
    for (let i = 0; i < stateAndLga[state].length; i++) {
      let total = 0;
      for (let j = 0; j < facilityProfileByState.length; j++) {
        if (stateAndLga[state][i] === facilityProfileByState[j].lga) total++;
      }
      result.push({
        name: stateAndLga[state][i],
        value: total,
        label: stateAndLga[state][i] + ": " + total,
      });
    }
    return result;
  };
  useRetrieve(`${API_URL}/bloodBank`);
  useRetrieve(`${API_URL}/dornorProfile`);
  useRetrieve(`${API_URL}/facilityProfile`);
  const retrieveBloodBanks = () => {
    fetch(`${API_URL}/bloodBank`)
      .then((response) => response.json())
      .then((data) => setBloodBanks(data))
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const retrieveDonors = () => {
    fetch(`${API_URL}/dornorProfile`)
      .then((response) => response.json())
      .then((data) => setDonors(data))
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const retrieveFacilityProfile = () => {
    fetch(`${API_URL}/facilityProfile`)
      .then((response) => response.json())
      .then((data) => setFacilityProfile(data))
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  React.useEffect(() => {
    retrieveBloodBanks();
    retrieveFacilityProfile();
    retrieveDonors();
  }, []);
  if (!authAdmin) {
    return navigate("/admin");
  }
  return (
    <>
      <Header />
      <h3 style={{ margin: 20 }}>Admin Dashboard</h3>
      <Stack
        justifyContent="space-between"
        direction={{
          xs: "column",
          sm: "column",
          md: "row",
          background:
            "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(0,0,0,0.3785889355742297) 35%, rgba(3,185,185,0.31976540616246496) 94%, rgba(0,212,255,1) 100%)",
        }}
      >
        <List
          sx={{ width: "100%", maxWidth: 350, bgcolor: "background.paper" }}
        >
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar alt="F" src="/drugstore-icon.png" />
            </ListItemAvatar>
            <ListItemText
              primary="Medical Facility, Blood Bank etc"
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    Total:
                  </Typography>
                  &nbsp;&nbsp;{facilityProfile && facilityProfile.length}
                  <Button
                    size="small"
                    onClick={() => navigate("/admin-retrieve-facilities")}
                  >
                    View
                  </Button>
                </React.Fragment>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar alt="P" src="/blood-icon.png" />
            </ListItemAvatar>
            <ListItemText
              primary="Donors"
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    Total:
                  </Typography>
                  &nbsp;&nbsp;{donors && donors.length}
                  <Button
                    size="small"
                    onClick={() => navigate("/search-donor")}
                  >
                    View
                  </Button>
                </React.Fragment>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar alt="B" src="/blood4.jpg" />
            </ListItemAvatar>
            <ListItemText
              primary="Blood Bank"
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    Total
                  </Typography>
                  &nbsp;&nbsp;{bloodBanks && bloodBanks.length}
                  <Button
                    size="small"
                    onClick={() => navigate("/blood-bank-view")}
                  >
                    View
                  </Button>
                </React.Fragment>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar alt="B" src="/blood4.jpg" />
            </ListItemAvatar>
            <ListItemText
              primary="Verification"
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    Total
                  </Typography>
                  &nbsp;&nbsp;{unverifiedList && unverifiedList.length}
                  <Button
                    size="small"
                    onClick={() => navigate("/admin-verification")}
                  >
                    View
                  </Button>
                </React.Fragment>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar alt="S" src="/pie_chart.png" />
            </ListItemAvatar>
            <ListItemText
              primary="Statistics"
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  ></Typography>
                  <FormControl fullWidth sx={{ mt: 2 }}>
                    <InputLabel id="Number-of-Facilities-by-State">
                      Number of Facilities by State
                    </InputLabel>
                    <Select
                      labelId="Number-of-Facilities-by-State"
                      id="Number-of-Facilities-by-State"
                      value={state}
                      label="Number of Facilities by State"
                      onChange={handleChange}
                    >
                      {nigerianStates()}
                    </Select>
                  </FormControl>
                </React.Fragment>
              }
            />
          </ListItem>
        </List>
        <Box sx={{ minWidth: 120, m: 2 }}>
          <div>
            <h4>
              {facilityProfileByState && (
                <span>
                  Number of Facilities in <i>{state}</i> state organised by LGAs
                </span>
              )}
            </h4>
            {facilityProfileByState && (
              <p>
                {"Total: " + facilityProfileByState.length}
                <hr />
              </p>
            )}
            <Stack
              direction={{ xs: "column", sm: "column", md: "row" }}
              // spacing={2}
              // alignItems="center"
              // justifyContent="flex-start"
              justifyContent="space-evenly"
            >
              <div>
                {totalByLgas() &&
                  totalByLgas().map((item) => (
                    <p>
                      {item.name}: {item.value}
                    </p>
                  ))}
              </div>

              <PieChart
                data={
                  totalByLgas() &&
                  totalByLgas().filter((item) => item.value !== 0)
                }
                outerRadius={160}
                innerRadius={0}
              />
            </Stack>
          </div>
        </Box>
      </Stack>
    </>
  );
}
