import Header from "../components/Header";
import Box from "@mui/material/Box";
import Typography from '@mui/material/Typography';
import Stack from "@mui/material/Stack";
import { useLocation } from "react-router-dom";

export default function VerificationStatus() {
    let location = useLocation();
    return<>
    <Header/>
    <Stack 
            p={2} 
            m={2} 
            spacing={2}
            justifyItems="center" 
            alignContent="center" 
            alignItems="center" 
            sx={{ maxWidth:400, margin:"auto"}}
            >
        <Box>
            {location.state.status === "No" && <img src="/close-icon.png" style={{width:100, height:100}}/>}
            {location.state.status === "inprogress" && <img src="/decision.png" style={{width:100, height:100}}/>}
        </Box>
        {location.state.status === "No" && <Typography ariant="span" component="span" sx={{fontFamily:"fantasy", textAlign:"center"}}>
            Your verification was disapproved!
        </Typography>}
        {location.state.status === "inprogress" && <Typography ariant="span" component="span" sx={{fontFamily:"fantasy", textAlign:"center"}}>
            Your verification is in progress. Please check back later. See the contact page to make contact.
        </Typography>}
       
    </Stack>
    </>
}