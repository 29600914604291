import * as React from "react";
import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import { Field, Formik, Form } from "formik";
import { TextField, Select } from "formik-mui";
import * as Yup from "yup";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import { FormHelperText } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { useStateValue } from "../utils/app-state-context";
import Header from "../components/Header";
import { API_URL } from "../utils/api";
import { useFeedBack, FeedBack } from "../components/FeedBack";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import SelectDonor from "../components/SelectDonor";
import BlobankSubHeader from "../components/BlobankSubHeader";

export default function BloodBank() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [{ facilityId, donorId }, dispatch] = useStateValue();
  const { openSnack, setOpenSnack, msg, setMsg, handleCloseSnackbar } =
    useFeedBack();
  return (
    <React.Fragment>
      <Header />
      <BlobankSubHeader />

      <FeedBack
        openSnack={openSnack}
        msg={msg}
        handleCloseSnackbar={handleCloseSnackbar}
      />
      <Box
        sx={{
          maxWidth: 500,
          p: 1,
          margin: "auto",
          marginTop: 5,
          borderRadius: 5,
        }}
      >
        <Formik
          enableReinitialize={true}
          initialValues={{
            facilityId,
            donorId,
            bagNumber: "",
            boodType: "",
            bloodGrp: "",
            dateCollected: "",
            dateTested: "",
            dateReleased: "",
            dateDiscarded: "",
            comment: "",
          }}
          validationSchema={Yup.object({
            donorId: Yup.string().required("*donorId is required"),
            bagNumber: Yup.string().required("*Bag number name is required"),
            boodType: Yup.string().required("*Blood type is required"),
            bloodGrp: Yup.string().required("*Blood type is required"),
            dateCollected: Yup.string().required(
              "*Date collected name is required"
            ),
            dateTested: Yup.string().required("*Date tested is required"),
            // dateReleased: Yup.string().required("*Date released is required"),
            // dateDiscarded: Yup.string().required("*Date discarded is required"),
            comment: Yup.string().required("*Date tested is required"),
          })}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            // const db = getFirestore(app);
            try {
              const response = await fetch(`${API_URL}/bloodBank`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  facilityId,
                  donorId: values.donorId,
                  bagNumber: values.bagNumber,
                  boodType: values.boodType,
                  dateCollected: values.dateCollected,
                  dateTested: values.dateTested,
                  dateReleased: values.dateReleased,
                  dateDiscarded: values.dateDiscarded,
                  comment: values.comment,
                }),
              });

              if (!response.ok) {
                throw new Error(response.status);
              }
              const res = await response.json();
              if (res) {
                setMsg(`Blood Bank Information Submitted Successfully. `);
                setOpenSnack(true);
                resetForm();
              }
              setSubmitting(true);
            } catch (e) {
              console.error("Error adding document: ", e);
              setSubmitting(true);
            }
          }}
        >
          {({ submitForm, isSubmitting, values, handleChange }) => (
            <Form>
              <Box
                sx={{
                  borderRadius: 5,
                  // background: "rgba(229,255,239)",
                  margin: "auto",
                  // background:
                  //   "linear-gradient(135deg, rgba(0,127,128,0.47942927170868344) 7%, rgba(118,148,216,0.4962359943977591) 35%, rgba(162,231,227,0.4990371148459384) 60%, rgba(229,255,239,1) 93%)",
                }}
              >
                {" "}
                {isSubmitting && <LinearProgress sx={{ my: 2 }} />}
                <Stack spacing={2} alignItems="center" justifyContent="center">
                  <Typography
                    component="h1"
                    noWrap
                    style={{ marginTop: "20", marginBottom: "20" }}
                  >
                    Blood Bank
                  </Typography>
                  <SelectDonor />

                  <Field
                    sx={{ mb: 3 }}
                    component={TextField}
                    fullWidth
                    name="donorId"
                    type="text"
                    size="small"
                    label="Donor Id"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <Field
                    component={TextField}
                    fullWidth
                    name="bagNumber"
                    type="text"
                    size="small"
                    label="Blood Bag Number"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <FormControl fullWidth>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="bloodGrp"
                      size="small"
                      label="Blood Group"
                      variant="outlined"
                    >
                      <MenuItem value={"O"}>O</MenuItem>
                      <MenuItem value={"A"}>A</MenuItem>
                      <MenuItem value={"B"}>B</MenuItem>
                      <MenuItem value={"AB"}>AB</MenuItem>
                    </Field>
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="boodType"
                      size="small"
                      label="RH Type"
                      variant="outlined"
                    >
                      <MenuItem value={"O-"}>O-</MenuItem>
                      <MenuItem value={"O+"}>O+</MenuItem>
                      <MenuItem value={"A-"}>A-</MenuItem>
                      <MenuItem value={"A+"}>A+</MenuItem>
                      <MenuItem value={"B-"}>B-</MenuItem>
                      <MenuItem value={"B+"}>B+</MenuItem>
                      <MenuItem value={"AB-"}>AB-</MenuItem>
                      <MenuItem value={"AB+"}>AB+</MenuItem>
                    </Field>
                  </FormControl>
                  <Field
                    sx={{ mb: 3 }}
                    component={TextField}
                    fullWidth
                    name="dateCollected"
                    type="date"
                    size="small"
                    label="Date Collected"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <FormHelperText error></FormHelperText>
                  <Field
                    sx={{ mb: 3 }}
                    component={TextField}
                    fullWidth
                    name="dateTested"
                    type="date"
                    size="small"
                    label="Date Tested"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <FormHelperText error></FormHelperText>
                  <Field
                    sx={{ mb: 3 }}
                    component={TextField}
                    fullWidth
                    name="dateReleased"
                    type="date"
                    size="small"
                    label="Date Released"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <FormHelperText error></FormHelperText>
                  <Field
                    sx={{ mb: 3 }}
                    component={TextField}
                    fullWidth
                    name="dateDiscarded"
                    type="date"
                    size="small"
                    label="Date Discarded"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <FormHelperText error></FormHelperText>
                  <Field
                    sx={{ mb: 3 }}
                    component={TextField}
                    fullWidth
                    multiline
                    name="comment"
                    size="small"
                    label="Comment"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                  style={{ margin: 10 }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={submitForm}
                    style={{
                      margin: 20,
                      background: "#0C2340",
                      paddingLeft: "40px",
                      paddingRight: "40px",
                      textTransform: "none",
                    }}
                  >
                    {isSubmitting ? (
                      <span style={{ color: "yellow" }}>Submitting... </span>
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </Stack>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </React.Fragment>
  );
}
