import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { API_URL } from "../utils/api";
import useRetrieve from "../utils/useRetrieve";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

export default function FacilityDetailViewModal({ facilityId }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    retrieveProfile();
    return setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const [facilities, setFacilities] = React.useState(null);
  const [user, setUser] = React.useState();
  const retrieveProfile = () => {
    fetch(`${API_URL}/facilityProfile/${facilityId}`)
      .then((response) => response.json())
      .then((data) => setFacilities(data))
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const users = () => {
    fetch(`${API_URL}/users/${facilityId}`)
      .then((response) => response.json())
      .then((data) => setUser(data))
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  React.useEffect(() => {
    retrieveProfile();
    users();
  }, []);

  return (
    <div>
      <Button onClick={handleOpen} style={{ textTransform: "none" }}>
        Blood Bank Source
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {user && user.role === "bloodbank" ? "Blood Bank" : "Facility"}{" "}
            Details
            <img
              src="/close-icon.png"
              style={{
                height: 25,
                weight: 25,
                float: "right",
                cursor: "pointer",
              }}
              alt="X"
              onClick={handleClose}
            />
          </Typography>
          {facilities !== null ? (
            <div
              style={{
                padding: 2,
                minWidth: 300,
                marginLeft: 10,
                margin: "auto",
              }}
            >
              <p>
                Facilities Name:{" "}
                <span style={{ color: "purple", fontWeight: "bold" }}>
                  {facilities.facilityName}
                </span>
              </p>
              <p>
                Sector:{" "}
                <span style={{ color: "purple", fontWeight: "bold" }}>
                  {facilities.privatePublic}
                </span>
              </p>
              <p>
                Registered?:{" "}
                <span style={{ color: "purple", fontWeight: "bold" }}>
                  {facilities.registered}
                </span>
              </p>
              <p>
                Registered By:{" "}
                <span style={{ color: "purple", fontWeight: "bold" }}>
                  {facilities.registrationBody}
                </span>
              </p>
              <p>
                Blood Bank Available:{" "}
                <span style={{ color: "purple", fontWeight: "bold" }}>
                  {facilities.bloodBankAvailable}
                </span>
              </p>
              <p>
                Telephone:{" "}
                <span style={{ color: "purple", fontWeight: "bold" }}>
                  {facilities.telephone}
                </span>
              </p>
              <p>
                Email:{" "}
                <span style={{ color: "purple", fontWeight: "bold" }}>
                  {facilities.email}
                </span>
              </p>
              <p>
                Address:{" "}
                <span style={{ color: "purple", fontWeight: "bold" }}>
                  {`${facilities.addressStreet}, ${facilities.addressTown}, ${facilities.addressState}`}
                </span>
              </p>
              <p>
                Contact Person:{" "}
                <span style={{ color: "purple", fontWeight: "bold" }}>
                  {facilities.contactPerson}
                </span>
              </p>
            </div>
          ) : (
            <div
              style={{
                padding: 2,
                minWidth: 300,
                marginLeft: 20,
                margin: "auto",
              }}
            >
              No Facility Profile Record.
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
}
