import React from "react";
import Button from "@mui/material/Button";
import SignatureCanvas from "react-signature-canvas";

export default function Signature() {
  const [sign, setSign] = React.useState();
  const [url, setUrl] = React.useState();
  const handleClear = () => {
    sign.clear();
    setUrl("");
  };
  const handlegenerate = () => {
    setUrl(sign.getTrimmedCanvas().toDataURL("image/png"));
    console.log(url.length);
    //  !==0
  };
  return (
    <div
      style={{
        marginTop: 10,
        border: "solid 1px grey",
        width: 350,
        height: 250,
        borderRadius: 5,
      }}
    >
      <SignatureCanvas
        penColor="green"
        canvasProps={{ width: 350, height: 200, className: "sigCanvas" }}
        ref={(data) => setSign(data)}
      />
      <Button sx={{ textTransform: "none" }} onClick={handleClear}>
        Clear
      </Button>
      {/* <Button onClick={handlegenerate}>Save</Button> */}
    </div>
  );
}
