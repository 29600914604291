import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CustomizedMenus from "./CustomizedMenus";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../utils/app-state-context";
import MobileMenu from "./MobileMenu";
import { Link } from "react-router-dom";
import { useFeedBack, FeedBack } from "../components/FeedBack";
import { retreieveLatestHealthStatus } from "../utils/retreieveLatestHealthStatus";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Container from "@mui/material/Container";

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default function Header(props) {
  const { openSnack, setOpenSnack, msg, setMsg, handleCloseSnackbar } =
    useFeedBack();
  const [
    {
      disqualificationDate,
      profileNotCreated,
      userProfile,
      authDonor,
      authAdmin,
      authFacility,
      facilityProfileState,
      donorsHealthStatus,
      unprocessedQue,
    },
    dispatch,
  ] = useStateValue();
  const navigate = useNavigate();
  console.log("authFacility: ", authFacility);
  console.log("unprocessedQue unprocessedQue: ", unprocessedQue);

  return (
    <>
      <CssBaseline />
      <ElevationScroll {...props}>
        <AppBar>
          <Toolbar sx={{ py: 0.5, justifyContent: "space-between" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => navigate("/")}
            >
              <img
                src="/logo.png"
                alt="logo"
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  width: "50px",
                  height: "50px",
                }}
              />
              <Typography
                sx={{
                  textAlign: "center",
                  fontFamily: "'Gill Sans Extabold' sans-serif",
                  fontWeight: "bold",
                  fontSize: 20,
                  flexGrow: 1,
                  color: "black",
                }}
              >
                Blood Hub{" "}
                {/* {disqualificationDate ? disqualificationDate: "no disqualificationDate"} */}
                {/* {`facility-facilityId: "${facilityId}", facility-role: "${role}" , donorId: "${donorId}", donorRole: "${donorRole}", authFaclity: "${authFaclity}"`} */}
              </Typography>
            </Box>

            {/* Not logged in on a big screen */}
            <Stack
              spacing={4}
              direction="row"
              alignItems="center"
              justifyContent="space-around"
              sx={{
                pr: 2,
                display: { xs: "none", sm: "none", md: "flex", lg: "flex" },
              }}
            >
              <Link
                to="/"
                style={{
                  color: "inherit",
                  textDecoration: "none",
                  "&:hover": { textDecoration: "underline" },
                }}
              >
                Home
              </Link>
              <Link
                to="/about"
                style={{
                  color: "inherit",
                  textDecoration: "none",
                  "&:hover": { textDecoration: "underline" },
                }}
              >
                About
              </Link>
              <Link
                to="/contact"
                style={{
                  color: "inherit",
                  textDecoration: "none",
                  "&:hover": { textDecoration: "underline" },
                }}
              >
                Contact
              </Link>
            </Stack>
            {/* Not logged in on a small screen */}
            {(!authFacility || !authDonor || !authAdmin) && (
              <Box
                sx={{
                  display: { xs: "flex", sm: "flex", md: "none", lg: "none" },
                }}
              >
                <MobileMenu />
              </Box>
            )}
            {/* Logged in on a big screen */}
            <Box
              sx={{
                display: { xs: "none", sm: "none", md: "flex", lg: "flex" },
              }}
            >
              {authAdmin && (
                <Button
                  color="inherit"
                  sx={{ textTransform: "none" }}
                  aria-label="admin-dashboard"
                  component="label"
                  onClick={() => navigate("/admin-dashboard")}
                >
                  Dashboard
                </Button>
              )}
              {authFacility && (
                <Button
                  color="inherit"
                  size="small"
                  sx={{ textTransform: "none" }}
                  aria-label="facility-dashboard"
                  component="label"
                  disabled={
                    profileNotCreated ||
                    (facilityProfileState &&
                      facilityProfileState.verified !== "Yes")
                  }
                  onClick={() => navigate("/facility-dashboard")}
                >
                  Dashboard
                </Button>
              )}
              {authDonor && (
                <>
                  <Button
                    color="inherit"
                    size="small"
                    sx={{ textTransform: "none" }}
                    disabled={userProfile == null}
                    aria-label="donor-dashboard"
                    component="label"
                    onClick={() => navigate("/donor-dashboard")}
                  >
                    Dashboard
                  </Button>
                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                  <Button
                    color="inherit"
                    disabled={
                      userProfile == null ||
                      (unprocessedQue && unprocessedQue.donorAccepted === "")
                    }
                    sx={{
                      textTransform: "none",
                      "&:hover": { backgroundColor: "#D4D4D4", color: "blue" },
                    }}
                    onClick={async () => {
                      const res = await retreieveLatestHealthStatus(
                        userProfile.userId
                      );
                      if (res.length !== 0 && res.donorAccepted === "") {
                        setMsg(
                          `Your previous questionnaire is being processed. Please wait for the response`
                        );
                        setOpenSnack(true);
                      } else navigate("/consent");
                    }}
                  >
                    Donation Form
                  </Button>
                </>
              )}

              {/* {authFacility && (
                <React.Fragment> */}
              {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" /> */}
              {/* <Button color="inherit"
              sx={{ ml: 1, textTransform: "none" }}
              size="small"
              disabled={profileNotCreated}
              onClick={() => navigate("/blood-bank")}
            >
              Store Blood
            </Button>
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" /> */}

              {/* {facilityProfileState &&
                    facilityProfileState.facilityType === "Blood Bank" && (
                      <Button
                        color="inherit"
                        sx={{ ml: 1, textTransform: "none" }}
                        endIcon={<SearchIcon />}
                        size="small"
                        disabled={
                          profileNotCreated ||
                          (facilityProfileState &&
                            facilityProfileState.verified !== "Yes")
                        }
                        onClick={() => navigate("/search-donor")}
                      >
                        Process Questionnaire
                      </Button>
                    )} */}
              {/* {facilityProfileState &&
                    facilityProfileState.facilityType !== "Blood Bank" && (
                      <Button
                        color="inherit"
                        sx={{ ml: 1, textTransform: "none" }}
                        endIcon={<SearchIcon />}
                        size="small"
                        disabled={
                          profileNotCreated ||
                          (facilityProfileState &&
                            facilityProfileState.verified !== "Yes")
                        }
                        onClick={() =>
                          navigate("/medical-facility-bloodbank-view")
                        }
                      >
                        Search for Blood
                      </Button>
                    )}
                </React.Fragment>
              )} */}
            </Box>
            {facilityProfileState && authFacility && (
              <Box sx={{ color: "black" }}>
                Hi,
                <span> {facilityProfileState.facilityName}</span>
              </Box>
            )}
            {userProfile && authDonor && (
              <Box sx={{ color: "black" }}>
                Hi,
                <span> {userProfile.fname}</span>
              </Box>
            )}
            {/* Logged in on a small screen */}
            {(authFacility || authDonor || authAdmin) && <CustomizedMenus />}
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Toolbar />
      <FeedBack
        openSnack={openSnack}
        msg={msg}
        handleCloseSnackbar={handleCloseSnackbar}
      />
    </>
  );
}
