import Header from "../components/Header";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";

export default function Decline() {
  const navigate = useNavigate();
  return (
    <>
      <Header />
      <Stack
        p={2}
        m={2}
        spacing={2}
        justifyItems="center"
        alignContent="center"
        alignItems="center"
        sx={{ maxWidth: 400, margin: "auto" }}
      >
        <Box>
          <img src="/decision.png" style={{ width: 100, height: 100 }} />
        </Box>
        <Typography
          ariant="span"
          component="span"
          sx={{ fontFamily: "fantasy", textAlign: "center" }}
        >
          Consent from potential donors before the questionnaire is filled is
          very important to ensure ethical responsibility is observed.
        </Typography>
        <Button onClick={() => navigate("/consent")}>Go Back</Button>
      </Stack>
    </>
  );
}
