import React from "react";
import { useStateValue } from "../utils/app-state-context";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import { API_URL } from "../utils/api";
import ShowHide from "../components/show-hide";
import BlobankSubHeader from "../components/BlobankSubHeader";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

export default function AdminRetrieveDonationHistory() {
  const [{ donorId, authAdmin }, _] = useStateValue();
  const [donationHis, setDonationHis] = React.useState(null);
  const [donorProfile, setDonorProfile] = React.useState(null);
  const retrieveDonationHistory = (donorId) => {
    fetch(`${API_URL}/donorHealthStatus/${donorId}`)
      .then((response) => response.json())
      .then((data) => setDonationHis(data))
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const retrieveDonorProfile = (donorId) => {
    fetch(`${API_URL}/dornorProfile/${donorId}`)
      .then((response) => response.json())
      .then((data) => setDonorProfile(data))
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  React.useEffect(() => {
    retrieveDonationHistory(donorId);
    retrieveDonorProfile(donorId);
  }, []);
  // if (!authAdmin) {
  //   return navigate("/admin");
  // }
  return (
    <>
      <Header />
      <BlobankSubHeader />

      <h2
        style={{
          margin: 10,
          padding: 10,
        }}
      >
        Donation History for{" "}
        <small
          style={{
            fontFamily: "Mulish",
            color: "purple",
          }}
        >
          {donorProfile && `${donorProfile.fname}  ${donorProfile.surname}`}
        </small>
      </h2>

      <div>
        {donationHis && donationHis.length !== 0 ? (
          donationHis.map((item) => (
            <div
              style={{
                margin: 10,
                padding: 10,
              }}
            >
              <Accordion sx={{ maxWidth: 500 }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography> User ID: {item.userId}</Typography>
                  &nbsp;&nbsp;&nbsp;
                  <br />
                </AccordionSummary>
                <AccordionDetails>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    Do You Feel Well Today: {item.feelWellToday}
                  </p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    Discomfort After Donation : {item.donationDiscomfort}
                  </p>
                  <p style={{ maxWidth: 500 }}> Tattooing: {item.Tattooing}</p>
                  <p style={{ maxWidth: 500 }}> Piercing: {item.Piercing}</p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    Dental Extraction: {item.DentalExtraction}
                  </p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    HeartDisease: {item.HeartDisease}
                  </p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    Cancer Malignant Disease: {item.CancerMalignantDisease}
                  </p>
                  <p style={{ maxWidth: 500 }}> Diabetes: {item.Diabetes}</p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    SickleCell: {item.SickleCell}
                  </p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    Hepatitis BC: {item.HepatitisBC}
                  </p>
                  <p style={{ maxWidth: 500 }}> STD: {item.STD}</p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    Typhoid in the last 1 year: {item.TyphoidLast1Yr}
                  </p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    Lung Disease: {item.LungDisease}
                  </p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    Tuberculosis: {item.Tuberculosis}
                  </p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    Allergic Disease: {item.AllergicDisease}
                  </p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    Kidney Disease: {item.KidneyDisease}
                  </p>
                  <p style={{ maxWidth: 500 }}> Epilepsy: {item.Epilepsy}</p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    Abnormal Bleeding Tendency: {item.AbnormalBleedingTtendency}
                  </p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    Jaundice in the last 1 year: {item.JaundiceLast1Yr}
                  </p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    Malaria in the last 6 Months: {item.Malaria6Months}
                  </p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    Fainting Spells: {item.FaintingSpells}
                  </p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    Antibiotics: {item.Antibiotics}
                  </p>
                  <p style={{ maxWidth: 500 }}> Steroids: {item.Steroids}</p>
                  <p style={{ maxWidth: 500 }}> Aspirin: {item.Aspirin}</p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    Vaccinations: {item.Vaccinations}
                  </p>
                  <p style={{ maxWidth: 500 }}> Alcohol: {item.Alcohol}</p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    Rabies Vaccine: {item.rabiesVaccine}
                  </p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    Surgery / Blood Transfusion in the last 6 Months:{" "}
                    {item.surgeryBloodTransfusion6Mnth}
                  </p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    Menstruation: {item.menstruation}
                  </p>
                  <p style={{ maxWidth: 500 }}> Pregnant: {item.pregnant}</p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    Abortionin the last 3 Months: {item.abortion3Mths}
                  </p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    Do you have a child less than one year ago?{" "}
                    {item.childLessThan1}
                  </p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    Rheumatic Fever: {item.rheumaticFever}
                  </p>
                  <p style={{ maxWidth: 500 }}> Chest Pain: {item.chestPain}</p>
                  <p style={{ maxWidth: 500 }}> Asthma: {item.asthma}</p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    In the past 12 months, have you been in close contact with a
                    person with Hepatitis? {item.closeContactHepatitis}
                  </p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    Have you ever been treated for pimples with drugs such as
                    Roaccutane, Tigason, Pros car or Propecia?{" "}
                    {item.pimpleRoaccutaneTigason}
                  </p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    injectionPitutaryHormone: {item.injectionPitutaryHormone}
                  </p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    Have you ever received a tissue or organ transplant e.g,
                    Corneo, dura mater, kidney, liver, bone marrow?{" "}
                    {item.tissueOrgnaTransplant}
                  </p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    Have you or your close relatives had an unexplained brain
                    condition or been diagnosed with a condition called
                    Cruelzfeld-Jakob disease (human form of mac cow disease) ?{" "}
                    {item.cruelzfeldJakob}
                  </p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    If had malaria in the last two weeks what treatment did you
                    receive: {item.iFMalaria2WeeksWhaDrug}
                  </p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    In the past six months have you had more than one sex
                    partner, engaged in casual sex with someone whose sexual
                    background you don't know? {item.past6MthsSexUnknownPart}
                  </p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    In the past five years, have you had male to male sex?{" "}
                    {item.past5YrsM2MSx}
                  </p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    In the past five years, have you had sex with a Male or
                    female prostitute (escort or sex worker) or exchanged money,
                    drugs, goods or favours in return for sex?{" "}
                    {item.past5YrsSxProstitute}
                  </p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    In the past five years, have you had sex with a Male or
                    female prostitute (escort or sex worker) or exchanged money,
                    drugs, goods or favours in return for sex?{" "}
                    {item.past5YrsSTD}
                  </p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    Are you HIV positive? {item.HIVPositive}
                  </p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    In the past twelve months, have you had accidential exposure
                    to blood or body fluids or been the victim of sexual
                    assault? {item.past12MthsSxAssault}
                  </p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    Have you ever injected yourself, or been injected with any
                    drug or substance including steroids, not prescribed by a
                    doctor? {item.injectedUrself}
                  </p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    To your knowledge, does any of the above apply to your sex
                    partner? {item.appliedSxPartner}
                  </p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    donatTestd4HivAids: {item.donatTestd4HivAids}
                  </p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    In the last four hours have you had meal or snack?{" "}
                    {item.inLast4HoursHadMealSnack}
                  </p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    In the past twelve months, have you suffered from nigth
                    sweets, unintentional weigth loss, persistent fever,
                    diarrhoea or swollen glands?{" "}
                    {item.past12MonSuffNigthSweatUnWeigtLossFeverDiSw}
                  </p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    Are you involved in a hazardous occupation such as driving a
                    public or heavy duty , flying an aeroplane, working on
                    scaffolding, etc that might endanger you or anyone else if
                    you become ligtheaded or faint? {item.hazardousOccupation}
                  </p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    Have you been receiving any medical treatment or taking any
                    medication? {item.anyMedication}
                  </p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    In the past four weeks, have you experienced vomitting or
                    diarrhoea? {item.vomittingDiarrhoea}
                  </p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    Do you have high(or low) blood pressure?{" "}
                    {item.lowHighBloodPressure}
                  </p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    Would you like to be informed about any abnormal test result
                    at the address furnished by you? {item.feedback}
                  </p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    Have you read and understood all the information presented
                    and answered all the questions truthfully? As any incorrect
                    statement or concealment may affect your health or may harm
                    the recipient? {item.readUnderstoodAll}
                  </p>
                  <ShowHide
                    item={
                      <>
                        <h3>Laboratory Results</h3>
                        <p style={{ maxWidth: 500 }}>
                          {" "}
                          Donor Grp Clinic: {item.donorGrpClinic}
                        </p>
                        <p style={{ maxWidth: 500 }}>
                          {" "}
                          Clinic Staff Date: {item.clinicStaffDate}
                        </p>
                        <p style={{ maxWidth: 500 }}>
                          {" "}
                          Donor Accepted: {item.donorAccepted}
                        </p>
                        <p style={{ maxWidth: 500 }}>
                          {" "}
                          Collection Start Time: {item.collectionStartTime}
                        </p>
                        <p style={{ maxWidth: 500 }}>
                          {" "}
                          Collection End Time: {item.collectionEndTime}
                        </p>
                        <p style={{ maxWidth: 500 }}>
                          {" "}
                          Volume Donated: {item.volumeDonated}
                        </p>
                        <p style={{ maxWidth: 500 }}>
                          {" "}
                          Blood Group: {item.bloodGrp}
                        </p>
                        <p style={{ maxWidth: 500 }}>
                          {" "}
                          Haemoglobin Level: {item.HaemoglobinLev}
                        </p>
                        <p style={{ maxWidth: 500 }}>
                          {" "}
                          Phlebotomist Name: {item.PhlebotomistName}
                        </p>
                        <p style={{ maxWidth: 500 }}>
                          {" "}
                          Assisttant Name: {item.assisttantName}
                        </p>
                        <p style={{ maxWidth: 500 }}>
                          {" "}
                          Type Of Pack: {item.typeOfPack}
                        </p>
                        <p style={{ maxWidth: 500 }}>
                          {" "}
                          Phone No: {item.signature1}
                        </p>
                        <p style={{ maxWidth: 500 }}>
                          {" "}
                          Phone No: {item.signature2}
                        </p>
                        
                        <p style={{ maxWidth: 500 }}> weight: {item.weight}</p>
                        <p style={{ maxWidth: 500 }}> pulse: {item.pulse}</p>
                        <p style={{ maxWidth: 500 }}> bp: {item.bp}</p>
                        <p style={{ maxWidth: 500 }}>
                          {" "}
                          Blood Type: {item.bloodTypes}
                        </p>
                        <p style={{ maxWidth: 500 }}>
                          {" "}
                          temperature: {item.temperature}
                        </p>
                        <p style={{ maxWidth: 500 }}> form: {item.form}</p>
                        <p style={{ maxWidth: 500 }}>
                          {" "}
                          Remarks Donor Status: {item.remarksDonorStatus}
                        </p>
                        <p style={{ maxWidth: 500 }}>
                          {" "}
                          Donatn No: {item.donatnNo}
                        </p>
                      </>
                    }
                  />
                </AccordionDetails>
              </Accordion>
            </div>
          ))
        ) : (
          <span style={{ color: "purple", fontWeight: "bold" }}>
            No Donation Record
          </span>
        )}
      </div>
    </>
  );
}
