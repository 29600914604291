import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Signature from "../components/Signature";

export default function Consent() {
  const navigate = useNavigate();
  return (
    <>
      <Header />
      <Box sx={{ maxWidth: 500, m: 2, p: 2, margin: "auto" }}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Consent
        </Typography>
        <hr />
        
          <p>The Nigerian Institute of Medical Research (NIMR) conducts important
          scientific research. This research enables us to understand donor need
          and improve transfusion services. We may have taken sample(s) from you
          for the purpose of blood donation. It is possible that the leftover of
          those samples (possibly together with some medical data) may be used
          at a later point in time for scientific research.
          </p> 
          <p>
          Only authorized researchers have access to your data and samples. 
          Each of them is bound by professional confidentiality and has also signed a
          confidentiality agreement. It is also possible that your medical data
          is used for imminent scientific research. In this case, your data will
          always be coded (your name will be replaced by a code, which makes you
          not directly identifiable) and whenever possible, your data will be
          completely anonymized. The link with your identity will be strictly
          separated. Your name or identifiable information will also never show
          up in any publications arising from such research.
          <p/> 

          <p>Prior to the start
          of any research, be it with left-over samples or only coded medical
          data, we must obtain approval from the NIMR Institutional Review Board
          (IRB). You have the right to refuse the later use of your samples and
          medical data in case you do not want this.
          </p>

          </p>
           In case you refuse the later use of your samples and medical data 
           for scientific research, this will not influence the use of yoiur 
           blood for transufion now or
          in the future. Possibly, researchers may discover something during
          such later research that is potentially hazardous to your health or
          the health of others. In this case, you will be informed about this
          out of moral and legal obligation. 
          <p>If you still have questions about
          the later use of your body samples or data, feel free to ask the
          Principal Investigator or the NIMR IRB secretary: The Chairman, NIMR
          Health Research and Ethical Committee, Nigerian Institute of Medical
          Research Yaba, Lagos. Phone-08023513399 Amoo Olufemi Samuel, Principal
          Investigator (08033367441) Statement of person giving consent: I have
          read and I now fully understand the purpose, risk and benefit of
          participating in future research. I hereby freely give my consent for
          my left-over sample or data to be used for future research.
          </p>
          <p>Kindly note that you can withdraw your consent at any time without a 
          statement of reasons. You can withdraw your consent by writing a short 
          message to the following email-address: infi@bloodhub.com.ng
              Please note that by withdrawing your consent your Bloodhub 
              profile will be deleted completely incl. your Bloodhub membership.
              </p>
        <Signature />
        <Stack
          spacing={2}
          direction={{ xs: "column", sm: "column", md: "row" }}
          justifyItems="space-between"
          mt={3}
        >
          <Button variant="outlined" onClick={() => navigate("/decline")}>
            <img src="/close-icon.png" style={{ width: 40, height: 40 }} />
            <Typography
              id="modal-modal-title"
              variant="span"
              component="span"
              sx={{ ml: 2 }}
            >
              I Decline
            </Typography>
          </Button>
          <Button
            variant="contained"
            onClick={() => navigate("/donation-form")}
          >
            <img src="/thumb-up.png" style={{ width: 40, height: 40 }} />
            <Typography
              id="modal-modal-title"
              variant="span"
              component="span"
              sx={{ ml: 2 }}
            >
              I Consent
            </Typography>
          </Button>
        </Stack>
      </Box>
      <Footer />
    </>
  );
}
