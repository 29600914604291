import React, { useState } from "react";
import { Field, Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import { TextField, Select } from "formik-mui";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Stack from "@mui/material/Stack";
import Header from "../components/Header";
import ConsentModal, { useConsentModal } from "../components/ConsentModal";
import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";
import { API_URL } from "../utils/api";
import { useStateValue } from "../utils/app-state-context";
import { useFeedBack, FeedBack } from "../components/FeedBack";
import LinearProgress from "@mui/material/LinearProgress";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import AddAlertSharpIcon from "@mui/icons-material/AddAlertSharp";
import { useNavigate } from "react-router-dom";
import { nigerianLgas, nigerianStates } from "../utils/stateAndLga";

// import useAuthentication from "../utils/useAuthentication";
// var newDate = new Date(date.setMonth(date.getMonth()+8));
// var jan312009 = new Date(2009, 0, 31);
// var eightMonthsFromJan312009  = jan312009.setMonth(jan312009.getMonth()+8);
const SubmitWhenDisqualified = () => {
  const { values, submitForm, setSubmitting } = useFormikContext();
  React.useEffect(() => {
    (values.Tattooing === "Yes" ||
      values.DentalExtraction === "Yes" ||
      values.Piercing === "Yes") &&
      submitForm();
    return setSubmitting(false);
  }, [values]);
};
export default function DonationForm() {
  const navigate = useNavigate();
  const { open, handleClose, handleOpen, handleDecline } = useConsentModal();
  const [{ userProfile, authDonor, facilityId, donorId }, dispatch] =
    useStateValue();
  const { openSnack, setOpenSnack, msg, setMsg, handleCloseSnackbar } =
    useFeedBack();
  const returnDate = (month) => {
    const date = new Date();
    const objectDate = new Date(date.setMonth(date.getMonth() + Number(month)));
    return `${objectDate.getDate()}/${
      objectDate.getMonth() + 1
    }/${objectDate.getFullYear()}`;
  };
  const [selectedState, setSelectedState] = React.useState();
  const [sugLga, setSugLga] = React.useState();
  const [sugFacility, setSugFacility] = React.useState();
  const [sugFaState, setsugFaState] = React.useState();
  const handleSugFacility = (sugFa) => {
    const res =
      facilities &&
      facilities.filter((item) => item.facilityId === Number(sugFa));
    const item = res[0];
    setsugFaState(
      `${item.facilityName} ${item.facilityType}, Address: ${item.addressStreet}, ${item.addressTown}, ${item.addressState}, Phone: ${item.telephone}`
    );
  };
  const handleStateChange = (selectedState) => setSelectedState(selectedState);
  const handleLgaChange = (selectedLga) => setSugLga(selectedLga);
  const [facilities, setFacilities] = React.useState(null);
  const retrieveBloodBanks = () => {
    fetch(`${API_URL}/facilityProfile`)
      .then((response) => response.json())
      .then((data) => setFacilities(data))
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const suggestedFacilities = () => {
    const res =
      facilities &&
      facilities.filter(
        (item) =>
          item.addressState === selectedState &&
          item.lga === sugLga &&
          item.verified === "Yes" &&
          item.facilityType === "Blood Bank"
      );

    setSugFacility(res);
  };
  React.useEffect(() => {
    suggestedFacilities();
  }, [selectedState, sugLga]);
  React.useEffect(() => {
    retrieveBloodBanks();
  }, []);
  React.useEffect(() => {
    if (!authDonor) {
      navigate("/sign-in");
    }
  }, [authDonor]);

  // facilities && console.log("facilities", facilities);
  // sugFacility && console.log("sugFacility", sugFacility);
  // selectedState && console.log("selectedState", selectedState);
  // sugLga && console.log("sugLga", sugLga);

  return (
    <>
      <Header />
      {/* <FeedBack
        openSnack={openSnack}
        msg={msg}
        handleCloseSnackbar={handleCloseSnackbar}
      />
      <ConsentModal
        open={open}
        handleClose={handleClose}
        handleOpen={handleOpen}
        handleDecline={handleDecline}
      />*/}

      <Box
        sx={{
          maxWidth: 600,
          p: 1,
          margin: "auto",
          marginTop: 3,
          borderRadius: 5,
        }}
      >
        <Formik
          enableReinitialize={true}
          initialValues={{
            donationDiscomfort: "",
            feelWellToday: "",
            inLast4HoursHadMealSnack: "",
            past12MonSuffNigthSweatUnWeigtLossFeverDiSw: "",
            hazardousOccupation: "",
            anyMedication: "",
            vomittingDiarrhoea: "",
            lowHighBloodPressure: "",
            Tattooing: "",
            Piercing: "",
            DentalExtraction: "",
            HeartDisease: "",
            CancerMalignantDisease: "",
            Diabetes: "",
            SickleCell: "",
            HepatitisBC: "",
            STD: "",
            TyphoidLast1Yr: "",
            LungDisease: "",
            Tuberculosis: "",
            AllergicDisease: "",
            KidneyDisease: "",
            Epilepsy: "",
            AbnormalBleedingTtendency: "",
            JaundiceLast1Yr: "",
            Malaria6Months: "",
            FaintingSpells: "",
            rheumaticFever: "",
            chestPain: "",
            asthma: "",
            closeContactHepatitis: "",
            pimpleRoaccutaneTigason: "",
            injectionPitutaryHormone: "",
            tissueOrgnaTransplant: "",
            cruelzfeldJakob: "",
            iFMalaria2WeeksWhaDrug: "",
            past6MthsSexUnknownPart: "",
            past5YrsM2MSx: "",
            past5YrsSxProstitute: "",
            past5YrsSTD: "",
            HIVPositive: "",
            past12MthsSxAssault: "",
            injectedUrself: "",
            appliedSxPartner: "",
            donatTestd4HivAids: "",
            Antibiotics: "",
            Steroids: "",
            Aspirin: "",
            Vaccinations: "",
            Alcohol: "",
            rabiesVaccine: "",
            surgeryBloodTransfusion6Mnth: "",
            menstruation: "",
            pregnant: "",
            abortion3Mths: "",
            childLessThan1: "",
            feedback: "",
            readUnderstoodAll: "",
            suggestedFacilities: "",
            availableFacilities: "",
            donationLga: "",
            donationState: "",
            // weight: "",
            // pulse: "",
            // bp: "",
            // temperature: "",
            // hb: "",
            // bloodTypes: "",
            createdBy: "",
            userId: "",
          }}
          validationSchema={Yup.object({
            // passphrase: Yup.string().when([], {
            //   is: () => passwordRequired && !showMessage,
            //   then: Yup.string().required("Passphrase is required"),
            //   otherwise: Yup.string().notRequired(),
            // }),
            // mandatoryTag: Yup.string().when("mandatory", {
            //   is: "Yes",
            //   then: Yup.string().required("Compulsory tag is required"),
            //   otherwise: () => Yup.string().nullable(),
            // }),
            donationDiscomfort: Yup.string().required(
              "*This field is required"
            ),
            feelWellToday: Yup.string().required("*This field is required"),
            inLast4HoursHadMealSnack: Yup.string().required(
              "*This field is required"
            ),
            past12MonSuffNigthSweatUnWeigtLossFeverDiSw: Yup.string().required(
              "*This field is required"
            ),
            hazardousOccupation: Yup.string().required(
              "*This field is required"
            ),
            anyMedication: Yup.string().required("*This field is required"),
            vomittingDiarrhoea: Yup.string().required(
              "*This field is required"
            ),
            lowHighBloodPressure: Yup.string().required(
              "*This field is required"
            ),
            Tattooing: Yup.string().required("*This field is required"),
            Piercing: Yup.string()
              .nullable()
              .when("Tattooing", {
                is: "Yes",
                otherwise: () =>
                  Yup.string().required("*This field is required"),
              }),
            DentalExtraction: Yup.string()
              .nullable()
              .when("Piercing", {
                is: "Yes",
                otherwise: () =>
                  Yup.string().required("*This field is required"),
              }),
            HeartDisease: Yup.string().required("*This field is required"),
            CancerMalignantDisease: Yup.string().required(
              "*This field is required"
            ),
            Diabetes: Yup.string().required("*This field is required"),
            SickleCell: Yup.string().required("*This field is required"),
            HepatitisBC: Yup.string().required("*This field is required"),
            STD: Yup.string().required("*This field is required"),
            TyphoidLast1Yr: Yup.string().required("*This field is required"),
            LungDisease: Yup.string().required("*This field is required"),
            Tuberculosis: Yup.string().required("*This field is required"),
            AllergicDisease: Yup.string().required("*This field is required"),
            KidneyDisease: Yup.string().required("*This field is required"),
            Epilepsy: Yup.string().required("*This field is required"),
            AbnormalBleedingTtendency: Yup.string().required(
              "*This field is required"
            ),
            JaundiceLast1Yr: Yup.string().required("*This field is required"),
            Malaria6Months: Yup.string().required("*This field is required"),
            rheumaticFever: Yup.string().required("*This field is required"),
            chestPain: Yup.string().required("*This field is required"),
            asthma: Yup.string().required("*This field is required"),
            closeContactHepatitis: Yup.string().required(
              "*This field is required"
            ),
            pimpleRoaccutaneTigason: Yup.string().required(
              "*This field is required"
            ),
            injectionPitutaryHormone: Yup.string().required(
              "*This field is required"
            ),
            tissueOrgnaTransplant: Yup.string().required(
              "*This field is required"
            ),
            cruelzfeldJakob: Yup.string().required("*This field is required"),

            past6MthsSexUnknownPart: Yup.string().required(
              "*This field is required"
            ),
            past5YrsM2MSx: Yup.string().required("*This field is required"),
            past5YrsSxProstitute: Yup.string().required(
              "*This field is required"
            ),
            past5YrsSTD: Yup.string().required("*This field is required"),
            HIVPositive: Yup.string().required("*This field is required"),
            past12MthsSxAssault: Yup.string().required(
              "*This field is required"
            ),
            injectedUrself: Yup.string().required("*This field is required"),
            appliedSxPartner: Yup.string().required("*This field is required"),
            donatTestd4HivAids: Yup.string().required(
              "*This field is required"
            ),

            FaintingSpells: Yup.string().required("*This field is required"),
            Antibiotics: Yup.string().required("*This field is required"),
            Steroids: Yup.string().required("*This field is required"),
            Aspirin: Yup.string().required("*This field is required"),
            Vaccinations: Yup.string().required("*This field is required"),
            Alcohol: Yup.string().required("*This field is required"),
            rabiesVaccine: Yup.string().required("*This field is required"),
            surgeryBloodTransfusion6Mnth: Yup.string().required(
              "*This field is required"
            ),
            // menstruation: Yup.string().required("*This field is required"),
            // pregnant: Yup.string().required("*This field is required"),
            // abortion3Mths: Yup.string().required("*This field is required"),
            // childLessThan1: Yup.string().required("*This field is required"),
            feedback: Yup.string().required("*This field is required"),
            readUnderstoodAll: Yup.string().required("*This field is required"),
            // suggestedFacilities: Yup.string().required(
            //   "*This field is required"
            // ),
            donationLga: Yup.string().required("*This field is required"),
            donationState: Yup.string().required("*This field is required"),

            // weight: Yup.string().required("*This field is required"),
            // pulse: Yup.string().required("*This field is required"),
            // bp: Yup.string().required("*This field is required"),
            // temperature: Yup.string().required("*This field is required"),
            // hb: Yup.string().required("*This field is required"),
            // bloodTypes: Yup.string().required("*This field is required"),
          })}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            try {
              const response = await fetch(`${API_URL}/donorHealthStatus`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                   donationDiscomfort : values.donationDiscomfort,
                   feelWellToday : values.feelWellToday,
                   inLast4HoursHadMealSnack : values.inLast4HoursHadMealSnack,
                   past12MonSuffNigthSweatUnWeigtLossFeverDiSw : values.past12MonSuffNigthSweatUnWeigtLossFeverDiSw,
                   hazardousOccupation : values.hazardousOccupation,
                   anyMedication : values.anyMedication,
                   vomittingDiarrhoea : values.vomittingDiarrhoea,
                   lowHighBloodPressure : values.lowHighBloodPressure,
                   Tattooing : values.Tattooing,
                   Piercing : values.Piercing,
                   DentalExtraction : values.DentalExtraction,
                   HeartDisease : values.HeartDisease,
                   CancerMalignantDisease : values.CancerMalignantDisease,
                   Diabetes : values.Diabetes,
                   SickleCell : values.SickleCell,
                   HepatitisBC : values.HepatitisBC,
                   STD : values.STD,
                   TyphoidLast1Yr : values.TyphoidLast1Yr,
                   LungDisease : values.LungDisease,
                   Tuberculosis : values.Tuberculosis,
                   AllergicDisease : values.AllergicDisease,
                   KidneyDisease : values.KidneyDisease,
                   Epilepsy : values.Epilepsy,
                   AbnormalBleedingTtendency : values.AbnormalBleedingTtendency,
                   JaundiceLast1Yr : values.JaundiceLast1Yr,
                   Malaria6Months : values.Malaria6Months,
                   FaintingSpells : values.FaintingSpells,
                   rheumaticFever : values.rheumaticFever,
                   chestPain : values.chestPain,
                   asthma : values.asthma,
                   closeContactHepatitis : values.closeContactHepatitis,
                   pimpleRoaccutaneTigason : values.pimpleRoaccutaneTigason,
                   injectionPitutaryHormone : values.injectionPitutaryHormone,
                   tissueOrgnaTransplant : values.tissueOrgnaTransplant,
                   cruelzfeldJakob : values.cruelzfeldJakob,
                   iFMalaria2WeeksWhaDrug : values.iFMalaria2WeeksWhaDrug,
                   past6MthsSexUnknownPart : values.past6MthsSexUnknownPart,
                   past5YrsM2MSx : values.past5YrsM2MSx,
                   past5YrsSxProstitute : values.past5YrsSxProstitute,
                   past5YrsSTD : values.past5YrsSTD,
                   HIVPositive : values.HIVPositive,
                   past12MthsSxAssault : values.past12MthsSxAssault,
                   injectedUrself : values.injectedUrself,
                   appliedSxPartner : values.appliedSxPartner,
                   donatTestd4HivAids : values.donatTestd4HivAids,
                   Antibiotics : values.Antibiotics,
                   Steroids : values.Steroids,
                   Aspirin : values.Aspirin,
                   Vaccinations : values.Vaccinations,
                   Alcohol : values.Alcohol,
                   rabiesVaccine : values.rabiesVaccine,
                   surgeryBloodTransfusion6Mnth : values.surgeryBloodTransfusion6Mnth,
                   menstruation : values.menstruation,
                   pregnant : values.pregnant,
                   abortion3Mths : values.abortion3Mths,
                   childLessThan1 : values.childLessThan1childLessThan1,
                   feedback : values.feedback,
                   readUnderstoodAll : values.readUnderstoodAll,
                   donationLga : values.donationLga,
                   donationState : values.donationState,
                   suggestedFacilities:values.suggestedFacilities||values.availableFacilities,
                   createdBy: facilityId,
                   userId: donorId,
                }),
              });
              if (!response.ok) {
                throw new Error(response.status);
              }
              const res = await response.json();
              if (res) {
                setMsg(`Submission was Successfully. `);
                setOpenSnack(true);
              }
              setTimeout(
                () =>
                  navigate("/donation-form-submitted", {
                    state: { sugFaState },
                  }),
                90
              );
              setSubmitting(false);
            } catch (e) {
              console.error("Error adding document: ", e);
              setSubmitting(false);
            }
          }}
        >
          {({ submitForm, isSubmitting, values }) => (
            <Form>
              <Box
                sx={{
                  borderRadius: 5,
                  width: "100%",
                  //   background: "rgba(229,255,239)",
                  margin: "auto",
                  // background:
                  //   "linear-gradient(135deg, rgba(0,127,128,0.47942927170868344) 7%, rgba(118,148,216,0.4962359943977591) 35%, rgba(162,231,227,0.4990371148459384) 60%, rgba(229,255,239,1) 93%)",
                }}
              >
                {isSubmitting && <LinearProgress sx={{ my: 2 }} />}
                <Stack
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                  style={{ margin: 10 }}
                >
                  <Typography
                    component="div"
                    noWrap
                    style={{ marginTop: "20", marginBottom: "20" }}
                  >
                    <h2>Donation Health Status Form</h2>
                  </Typography>
                  <Typography
                    id="modal-modal-description"
                    sx={{ mt: 2, textAlign: "justify", color: "maroon" }}
                  >
                    <AddAlertSharpIcon sx={{ mb: -0.5, pl: 2, pr: 2 }} />
                    Please read and understand all the information presented and
                    answer all the questions truthfully, as any incorrect
                    statement or concealment may affect your health or may harm
                    the recipient.
                  </Typography>
                  <FormControl fullWidth>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="donationDiscomfort"
                      size="small"
                      label="Do you have any discomfort during/after donation?"
                      variant="outlined"
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Field>
                    {values.donationDiscomfort === "Yes" && (
                      <span style={{ color: "green", fontWeight: "bold" }}>
                        We will ensure that the process would be seamless, and
                        you will feel very comfortable
                      </span>
                    )}
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="feelWellToday"
                      size="small"
                      label="Are you feeling well and in good health today?"
                      variant="outlined"
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Field>
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="inLast4HoursHadMealSnack"
                      size="small"
                      label="In the last four hours have you had meal or snack?"
                      variant="outlined"
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Field>
                  </FormControl>
                  <FormControl fullWidth>
                    <FormHelperText sx={{ mb: 2 }}>
                      In the past twelve months, have you suffered from nigth
                      sweets, unintentional weigth loss, persistent fever,
                      diarrhoea or swollen glands?
                    </FormHelperText>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="past12MonSuffNigthSweatUnWeigtLossFeverDiSw"
                      size="small"
                      label="In the past twelve months, have you suffered from nigth sweets, unintentional weigth loss, persistent fever, diarrhoea  or swollen glands?"
                      variant="outlined"
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Field>
                  </FormControl>
                  <FormControl fullWidth>
                    <FormHelperText sx={{ mb: 2 }}>
                      Are you involved in a hazardous occupation such as driving
                      a public or heavy duty , flying an aeroplane, working on
                      scaffolding, etc that might endanger you or anyone else if
                      you become ligtheaded or faint?
                    </FormHelperText>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="hazardousOccupation"
                      size="small"
                      label="Are you involved in a hazardous occupation such as driving a public or heavy duty , flying an aeroplane, working on scaffolding, etc that might endanger you or anyone else if you become ligtheaded or faint?"
                      variant="outlined"
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Field>
                  </FormControl>
                  <FormControl fullWidth>
                    <FormHelperText sx={{ mb: 2 }}>
                      Have you been receiving any medical treatment or taking
                      any medication?
                    </FormHelperText>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="anyMedication"
                      size="small"
                      label="Have you been receiving any medical treatment or taking any medication?"
                      variant="outlined"
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Field>
                  </FormControl>
                  <FormControl fullWidth>
                    <FormHelperText>
                      In the past four weeks, have you experienced vomitting or
                      diarrhoea?
                    </FormHelperText>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="vomittingDiarrhoea"
                      size="small"
                      label="In the past four weeks, have you experienced vomitting or diarrhoea?"
                      variant="outlined"
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Field>
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="lowHighBloodPressure"
                      size="small"
                      label="Do you have high(or low) blood pressure?"
                      variant="outlined"
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Field>
                  </FormControl>
                  <Typography
                    variant="body2"
                    display="block"
                    gutterBottom
                    sx={{ fontSize: 18 }}
                  >
                    In the last <b>six months</b> have you had any?
                  </Typography>
                  <FormControl fullWidth>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="Tattooing"
                      size="small"
                      label="Tatooing"
                      variant="outlined"
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Field>
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="Piercing"
                      size="small"
                      label="Piercing"
                      variant="outlined"
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Field>
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="DentalExtraction"
                      size="small"
                      label="Dental Procedure or Tooth Extraction"
                      variant="outlined"
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Field>
                  </FormControl>
                  <Typography
                    variant="body2"
                    display="block"
                    gutterBottom
                    sx={{ fontSize: 18, mt: 3 }}
                  >
                    Do you suffer from or have suffered from any of the
                    following diseases?
                  </Typography>
                  <FormControl fullWidth>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="HeartDisease"
                      size="small"
                      label="Heart Disease"
                      variant="outlined"
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Field>
                  </FormControl>
                  <FormControl fullWidth>
                    <FormHelperText sx={{ mb: 2 }}>
                      Cancer malignant disease, a blood disease, an abnormal
                      bleeding disoredr or a bleeding gastric or duodenal ulcer
                    </FormHelperText>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="CancerMalignantDisease"
                      size="small"
                      label="Cancer malignant disease, a blood disease, an abnormal bleeding disoredr or a bleeding gastric or duodenal ulcer"
                      variant="outlined"
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Field>
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="Diabetes"
                      size="small"
                      label="Diabetes"
                      variant="outlined"
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Field>
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="SickleCell"
                      size="small"
                      label="Sickle Cell"
                      variant="outlined"
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Field>
                  </FormControl>
                  <FormControl fullWidth>
                    <FormHelperText sx={{ mb: 2 }}>
                      Hepatitis BC, yellow jaundice, or liver disease(except
                      jaundice at birth)
                    </FormHelperText>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="HepatitisBC"
                      size="small"
                      label="Hepatitis BC, yellow jaundice, or liver disease(except jaundice at birth)"
                      variant="outlined"
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Field>
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="STD"
                      size="small"
                      label=" Sexually Transmitted Diseases"
                      variant="outlined"
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Field>
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="TyphoidLast1Yr"
                      size="small"
                      label="Typhoid (last on year)"
                      variant="outlined"
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Field>
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="LungDisease"
                      size="small"
                      label="Lung Disease"
                      variant="outlined"
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Field>
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="Tuberculosis"
                      size="small"
                      label="Tuberculosis"
                      variant="outlined"
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Field>
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="AllergicDisease"
                      size="small"
                      label="Allergic Disease"
                      variant="outlined"
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Field>
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="KidneyDisease"
                      size="small"
                      label="Kidney Disease"
                      variant="outlined"
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Field>
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="Epilepsy"
                      size="small"
                      label="Epilepsy"
                      variant="outlined"
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Field>
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="AbnormalBleedingTtendency"
                      size="small"
                      label="Abnormal Bleeding Tendency"
                      variant="outlined"
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Field>
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="JaundiceLast1Yr"
                      size="small"
                      label="Jaundice (last one year)"
                      variant="outlined"
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Field>
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="Malaria6Months"
                      size="small"
                      label="Malaria (two weeks)"
                      variant="outlined"
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Field>
                  </FormControl>
                  {values.Malaria6Months === "Yes" && (
                    <FormControl fullWidth>
                      <FormHelperText sx={{ mb: 2 }}>
                        If had malaria in the last two weeks what treatment did
                        you receive
                      </FormHelperText>
                      <Field
                        sx={{ mb: 3 }}
                        component={TextField}
                        name="iFMalaria2WeeksWhaDrug"
                        size="small"
                        label="If had malaria in the last two weeks what treatment did you receive"
                        variant="outlined"
                      />
                    </FormControl>
                  )}
                  <FormControl fullWidth>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="FaintingSpells"
                      size="small"
                      label="Fainting Spells"
                      variant="outlined"
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Field>
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="rheumaticFever"
                      size="small"
                      label="Rheumatic Fever"
                      variant="outlined"
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Field>
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="chestPain"
                      size="small"
                      label="Chest Pain"
                      variant="outlined"
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Field>
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="asthma"
                      size="small"
                      label="Asthma"
                      variant="outlined"
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Field>
                  </FormControl>
                  <FormControl fullWidth>
                    <FormHelperText sx={{ mb: 2 }}>
                      In the past 12 months, have you been in close contact with
                      a person with Hepatitis?
                    </FormHelperText>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="closeContactHepatitis"
                      size="small"
                      label="In the past 12 months, have you been in close contact with a person with  Hepatitis?"
                      variant="outlined"
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Field>
                  </FormControl>
                  <FormControl fullWidth>
                    <FormHelperText sx={{ mb: 2 }}>
                      Have you ever been treated for pimples with drugs such as
                      Roaccutane, Tigason, Pros car or Propecia?
                    </FormHelperText>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="pimpleRoaccutaneTigason"
                      size="small"
                      label="Have you ever been treated for pimples with drugs such as Roaccutane, Tigason, Pros car or Propecia?"
                      variant="outlined"
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Field>
                  </FormControl>
                  <FormControl fullWidth>
                    <FormHelperText sx={{ mb: 2 }}>
                      Have you ever had injection or human pitutary growth
                      hormone, pitutary gonadotropins(fertility medicines), or
                      seen a neurosurgeon or neurologist?
                    </FormHelperText>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="injectionPitutaryHormone"
                      size="small"
                      label="Have you ever had injection or human pitutary growth hormone, pitutary gonadotropins(fertility medicines), or seen a neurosurgeon or neurologist?"
                      variant="outlined"
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Field>
                  </FormControl>
                  <FormControl fullWidth>
                    <FormHelperText sx={{ mb: 2 }}>
                      Have you ever received a tissue or organ transplant e.g,
                      Corneo, dura mater, kidney, liver, bone marrow?
                    </FormHelperText>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="tissueOrgnaTransplant"
                      size="small"
                      label="Have you ever received a tissue or organ transplant e.g, Corneo, dura mater, kidney, liver, bone marrow?"
                      variant="outlined"
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Field>
                  </FormControl>
                  <FormControl fullWidth>
                    <FormHelperText sx={{ mb: 2 }}>
                      Have you or your close relatives had an unexplained brain
                      condition or been diagnosed with a condition called
                      Cruelzfeld-Jakob disease (human form of mac cow disease) ?
                    </FormHelperText>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="cruelzfeldJakob"
                      size="small"
                      label="Have you or your close relatives had an unexplained brain condition or been diagnosed with a condition called Cruelzfeld-Jakob disease (human form of mac cow disease) ?"
                      variant="outlined"
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Field>
                  </FormControl>
                  <Typography
                    variant="body2"
                    display="block"
                    gutterBottom
                    sx={{ fontSize: 18, mt: 3 }}
                  >
                    Are you taking or have you been taking any of these in the
                    past 3 days (72 hours)?{" "}
                  </Typography>
                  <FormControl fullWidth>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="Antibiotics"
                      size="small"
                      label="Antibiotics"
                      variant="outlined"
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Field>
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="Steroids"
                      size="small"
                      label="Steroids"
                      variant="outlined"
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Field>
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="Aspirin"
                      size="small"
                      label="Aspirin or any other pain relieving medication"
                      variant="outlined"
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Field>
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="Vaccinations"
                      size="small"
                      label="Vaccinations"
                      variant="outlined"
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Field>
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="Alcohol"
                      size="small"
                      label="Alcohol"
                      variant="outlined"
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Field>
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="rabiesVaccine"
                      size="small"
                      label="Dog bite Rabies vaccine (1 year)?"
                      variant="outlined"
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Field>
                  </FormControl>
                  <FormControl fullWidth>
                    <FormHelperText sx={{ mb: 2 }}>
                      Is there any history of surgery or blood transfusion in
                      the past six months?
                    </FormHelperText>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="surgeryBloodTransfusion6Mnth"
                      size="small"
                      label="Is there any history of surgery or blood transfusion in the
                            past six months?"
                      variant="outlined"
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Field>
                  </FormControl>
                  {userProfile && userProfile.gender === "Female" && (
                    <>
                      <FormControl fullWidth>
                        <Field
                          sx={{ mb: 3 }}
                          component={Select}
                          name="menstruation"
                          size="small"
                          label="Are you on your period (menstruation)?"
                          variant="outlined"
                        >
                          <MenuItem value={"Yes"}>Yes</MenuItem>
                          <MenuItem value={"No"}>No</MenuItem>
                        </Field>
                      </FormControl>
                      <FormControl fullWidth>
                        <Field
                          sx={{ mb: 3 }}
                          component={Select}
                          name="pregnant"
                          size="small"
                          label=" Are you pregnant or breast feeding?"
                          variant="outlined"
                        >
                          <MenuItem value={"Yes"}>Yes</MenuItem>
                          <MenuItem value={"No"}>No</MenuItem>
                        </Field>
                      </FormControl>
                      <FormControl fullWidth>
                        <Field
                          sx={{ mb: 3 }}
                          component={Select}
                          name="abortion3Mths"
                          size="small"
                          label="Have you had an abortion in the last three months?"
                          variant="outlined"
                        >
                          <MenuItem value={"Yes"}>Yes</MenuItem>
                          <MenuItem value={"No"}>No</MenuItem>
                        </Field>
                      </FormControl>
                      <FormControl fullWidth>
                        <Field
                          sx={{ mb: 3 }}
                          component={Select}
                          name="childLessThan1"
                          size="small"
                          label="Do you have a child less than one year old?"
                          variant="outlined"
                        >
                          <MenuItem value={"Yes"}>Yes</MenuItem>
                          <MenuItem value={"No"}>No</MenuItem>
                        </Field>
                      </FormControl>
                    </>
                  )}

                  <FormControl fullWidth>
                    <FormHelperText sx={{ mb: 2 }}>
                      In the past six months have you had more than one sex
                      partner, engaged in casual sex with someone whose sexual
                      background you don't know?
                    </FormHelperText>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="past6MthsSexUnknownPart"
                      size="small"
                      label="In the past six months have you had more than one sex partner, engaged in casual sex with someone whose sexual background you don't know?"
                      variant="outlined"
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Field>
                  </FormControl>
                  <FormControl fullWidth>
                    <FormHelperText sx={{ mb: 2 }}>
                      In the past five years, have you had male to male sex?
                    </FormHelperText>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="past5YrsM2MSx"
                      size="small"
                      label="In the past five years, have you had male to male sex?"
                      variant="outlined"
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Field>
                  </FormControl>

                  <FormControl fullWidth>
                    <FormHelperText sx={{ mb: 2 }}>
                      In the past five years, have you had sex with a Male or
                      female prostitute (escort or sex worker) or exchanged
                      money, drugs, goods or favours in return for sex?
                    </FormHelperText>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="past5YrsSxProstitute"
                      size="small"
                      label="In the past five years, have you had sex with a Male or female prostitute (escort or sex worker) or exchanged money, drugs, goods or favours in return for sex?"
                      variant="outlined"
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Field>
                  </FormControl>

                  <FormControl fullWidth>
                    <FormHelperText sx={{ mb: 2 }}>
                      In the past five years, have you had sexually transmitted
                      disease (STD) e.g Syphilis, gonorhea, genital herpes,
                      genital ulcer 'VD', Drop?
                    </FormHelperText>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="past5YrsSTD"
                      size="small"
                      label="In the past five years, have you had sexually transmitted disease (STD) e.g Syphilis, gonorhea, genital herpes, genital ulcer 'VD', Drop?"
                      variant="outlined"
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Field>
                  </FormControl>

                  <FormControl fullWidth>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="HIVPositive"
                      size="small"
                      label="Are you HIV positive?"
                      variant="outlined"
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Field>
                  </FormControl>

                  <FormControl fullWidth>
                    <FormHelperText sx={{ mb: 2 }}>
                      In the past twelve months, have you had accidential
                      exposure to blood or body fluids or been the victim of
                      sexual assault?
                    </FormHelperText>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="past12MthsSxAssault"
                      size="small"
                      label="In the past twelve months, have you had accidential exposure to blood or body fluids or been the victim of sexual assault?"
                      variant="outlined"
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Field>
                  </FormControl>

                  <FormControl fullWidth>
                    <FormHelperText sx={{ mb: 2 }}>
                      Have you ever injected yourself, or been injected with any
                      drug or substance including steroids, not prescribed by a
                      doctor?
                    </FormHelperText>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="injectedUrself"
                      size="small"
                      label="Have you ever injected yourself, or been injected with any drug or substance including steroids, not prescribed by a doctor?"
                      variant="outlined"
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Field>
                  </FormControl>
                  <FormControl fullWidth>
                    <FormHelperText sx={{ mb: 2 }}>
                      To your knowledge, does any of the above apply to your sex
                      partner?
                    </FormHelperText>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="appliedSxPartner"
                      size="small"
                      label="To your knowledge, does any of the above apply to your sex partner?"
                      variant="outlined"
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Field>
                  </FormControl>
                  <FormControl fullWidth>
                    <FormHelperText sx={{ mb: 2 }}>
                      Have you come to donate blood just to be tested for
                      HIV/AIDS?
                    </FormHelperText>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="donatTestd4HivAids"
                      size="small"
                      label="Have you come to donate blood just to be tested for HIV/AIDS?"
                      variant="outlined"
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Field>
                  </FormControl>

                  <FormControl fullWidth>
                    <FormHelperText sx={{ mb: 2 }}>
                      Would you like to be informed about any abnormal test
                      result at the address furnished by you?
                    </FormHelperText>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="feedback"
                      size="small"
                      label="Would you like to be informed about any abnormal test result
                            at the address furnished by you?"
                      variant="outlined"
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Field>
                  </FormControl>
                  <FormControl fullWidth>
                    <FormHelperText sx={{ mb: 2 }}>
                      Have you read and understood all the information presented
                      and answered all the questions truthfully? As any
                      incorrect statement or concealment may affect your health
                      or may harm the recipient
                    </FormHelperText>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      name="readUnderstoodAll"
                      size="small"
                      label="Have you read and understood all the information presented
                            and answered all the questions truthfully? As any incorrect
                            statement or concealment may affect your health or may harm
                            the recipient?"
                      variant="outlined"
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Field>
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      component={Select}
                      name="donationState"
                      size="small"
                      label="State where you want to donate"
                      variant="standard"
                      onChange={(e) => handleStateChange(e.target.value)}
                    >
                      {nigerianStates()}
                    </Field>
                  </FormControl>
                  <FormControl fullWidth>
                    <Field
                      sx={{ mb: 3 }}
                      component={Select}
                      fullWidth
                      name="donationLga"
                      size="small"
                      label="Local where you want to donate"
                      variant="standard"
                      onChange={(e) => handleLgaChange(e.target.value)}
                    >
                      {selectedState && nigerianLgas(selectedState)}
                    </Field>
                  </FormControl>
                  {sugFacility && sugFacility.length !== 0 ? (
                    <FormControl fullWidth>
                      <Field
                        sx={{ mb: 3 }}
                        component={Select}
                        name="suggestedFacilities"
                        size="small"
                        label="suggested Facilities"
                        variant="outlined"
                        onChange={(e) => handleSugFacility(e.target.value)}
                      >
                        {sugFacility &&
                          sugFacility.map((item) => (
                            <MenuItem
                              key={item.facilityId}
                              value={item.facilityId}
                            >{`${item.facilityName} ${item.facilityType}, Address: ${item.addressStreet}, ${item.addressTown}, ${item.addressState}, Phone: ${item.telephone}`}</MenuItem>
                          ))}
                      </Field>
                    </FormControl>
                  ) : values.donationLga !== "" ? (
                    <span style={{ fontWeight: "bold", color: "green" }}>
                      There is no registered blood donation facility in your
                      selected state and / or local government area. You can choose from 
                      the available ones bellow:
                    </span>
                  ) : null}
                  {sugFacility && sugFacility.length === 0 &&<FormControl fullWidth>
                      <Field
                        sx={{ mb: 3 }}
                        component={Select}
                        name="availableFacilities"
                        size="small"
                        label="Available Blood Bank Facilities"
                        variant="outlined"
                        onChange={(e) => handleSugFacility(e.target.value)}
                      >
                        {facilities &&
                          facilities.filter(
                            (item) =>
                              item.verified === "Yes" &&
                              item.facilityType === "Blood Bank"
                          ).map((item) => (
                            <MenuItem
                              key={item.facilityId}
                              value={item.facilityId}
                            >{`${item.facilityName} ${item.facilityType}, Address: ${item.addressStreet}, ${item.addressTown}, ${item.addressState}, Phone: ${item.telephone}`}</MenuItem>
                          ))}
                        
                      </Field>
                    </FormControl>}
                </Stack>
                <Stack
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                  style={{ margin: 10 }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={
                      isSubmitting || values.readUnderstoodAll !== "Yes"
                    }
                    onClick={submitForm}
                    style={{
                      margin: 20,
                      background: "#0C2340",
                      paddingLeft: "40px",
                      paddingRight: "40px",
                      textTransform: "none",
                    }}
                  >
                    {isSubmitting ? (
                      <span style={{ color: "yellow" }}> Submitting...</span>
                    ) : (
                      "Submit"
                    )}
                  </Button>
                  <p>
                    {values.readUnderstoodAll === "No" && (
                      <small style={{ color: "red" }}>
                        To submit you must select Yes to the question:
                        <br />
                        "Have you read and understood all the information
                        presented and answered all the questions truthfully? As
                        any incorrect statement or concealment may affect your
                        health or may harm the recipient?"
                      </small>
                    )}
                  </p>
                </Stack>
              </Box>
              {/* <SubmitWhenDisqualified /> */}
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
}
