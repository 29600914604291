import React, { Suspense } from "react";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";

const Header = React.lazy(() => import("../components/Header"));
const HomeHero = React.lazy(() => import("../components/HomeHero"));
const HomeObjective = React.lazy(() => import("../components/HomeObjective"));
const CollaboratingTeam = React.lazy(() =>
  import("../components/CollaboratingTeam")
);
const Footer = React.lazy(() => import("../components/Footer"));
const TeamCard = React.lazy(() => import("../components/TeamCard"));

export function Skeleton2() {
  return (
    <>
      <Skeleton
        sx={{ height: 190, mb: 5 }}
        animation="wave"
        variant="rectangular"
      />
      <Skeleton
        sx={{ height: 190, mb: 5 }}
        animation="wave"
        variant="rectangular"
      />
    </>
  );
}

export default function NewHome() {
  return (
    <>
      <Suspense fallback={<Skeleton2 />}>
        <Header />
      </Suspense>
      <Suspense fallback={<Skeleton2 />}>
        <HomeHero />
      </Suspense>
      <Suspense fallback={<Skeleton2 />}>
        <HomeObjective />
      </Suspense>
      <Suspense fallback={<Skeleton2 />}>
        <CollaboratingTeam />
      </Suspense>
      <Suspense fallback={<Skeleton2 />}>
        <Footer />
      </Suspense>
      <Suspense fallback={<Skeleton2 />}>
      
      </Suspense>
      
    </>
  );
}
