import * as React from "react";
import { Box } from "@mui/system";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import { Field, Formik, Form } from "formik";
import * as Yup from "yup";
import { TextField } from "formik-mui";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LinearProgress from "@mui/material/LinearProgress";
import { FormHelperText } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { API_URL } from "../utils/api";
import SiginUpSuccessful from "./SiginUpSuccessful";
import { useStateValue } from "../utils/app-state-context";
import Header from "../components/Header";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

export default function SignUp() {
  const navigate = useNavigate();
  const [{ facilityId }, dispatch] = useStateValue();
  const [pvalues, setPvalues] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });
  const [errMsg, setErrMsg] = React.useState("");
  const [acctStatus, setAcctStatus] = React.useState(null);
  const handleClickShowPassword = () => {
    setPvalues({
      ...pvalues,
      showPassword: !pvalues.showPassword,
    });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <Header />
      <Stack
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          alignItems: "center",
          alignContent: "center",
          pt: 5,
        }}
      >
        {/* acctStatus !== null && acctStatus.signedup==="taken" && */}
        {acctStatus !== null && acctStatus.signedup !== "taken" && (
          <SiginUpSuccessful
            username={acctStatus.username}
            password={acctStatus.password}
            createdBy={acctStatus.createdBy}
          />
        )}

        {((acctStatus !== null && acctStatus.signedup === "taken") ||
          acctStatus === null) && (
          <Formik
            enableReinitialize={true}
            initialValues={{
              username: "",
              password: "",
            }}
            validationSchema={Yup.object({
              username: Yup.string()
                .email()
                .required("*User Email is required"),
              password: Yup.string().required("*Password is required"),
            })}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);
              try {
                const response = await fetch(`${API_URL}/signup`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    ...values,
                    role: "donor",
                    createdBy: facilityId || "Self",
                  }),
                });
                if (!response.ok) {
                  throw new Error(response.status);
                }
                const res = await response.json();
                if (res) {
                  if (res.id) {
                    dispatch({
                      type: "SIGNUP-DONOR",
                      payload: {
                        donorId: res.id,
                        donorRole: res.role,
                      },
                    });
                  }
                  setAcctStatus(res);
                  setSubmitting(false);
                }
              } catch (error) {
                setErrMsg(error);
                setSubmitting(false);
              }
            }}
          >
            {({ submitForm, isSubmitting, values, handleChange }) => (
              <Form>
                {isSubmitting && <LinearProgress sx={{ my: 1, zIndex: 200 }} />}{" "}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      mb: 4,
                      color: "grey",
                    }}
                  >
                    <AccountCircleIcon sx={{ mb: -1 }} /> &nbsp;&nbsp;Sign Up
                    Donor Page
                  </Box>

                  <div
                    style={{
                      height: 3,
                      marginBottom: 20,
                      width: "100%",
                      backgroundColor: "black",
                      color: "white",
                    }}
                  ></div>
                  <Box sx={{ color: "maroon" }}>
                    Already have a account?{" "}
                    <Button
                      onClick={() => navigate("/sign-in", { replace: true })}
                    >
                      Sign In
                    </Button>
                  </Box>

                  {/* <Box
                    style={{
                      height: 3,
                      marginBottom: 20,
                      width: "100%",
                      backgroundColor: "black",
                      backgroundImage:
                        "linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%)",
                    }}
                  >
                    {" "}
                  </Box> */}
                  {acctStatus !== null && acctStatus.signedup === "taken" && (
                    <span style={{ color: "red" }}>
                      The username has already been taken
                    </span>
                  )}
                </Box>
                <Stack
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                  style={{ margin: 10 }}
                >
                  {errMsg && errMsg.message && (
                    <FormHelperText
                      error
                      style={{
                        border: "solid 1px red",
                        borderRadius: 10,
                        padding: 10,
                        fontWeight: "bold",
                      }}
                    >
                      An Error Occured:{" "}
                      {Number(errMsg.message) == 500
                        ? "Username has already been taken."
                        : errMsg.message}
                    </FormHelperText>
                  )}

                  <Field
                    component={TextField}
                    fullWidth
                    name="username"
                    type="email"
                    size="small"
                    label="Donor's Username (User Email)"
                    variant="standard"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <Field
                    component={TextField}
                    fullWidth
                    name="password"
                    size="small"
                    label="Password"
                    variant="standard"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type={pvalues.showPassword ? "text" : "password"}
                    value={values.password}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {pvalues.showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                  style={{ margin: 10 }}
                >
                  <Button
                    variant="contained"
                    disabled={isSubmitting}
                    onClick={submitForm}
                    style={{
                      margin: 20,
                      background: "#089000",
                      paddingLeft: "40px",
                      paddingRight: "40px",
                      textTransform: "none",
                      color: "#ffffff",
                    }}
                  >
                    {isSubmitting ? (
                      <span style={{ color: "yellow" }}>Submitting... </span>
                    ) : (
                      "Sign Up"
                    )}
                  </Button>
                </Stack>
              </Form>
            )}
          </Formik>
        )}
      </Stack>
    </>
  );
}
