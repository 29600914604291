import React from "react";
import Header from "../components/Header";
import { useStateValue } from "../utils/app-state-context";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import BloodBankDetailViewModal from "./BloodBankDetailViewModal";
import SearchDonor from "./SearchDonor";

export default function FacilityDashBoard() {
  const navigate = useNavigate();
  const [{ facilityProfileState, authFacility }, _] = useStateValue();
  React.useEffect(() => {
    if (!authFacility) {
      return navigate("/facility/sign-in-facility");
    }
  }, [authFacility]);

  return (
    <>
      <Header />
      <SearchDonor />
      {/* <Paper
        elevation={3}
        sx={{ p: 3, maxWidth: 300, m: 3 }}
        alignItems="flex-start"
      >
        <Stack direction="row" spacing={4}>
          <div>
            <h3>Blood Bank</h3>
            <p>
              <BloodBankDetailViewModal />
            </p>
          </div>
          <img src="/blood-icon.png" alt="" />
        </Stack>
      </Paper> */}
    </>
  );
}
