import React from "react";
import MenuItem from "@mui/material/MenuItem";
const stateAndLga = {
  "Akwa Ibom": {
    stateCode: "AK",
    lga: {
      Abak: "ABK",
      "Eastern obolo": "KRT",
      Eket: "KET",
      "Esit Eket": "kST",
      "Essien Udim": "AFH",
      "Etim Ekpo": "AEE",
      Etinam: "ETN",
      Ebeno: "PNG",
      "Ibesikpo Asutan": "NGD",
      "Ibiono Ibom": "BMT",
      Ika: "NYA",
      Ikono: "KKN",
      "Ikot Abasi": "KTS",
      "Ikot Ekpene": "KTE",
      Ini: "DRK",
      Itu: "TTU",
      Mbo: "ENW",
      "Mkpat Enin": "MKP",
      "Nsit Mbom": "AFG",
      "Nsit Ubium": "KTD",
      "Nsit Atai": "TAI",
      "Obot Akara": "NTE",
      Okobo: "KPD",
      Onna: "ABT",
      Oron: "RNN",
      Orukanam: "KTM",
      "Udung-Uko": "EYF",
      Ukanefun: "KPK",
      "Uru Offong Oruko": "UFG",
      Uruan: "DUU",
      Uyo: "UYY",
    },
  },

  Anambra: {
    stateCode: "AN",
    lga: {
      Aguata: "AGU",
      Anambra: "AAH",
      "Anambra West": "NZM",
      Anaocha: "NEN",
      "Akwa North": "ACA",
      "Akwa South": "AWK",
      Ayamelum: "NKK",
      Dunukofia: "KPP",
      Ekwusigo: "ZBL",
      "Idemili North": "GDD",
      "Idemili South": "JJT",
      Mala: "HAL",
      Njikoka: "ABN",
      "Nnewi North": "NNE",
      "Nnewi South": "UKP",
      Ogbaru: "ATN",
      "Onitsha North": "NSH",
      "Onitsha South": "FGG",
      "Orumba North": "AJL",
      "Orumba South": "UMZ",
      Oyi: "HTE",
    },
  },

  Bauchi: {
    stateCode: "BA",
    lga: {
      Alkaleri: "ALK",
      Bauchi: "BAU",
      Bogoro: "BGR",
      Damban: "DBM",
      Darazo: "DRZ",
      Dass: "DAS",
      Gamawa: "GAM",
      Ganjuwa: "GJW",
      Giade: "GYD",
      Itas: "TSG",
      Gadau: "JMA",
      "Jama Are": "KTG",
      Katagum: "KRF",
      Misau: "MSA",
      Ningi: "NNG",
      Shira: "SHR",
      "Tafawa Balewa": "TFB",
      Toro: "TRR",
      Warji: "WRJ",
      Zaki: "ZAK",
    },
  },

  Bayelsa: {
    stateCode: "BY",
    lga: {
      Brass: "BRS",
      Ekeremor: "KMR",
      Kolokuma: "KMK",
      Opkuma: "KMK",
      Nembe: "NEM",
      Ogbia: "GBB",
      Sagbama: "SAG",
      "Southern Ijaw": "SPR",
      Yenegoa: "YEN",
    },
  },

  Benue: {
    stateCode: "BN",
    lga: {
      Ado: "GMU",
      Agatu: "GTU",
      Apa: "GKP",
      Buruku: "BKB",
      Gboko: "GBK",
      Guma: "YGJ",
      "Gwer-East": "ALD",
      "Gwer-West": "NAK",
      "Katsina Ala": "KAL",
      Konshisha: "TSE",
      Kwande: "WDP",
      Logo: "GBG",
      Makurdi: "MKD",
      Obi: "BRT",
      Ogbadibo: "BGT",
      Ohimini: "DKP",
      Oju: "JUX",
      Okpokwu: "PKG",
      Otukpo: "TKP",
      Tarka: "WNN",
      Ukum: "UKM",
      Ushongo: "SEL",
      Vandeikya: "VDY",
    },
  },
  Borno: {
    stateCode: "B0",
    lga: {
      Abadam: "ADM",
      "Askira Uba": "ASU",
      Bama: "BAM",
      Bayo: "BAY",
      Biu: "BBU",
      Chibok: "CBK",
      Damboa: "DAM",
      Dikwa: "DKW",
      Gubio: "GUB",
      Guzamala: "GZM",
      Gwoza: "GZA",
      Hawul: "HWL",
      Jere: "JRE",
      Kaga: "KGG",
      Kala: "KBG",
      Balge: "KBG",
      Konduga: "KDG",
      Kukawa: "KWA",
      "Kwaya Kusar": "KWY",
      Mafa: "MAF",
      Magumeri: "MGM",
      Maiduguri: "MAG",
      Marte: "MAR",
      Mobbar: "MBR",
      Mongunu: "MNG",
      Ngala: "NGL",
      Nganzai: "NGZ",
      Shani: "SHN",
    },
  },

  "Cross River": {
    stateCode: "CR",
    lga: {
      Abi: "TGD",
      Akamkpa: "RAM",
      Akpabuyo: "KTA",
      Bakassi: "BKS",
      Bekwara: "ABE",
      "Bias!": "AKP",
      Boki: "BJE",
      "Calabar Municipal": "CAL",
      "Calabar South": "ANA",
      Etunk: "EFE",
      Ikom: "KMM",
      Obanliku: "BNS",
      Obubra: "BRA",
      Obudu: "UDU",
      Odukpani: "DUK",
      Ogoja: "GGJ",
      Yakurr: "GEP",
      Yala: "CKK",
    },
  },

  Delta: {
    stateCode: "DT",
    lga: {
      "Aniocha-North": "SLK",
      "Aniocha-South": "GWK",
      Bomadi: "BMA",
      Burutu: "BUR",
      "Ethiope East": "SKL",
      "Ethiope West": "GRA",
      "Ika North": "AGB",
      "Ika South": "AYB",
      "Isoko North": "DSZ",
      "Isoko South": "LEH",
      "Ndokwa East": "ABH",
      "Ndokwa West": "KWC",
      Okpe: "KPE",
      "Oshimili North": "AKU",
      Oshimili: "ASB",
      Patani: "PTN",
      Sapele: "SAP",
      Udu: "ALA",
      "Ughelli North": "UGH",
      "Ughelli South": "JRT",
      Ukwuani: "BKW",
      Uvwie: "EFR",
      "Warri Central": "GBJ",
      "Warri North": "KLK",
      "Warri South": "WWR",
    },
  },

  Ebonyi: {
    stateCode: "EB",
    lga: {
      Abakaliki: "AKL",
      "Afikpo North": "AFK",
      "Afikpo South": "EDA",
      Ebonyi: "UGB",
      "Ezza North": "EBJ",
      "Ezza South": "NKE",
      Ikwo: "CHR",
      Ishielu: "ZLL",
      Ivo: "SKA",
      Izzi: "BKL",
      Ohakwu: "HKW",
      Ohaozara: "BZR",
      Onicha: "NCA",
    },
  },

  Edo: {
    stateCode: "ED",
    lga: {
      "Akoko Edo": "GAR",
      Egor: "USL",
      "Esan Central": "RRU",
      "Esan North East": "URM",
      "Esan South East": "UBJ",
      "Esan west": "EKP",
      "Etsako Central": "FUG",
      "Etsako East": "AGD",
      "Etsako West": "AUC",
      Igueben: "GHE",
      "Ikpoba Okha": "DGE",
      Oredo: "BEN",
      Orhionmwon: "ABD",
      "Ovia-North-East": "AKA",
      "Ovia-South-West": "GBZ",
      "Owan-East": "AFZ",
      "Owan-West": "SGD",
      Uhunmwondeher: "ED",
    },
  },

  Ekiti: {
    stateCode: "EK",
    lga: {
      "Ado-Ekiti": "ADK",
      Efon: "EFY",
      "Ekiti East": "MUE",
      "Ekiti South West Law": "EK",
      "Ekiti West": "AMK",
      Emure: "EMR",
      "Ise Orun": "SSE",
      Gbonyin: "DEA",
      "Ido Osi": "DEK",
      Ijero: "JER",
      Ikere: "KER",
      Ikole: "RLE",
      Ilejemeje: "YEK",
      Irepodun: "GED",
      Ifelodun: "GED",
      Moba: "TUN",
      Oye: "YEE",
    },
  },

  Enugu: {
    stateCode: "EN",
    lga: {
      Aninri: "DBR",
      Awgu: "AWG",
      "Enugu East": "NKW",
      "Enugu North": "ENU",
      "Enugu South": "UWN",
      Ezeagu: "AGW",
      "Igbo Etti": "GBD",
      "Igbo Eze North": "ENZ",
      "Igbo Eze South": "BBG",
      "Isi Uzo": "KEM",
      "Nkanu East": "MGL",
      "Nkanu West": "AGN",
      Nsukka: "NSK",
      "Oji River": "JRV",
      Udenu: "BLF",
      Udi: "UDD",
      "Uzo-Uwani": "UMU",
    },
  },

  FCT: {
    stateCode: "FC",
    lga: {
      "Abaji Area Council": "ABJ",
      "Abuja Municipal": "",
      "Area Council": "ABC",
      Bwari: "BWR",
      Gwagwalada: "GWA",
      "Kuje Area Council": "KUJ",
      Kwali: "KWL",
    },
  },

  Gombe: {
    stateCode: "GB",
    lga: {
      Akko: "AKK",
      Balanga: "BLG",
      Billiri: "BLR",
      Dukku: "DKU",
      Funakaye: "FKY",
      Gombe: "GME",
      Kaltungo: "KLT",
      Kwami: "KWM",
      Nafada: "NFD",
      Bajoga: "NFD",
      Shomgom: "SHM",
      Yamaltu: "YDB",
      Deba: "YDB",
    },
  },

  Imo: {
    stateCode: "IM",
    lga: {
      "Aboh Mbaise": "ABB",
      "Ahiazu Mbaise": "AFR",
      "Ehime Mbano": "EHM",
      Ezinihitte: "ETU",
      "Ideato North": "URU",
      "Ideato South": "DFB",
      Ihitte: "EKE",
      Uboma: "EKE",
      Ikeduru: "KED",
      "Isiala-Mbano": "UML",
      Isu: "UMD",
      Mbaitoli: "NWA",
      "Ngor-Okpala": "NGN",
      Njaba: "UMK",
      Nkwerre: "NKR",
      Nwangele: "AMG",
      Obowo: "TTK",
      Oguta: "GUA",
      "Ohaji Egbema": "EBM",
      Okigwe: "KGE",
      Onuimo: "KWE",
      Orlu: "RLU",
      Orsu: "AWT",
      "Oru East": "MMA",
      "Oru West": "NGB",
      "Owerri Municipal": "WER",
      "Owerri North": "RRT",
      "Owerri West": "UMG",
    },
  },

  Jigawa: {
    stateCode: "JG",
    lga: {
      Auyo: "AUY",
      Babura: "BBR",
      Biriniwa: "BNW",
      "Birnin Kudu": "BKD",
      Buji: "BUJ",
      Dutse: "DUT",
      Gagarawa: "GGW",
      Garki: "GRK",
      Gumel: "GML",
      Guri: "GRR",
      Gwaram: "GRM",
      Gwiwa: "GWW",
      Hadejia: "HJA",
      Jahun: "JHN",
      "Kafin Hausa": "KHS",
      Kaugama: "KGM",
      Kazaure: "KZR",
      Kirkasamma: "KKM",
      Maigatari: "MGR",
      "Malam Maduri": "MMR",
      Miga: "MGA",
      Ringim: "RNG",
      Roni: "RNG",
      "Sule Tankarkar": "STK",
      Taura: "TAR",
      Yankwashi: "YKS",
      Kiyawa: "KYW",
    },
  },

  Kaduna: {
    stateCode: "KD",
    lga: {
      "Birnin Gwari": "BNG",
      Chikun: "KJM",
      Giwa: "GKW",
      Igabi: "TRK",
      Ikara: "KAR",
      Jaba: "KWB",
      Jemaa: "KAF",
      Kachia: "KCH",
      "Kaduna North": "DKA",
      Kagarko: "KGK",
      Kajuru: "KJR",
      Kauru: "KRA",
      "Kaduna-South": "MKA",
      Kubah: "ANC",
      Kudan: "HKY",
      Lere: "SNK",
      Makarfi: "MKR",
      "Sabon Gari": "SBG",
      Sanga: "GWT",
      Soba: "MGN",
      "Zangon-Kataf": "ZKW",
      Zaria: "ZAR",
    },
  },

  Kano: {
    stateCode: "KN",
    lga: {
      Ajingi: "AJG",
      Albasu: "ABS",
      Bagwa: "BGW",
      Bebeji: "BBJ",
      Bichi: "BCH",
      Bunkure: "BNK",
      Dala: "DAL",
      Danbatta: "DBT",
      "Dawakin Kudu": "DKD",
      "Dawakin Tofa": "DTF",
      Doguwa: "DGW",
      Fagge: "FGE",
      Gabasawa: "DSW",
      Garko: "GAK",
      "Garun Mallam": "GNM",
      Gaya: "GYA",
      Gezawa: "GZW",
      Gwale: "GWL",
      Gwarzo: "GRZ",
      "Kano Municipal": "KMC",
      Karaye: "KRY",
      Kibiya: "KBY",
      Kiru: "KKU",
      Kumbotso: "KBT",
      Kunchi: "KNC",
      Kura: "KUR",
      Madobi: "MDB",
      Makoda: "MKK",
      Minjibir: "MJB",
      Nassarawa: "NSR",
      Rano: "RAN",
      "Rimin Gado": "RMG",
      Rogo: "RGG",
      Shanono: "SNN",
      Sumaila: "SML",
      Takai: "TAK",
      Tarauni: "TRN",
      Tofa: "TEA",
      Tsanyawa: "TYW",
      "Tudun Wada": "TWD",
      Ungogo: "UGG",
      Warawa: "WRA",
      Wudil: "WDL",
      Kabo: "KBK",
    },
  },

  Katsina: {
    stateCode: "KT",
    lga: {
      Bakori: "BKR",
      Batagarawa: "BAT",
      Batsari: "BTR",
      Baure: "BRE",
      Bixdawa: "BDW",
      Charanchi: "CRC",
      "Dan Musa": "DMS",
      Dandume: "DDM",
      Danja: "DJA",
      Daura: "DRA",
      Dutsi: "DTS",
      Dutsinma: "DTM",
      Faskari: "FSK",
      Funtua: "FTA",
      Ingawa: "NGW",
      Jibia: "JBY",
      Kafur: "KFR",
      Kaita: "KAT",
      Kankara: "KKR",
      Kankia: "KNK",
      Katsina: "KTN",
      Kurfi: "KUF",
      Kusada: "KSD",
      "Mai Adua": "MDW",
      Malumfashi: "MNF",
      Maxi: "MAN",
      Mashi: "MSH",
      Matazu: "MTZ",
      Musawa: "MSW",
      Rimi: "RMY",
      Sabuwa: "SBA",
      Safana: "SFN",
      Sandamu: "SDM",
      Zango: "ZNG",
    },
  },

  Kebbi: {
    stateCode: "KB",
    lga: {
      Aleiro: "ALR",
      "Arewa Dandi": "KGW",
      Argungu: "ARG",
      Augie: "AUG",
      Bagudo: "BGD",
      "Birnin Kebbi": "BRK",
      Bunza: "BNZ",
      Dandi: "KMB",
      Fakai: "MHT",
      Gwandu: "GWN",
      Jega: "JEG",
      Kalgo: "KLG",
      "Koko Besse": "BES",
      Maiyama: "MYM",
      Ngaski: "WRR",
      Sakaba: "DRD",
      Shanga: "SNA",
      Suru: "DKG",
      Wasagu: "RBH",
      Yauri: "YLW",
      Zuru: "ZUR",
    },
  },

  Kogi: {
    stateCode: "KG",
    lga: {
      Adavi: "DAV",
      Ajaokuta: "AJA",
      Ankpa: "KPA",
      Bassa: "BAS",
      Dekina: "KNA",
      Ibaji: "NDG",
      Idah: "DAH",
      "Igalamela-Odolu": "AJK",
      Ijumu: "JMU",
      Kabba: "KAB",
      Bunu: "KAB",
      Lokopja: "LKJ",
      "Mopa-Muro-Mopi": "MPA",
      Ofu: "KFU",
      Ogori: "KPF",
      Magongo: "KPF",
      Okehi: "KKH",
      Okene: "KNE",
      Olamaboro: "LAM",
      Omala: "BJK",
      "Yagba-East": "ERE",
      "Yagba-West": "SAN",
    },
  },

  Kwara: {
    stateCode: "KW",
    lga: {
      Asa: "AFN",
      Baruten: "KSB",
      Edu: "LAF",
      Ekiti: "ARP",
      Ifelodun: "SHA",
      "Ilorin East": "KEY",
      "Ilorin South": "FUF",
      "Ilorin West": "LRN",
      Irepodun: "MUN",
      Isin: "WSN",
      Kaiama: "KMA",
      Moro: "BDU",
      Offa: "FFA",
      "Oke Ero": "LFF",
      Oyun: "LEM",
      Pategi: "PTG",
    },
  },

  Lagos: {
    stateCode: "LA",
    lga: {
      Agege: "GGE",
      "Ajeromi Ifelodun": "AGL",
      Alimosho: "KTU",
      "Amuwo Odofin": "FST",
      Apapa: "APP",
      Badagry: "BDG",
      Epe: "EPE",
      "Eti-Osa": "EKY",
      "Ibeju Lekki": "AKD",
      "Ifako Ijaiye": "FKJ",
      Ikeja: "KJA",
      Ikorodu: "KRD",
      Kosofe: "KSF",
      "Lagos Island": "AAA",
      "Lagos Mainland": "LND",
      Mushin: "MUS",
      Ojo: "JJJ",
      "Oshodi Isolo": "LSD",
      Somolu: "SMK",
      Surulere: "LSR",
    },
  },

  Nasarawa: {
    stateCode: "NS",
    lga: {
      Akwanga: "AKW",
      Awe: "AWE",
      Doma: "DMA",
      Karu: "KRV",
      Keana: "KEN",
      Keffi: "kEF",
      Kokona: "GRU",
      Lafia: "LFA",
      Nasarawa: "NSW",
      "Nasarawa Eggon": "NEG",
      Obi: "NBB",
      Wamba: "WAM",
      Toto: "NTT",
    },
  },

  Niger: {
    stateCode: "NG",
    lga: {
      Agaie: "AGA",
      Agwara: "AGR",
      Bida: "BDA",
      Borgu: "NBS",
      Bosso: "MAK",
      Chanchaga: "MNA",
      Edati: "ENG",
      Gbako: "LMU",
      Gurara: "GWU",
      Katcha: "KHA",
      Kontagora: "KNT",
      Lapai: "LAP",
      Lavun: "KUG",
      Magama: "NAS",
      Mariga: "BMG",
      Mashegu: "MSG",
      Mokwa: "MKW",
      Muya: "SRP",
      Paikoro: "PAK",
      Rafi: "KAG",
      Rijau: "RJA",
      "Si Iiroro": "KUT",
      Suleja: "SUL",
      Tafa: "WSE",
      Wushishi: "WSU",
    },
  },

  Ogun: {
    stateCode: "OG",
    lga: {
      "Abeokuta North": "AKM",
      "Abeokuta South": "AAB",
      Adoodo: "OTA",
      Ota: "OTA",
      Ewekoro: "TRE",
      Ifo: "FFF",
      "Egbado North": "AYE",
      "Egbado South": "LAR",
      "Ijebu East": "GBE",
      "Ijebu North": "JGB",
      "Ijebu North East": "JNE",
      "Ijebu Ode": "JBD",
      Ikenne: "JNN",
      "Imeko Afon": "MEK",
      Ipokia: "PKA",
      "Obafemi Owode": "WDE",
      Odedah: "DED",
      Odogbolu: "DGB",
      "Ogun Waterside": "ABG",
      "Remo North": "JRM",
      Shagamu: "SMG",
    },
  },

  Ondo: {
    stateCode: "OD",
    lga: {
      "Akoko North": "KAK",
      "Akoko North West": "ANG",
      "Akoko South": "SUA",
      "Akoko South East": "KAA",
      "Akure North": "AKR",
      "Akure South": "JTA",
      "Ese-Odo": "GKB",
      Idanre: "WEN",
      Ifedore: "FGB",
      Ijaye: "GBA",
      "Ile Oluji Okeigbo": "LEL",
      Irele: "REL",
      Odigbo: "REF",
      "Okiti Pupa": "KTP",
      "Ondo West": "NND",
      "Ondo East": "BDR",
      Ose: "FFN",
      Owo: "WWW",
    },
  },

  Osun: {
    stateCode: "OS",
    lga: {
      Atakumosa: "SSU",
      "Atakumosa East": "PRN",
      "Ayeda Ade": "GBN",
      Ayedire: "LGB",
      Boluwaduro: "TAN",
      Boripe: "RGB",
      Ede: "EDE",
      "Ede North": "EDT",
      Egbedore: "AAW",
      Ejigbo: "EJG",
      "Ife North": "PMD",
      "Ife South": "FTD",
      "Ife Central": "FFE",
      "Ife East": "FEE",
      Ifedayo: "FDY",
      Ifelodun: "KNR",
      Ila: "LRG",
      "Ilesa East": "LES",
      "Ilesa west": "LEW",
      Irepodun: "RLG",
      Irewole: "KRE",
      Isokan: "APM",
      Iwo: "WWD",
      Obokun: "BKN",
      "Odo otin": "DTN",
      "Ola oluwa": "BDS",
      Olorunda: "GNN",
      "Ori Ade": "JJS",
      Orolu: "FNN",
      Osogbo: "SGB",
    },
  },

  Oyo: {
    stateCode: "OY",
    lga: {
      Afijio: "JBL",
      Akinyele: "MNY",
      Atiba: "FMT",
      Egbeda: "EGB",
      "Ibadan North": "BDJ",
      "Ibadan North East": "AGG",
      "Ibadan North West": "MRK",
      "Ibadan South East": "MAP",
      "Ibadan South West": "LUY",
      "Ibarapa Central": "RUW",
      "Ibarapa East": "AYT",
      "Ibarapa North": "IRP",
      Ido: "DDA",
      Irepo: "KSH",
      Iseyin: "SEY",
      Itesiwaju: "TUT",
      Iwajowa: "WEL",
      Kajola: "KEH",
      Lagelu: "YNF",
      "Ogbomoso North": "KNH",
      "Ogbomoso South": "AME",
      "Ogo oluwa": "AJW",
      Olorunsogo: "GBY",
      Oluyole: "YRE",
      "Ona Ara": "AKN",
      Orelope: "GBH",
      "Ori Ire": "KKY",
      Oyo: "JND",
      "Oyo East": "YYY",
      "Saki East ": "GMD",
      "Saki West": "SHK",
      Suruler: "RSD",
    },
  },

  Plateau: {
    stateCode: "PL",
    lga: {
      "Barkin Ladi": "BLD",
      Bassa: "BSA",
      Bokkos: "BKK",
      "Jos East": "ANW",
      "Jos North": "JJN",
      "Jos South": "BUU",
      Kanam: "DNG",
      Kanke: "KWK",
      "Langtang Northlgt": "PL",
      "Langtang South": "MBD",
      Mangu: "MGU",
      Mikang: "TNK",
      Pankshin: "PKN",
      Quananpan: "QAP",
      Riyom: "RYM",
      Shendam: "SHD",
      Wase: "WAS",
    },
  },

  Rivers: {
    stateCode: "RV",
    lga: {
      Aboa: "ABU",
      Odual: "ABU",
      "Ahoada East": "AHD",
      "Ahoada West": "KNM",
      Akukutoru: "ABM",
      Andoni: "NDN",
      "Asari Toru": "BGM",
      Bonny: "BNY",
      Degema: "DEG",
      Eleme: "NCH",
      Emuoha: "MHA",
      Etche: "KHE",
      Gokana: "KPR",
      Kwerre: "SKP",
      Khana: "BRR",
      Obia: "RUM",
      Akpor: "RUM",
      "Ogba Egbema Ndoni": "RGM",
      Ogu: "GGU",
      Bolo: "GGU",
      Okirika: "KRK",
      Omuma: "BER",
      Opobo: "PBT",
      Nkoro: "PBT",
      Oyigbo: "AFM",
      "Port Harcourt": "PHC",
      Tai: "SKN",
    },
  },

  Sokoto: {
    stateCode: "SO",
    lga: {
      Binji: "BJN",
      Bodinga: "DBN",
      "Dange Shuni": "DGS",
      Gada: "GAD",
      Goronyo: "GRY",
      Gudu: "BLE",
      Gwadabawa: "GWA",
      Illela: "LLA",
      Isa: "SAA",
      Kebbe: "KBE",
      Kware: "KWR",
      Rabah: "RBA",
      "Sabon Birni": "SBN",
      Shagari: "SGR",
      Silame: "SLM",
      "Sokoto North": "SKK",
      "Sokoto South": "SRZ",
      Tambawal: "TBW",
      Tangaza: "TGZ",
      Tureta: "TRT",
      Wamakko: "WMK",
      Wurno: "WRN",
      Yabo: "YYB",
    },
  },

  Taraba: {
    stateCode: "TB",
    lga: {
      "Ardo Kola": "ARD",
      Bali: "BAL",
      Donga: "DGA",
      Gashaka: "GKA",
      Gassol: "GAS",
      Ibi: "BBB",
      Jalingo: "JAL",
      "Karim Lamido": "KLD",
      Kurmi: "KRM",
      Lau: "LAU",
      Sardauna: "SDA",
      Takum: "TTM",
      Ussa: "USS",
      Wukari: "WKR",
      Yorro: "YRR",
      Zing: "TZG",
    },
  },

  Yobe: {
    stateCode: "YB",
    lga: {
      Bade: "GSH",
      Bosari: "DPH",
      Damaturu: "DTR",
      Fika: "FKA",
      Fune: "FUN",
      Geidam: "GDM",
      Gujba: "GJB",
      Gulani: "GLN",
      Jakusko: "JAK",
      Karasuwa: "KRS",
      Machina: "MCN",
      Nangere: "NNR",
      Nguru: "NGU",
      Potiskum: "PKM",
      Tarmua: "TMW",
      Yunusari: "YUN",
      Yusufari: "YSF",
    },
  },

  Zamfara: {
    stateCode: "ZA",
    lga: {
      Anka: "ANK",
      Bakura: "BKA",
      Bukkuyum: "BKM",
      Bungudu: "BUG",
      Gumi: "GMM",
      Gusau: "GUS",
      "Kaura Namoda": "KRN",
      "Birnin Magaji": "BMJ",
      Maradun: "MRD",
      Maru: "MRR",
      Shinkafi: "SKF",
      "Talata Mafara": "TMA",
      Tsafe: "TSF",
      Zurmi: "ZRM",
    },
  },
};

export const nigerianStates = () => {
  const lst = {};
  for (const key in stateAndLga) {
    lst.push(<MenuItem value={key}>{key}</MenuItem>);
  }
  return lst;
};
export const nigerianLgasCodes = (selectedState, lga) => {
  const res = stateAndLga[selectedState];
  const stateCode = res.stateCode;
  const lgaCode = res.lga[lga];
  return { stateCode, lgaCode };
};
