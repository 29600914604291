import React, { useState } from "react";
import { Field, Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import { TextField, Select } from "formik-mui";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Stack from "@mui/material/Stack";
import Header from "../components/Header";
import ConsentModal, { useConsentModal } from "../components/ConsentModal";
import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";
import { API_URL } from "../utils/api";
import { useStateValue } from "../utils/app-state-context";
import { useFeedBack, FeedBack } from "../components/FeedBack";
import LinearProgress from "@mui/material/LinearProgress";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import AddAlertSharpIcon from "@mui/icons-material/AddAlertSharp";
import { useNavigate } from "react-router-dom";
import BlobankSubHeader from "../components/BlobankSubHeader";
import { useLocation } from "react-router-dom";

export default function DonorsHealthResult() {
  const navigate = useNavigate();
  let location = useLocation();
  const [{ userProfile, authDonor, facilityId, donorId }, _] = useStateValue();
  const { openSnack, setOpenSnack, msg, setMsg, handleCloseSnackbar } =
    useFeedBack();
  const returnDate = (month) => {
    const date = new Date();
    const objectDate = new Date(date.setMonth(date.getMonth() + Number(month)));
    return `${objectDate.getDate()}/${
      objectDate.getMonth() + 1
    }/${objectDate.getFullYear()}`;
  };

  // useAuthentication(authDonor, `/sign-in`);
  return (
    <>
      <Header />
      <BlobankSubHeader />

      <Box
        sx={{
          maxWidth: 500,
          p: 1,
          m: 1,
          margin: "auto",
          borderRadius: 5,
        }}
      >
        <Formik
          enableReinitialize={true}
          initialValues={{
            donorGrpClinic: "",
            clinicStaffDate: "",
            donorAccepted: "",
            collectionStartTime: "",
            collectionEndTime: "",
            volumeDonated: "",
            bloodGrp: "",
            HaemoglobinLev: "",
            PhlebotomistName: "",
            assisttantName: "",
            typeOfPack: "",
            signature1: "",
            signature2: "",
            remarksDonorStatus: "",
            others: "",
            donatnNo: "",
            weight: "",
            pulse: "",
            bp: "",
            temperature: "",
            bloodTypes: "",
            createdBy: "",
            userId: "",
          }}
          validationSchema={Yup.object({
            weight: Yup.string().required("*This field is required"),
            donorAccepted: Yup.string().required("*This field is required"),
            pulse: Yup.string().required("*This field is required"),
            bp: Yup.string().required("*This field is required"),
            temperature: Yup.string().required("*This field is required"),
            bloodTypes: Yup.string().required("*This field is required"),
          })}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            try {
              const response = await fetch(`${API_URL}/donorHealthStatus`, {
                method: "PUT",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  ...values,
                  createdBy: facilityId,
                  userId: donorId,
                  dhsId: location.state.dhsId,
                }),
              });
              if (!response.ok) {
                throw new Error(response.status);
              }
              const res = await response.json();
              if (res) {
                setMsg(`Donation Health Status Successfully Submitted. `);
                setOpenSnack(true);
              }
              setSubmitting(false);
              resetForm();
              navigate("/donation-health-result-submitted");
            } catch (e) {
              alert("An Error Occured");
              console.error("Error adding document: ", e);
              setSubmitting(false);
            }
          }}
        >
          {({ submitForm, isSubmitting, values }) => (
            <Form>
              <Box
                sx={{
                  borderRadius: 5,
                  //   background: "rgba(229,255,239)",
                  margin: "auto",
                  // background:
                  //   "linear-gradient(135deg, rgba(0,127,128,0.47942927170868344) 7%, rgba(118,148,216,0.4962359943977591) 35%, rgba(162,231,227,0.4990371148459384) 60%, rgba(229,255,239,1) 93%)",
                }}
              >
                {isSubmitting && <LinearProgress sx={{ my: 2 }} />}
                <Stack spacing={2} alignItems="center" justifyContent="center">
                  <Typography component="div" noWrap>
                    <h3>Lab Result for {donorId}</h3>
                  </Typography>

                  <Box
                    sx={{
                      borderRadius: 5,
                      width: "100%",
                      // background: "rgba(229,255,239)",
                      margin: "auto",
                      // background:
                      //   "linear-gradient(135deg, rgba(0,127,128,0.47942927170868344) 7%, rgba(118,148,216,0.4962359943977591) 35%, rgba(162,231,227,0.4990371148459384) 60%, rgba(229,255,239,1) 93%)",
                    }}
                  >
                    <Typography
                      component="div"
                      noWrap
                      style={{ marginTop: "20", marginBottom: "20" }}
                    >
                      Test Result:
                    </Typography>
                    <FormControl fullWidth>
                      <Field
                        sx={{ mb: 3 }}
                        component={TextField}
                        style={{ marginBottom: 10 }}
                        fullWidth
                        name="donorGrpClinic"
                        type="text"
                        size="small"
                        label="Donor Group/Clinic"
                        variant="outlined"
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <Field
                        sx={{ mb: 3 }}
                        component={TextField}
                        name="clinicStaffDate"
                        type="date"
                        size="small"
                        label="Date"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <Field
                        sx={{ mb: 3 }}
                        component={Select}
                        name="donorAccepted"
                        size="small"
                        label="Donor is Accepted?"
                        variant="outlined"
                      >
                        <MenuItem value={"Yes"}>Yes</MenuItem>
                        <MenuItem value={"No"}>No</MenuItem>
                      </Field>
                    </FormControl>

                    <FormControl fullWidth>
                      <Field
                        component={TextField}
                        style={{ marginBottom: 10 }}
                        fullWidth
                        name="collectionStartTime"
                        type="text"
                        size="small"
                        label="Collection Start Time"
                        variant="outlined"
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <Field
                        component={TextField}
                        style={{ marginBottom: 10 }}
                        fullWidth
                        name="collectionEndTime"
                        type="text"
                        size="small"
                        label="Collection End Time"
                        variant="outlined"
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <Field
                        component={TextField}
                        style={{ marginBottom: 10 }}
                        fullWidth
                        name="donatnNo"
                        type="text"
                        size="small"
                        label="Number of Donations"
                        variant="outlined"
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <Field
                        component={TextField}
                        style={{ marginBottom: 10 }}
                        fullWidth
                        name="volumeDonated"
                        type="text"
                        size="small"
                        label="Volume Donated"
                        variant="outlined"
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <Field
                        sx={{ mb: 3 }}
                        component={Select}
                        name="bloodGrp"
                        size="small"
                        label="Blood Group"
                        variant="outlined"
                      >
                        <MenuItem value={"O"}>O</MenuItem>
                        <MenuItem value={"A"}>A</MenuItem>
                        <MenuItem value={"B"}>B</MenuItem>
                        <MenuItem value={"AB"}>AB</MenuItem>
                      </Field>
                    </FormControl>
                    <FormControl fullWidth>
                      <Field
                        sx={{ mb: 3 }}
                        component={Select}
                        name="bloodTypes"
                        size="small"
                        label="RH Types"
                        variant="outlined"
                      >
                        <MenuItem value={"O-"}>O-</MenuItem>
                        <MenuItem value={"O+"}>O+</MenuItem>
                        <MenuItem value={"A-"}>A-</MenuItem>
                        <MenuItem value={"A+"}>A+</MenuItem>
                        <MenuItem value={"B-"}>B-</MenuItem>
                        <MenuItem value={"B+"}>B+</MenuItem>
                        <MenuItem value={"AB-"}>AB-</MenuItem>
                        <MenuItem value={"AB+"}>AB+</MenuItem>
                      </Field>
                    </FormControl>
                    <FormControl fullWidth>
                      <Field
                        component={TextField}
                        style={{ marginBottom: 10 }}
                        fullWidth
                        name="HaemoglobinLev"
                        type="text"
                        size="small"
                        label="Haemoglobin Level"
                        variant="outlined"
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <Field
                        component={TextField}
                        style={{ marginBottom: 10 }}
                        fullWidth
                        name="PhlebotomistName"
                        type="text"
                        size="small"
                        label="Phlebotomist Name"
                        variant="outlined"
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <Field
                        component={TextField}
                        style={{ marginBottom: 10 }}
                        fullWidth
                        name="signature1"
                        type="text"
                        size="small"
                        label="Phlebotomist Phone Number"
                        variant="outlined"
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <Field
                        component={TextField}
                        style={{ marginBottom: 10 }}
                        fullWidth
                        name="assisttantName"
                        type="text"
                        size="small"
                        label="Assisttant Phlebotomist Name"
                        variant="outlined"
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <Field
                        component={TextField}
                        style={{ marginBottom: 10 }}
                        fullWidth
                        name="signature2"
                        type="text"
                        size="small"
                        label="Assistant Phlebotomist Phone Number"
                        variant="outlined"
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <Field
                        component={TextField}
                        style={{ marginBottom: 10 }}
                        fullWidth
                        name="typeOfPack"
                        type="text"
                        size="small"
                        label="Type of Pack"
                        variant="outlined"
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <Field
                        component={TextField}
                        style={{ marginBottom: 10 }}
                        fullWidth
                        name="weight"
                        type="text"
                        size="small"
                        label="Weight"
                        variant="outlined"
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <Field
                        component={TextField}
                        style={{ marginBottom: 10 }}
                        fullWidth
                        name="pulse"
                        type="text"
                        size="small"
                        label="Pulse"
                        variant="outlined"
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <Field
                        component={TextField}
                        style={{ marginBottom: 10 }}
                        fullWidth
                        name="bp"
                        type="text"
                        size="small"
                        label="BP"
                        variant="outlined"
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <Field
                        component={TextField}
                        style={{ marginBottom: 10 }}
                        fullWidth
                        name="temperature"
                        type="text"
                        size="small"
                        label="Temperature"
                        variant="outlined"
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <Field
                        component={TextField}
                        style={{ marginBottom: 10 }}
                        fullWidth
                        name="remarksDonorStatus"
                        type="text"
                        size="small"
                        label="Remarks Donor Status"
                        variant="outlined"
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <Field
                        component={TextField}
                        style={{ marginBottom: 10 }}
                        fullWidth
                        name="others"
                        type="text"
                        size="small"
                        label="Others"
                        variant="outlined"
                      />
                    </FormControl>
                  </Box>
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                  style={{ margin: 10 }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={submitForm}
                    style={{
                      margin: 20,
                      background: "#0C2340",
                      paddingLeft: "40px",
                      paddingRight: "40px",
                      textTransform: "none",
                    }}
                  >
                    {isSubmitting ? (
                      <span style={{ color: "yellow" }}>Submitting... </span>
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </Stack>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
}
