import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../utils/app-state-context";
import { API_URL } from "../utils/api";
import DonorDetailViewModal from "./DonorDetailViewModal";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function AdminRetrieveFacilityBloodBanks({ facilityId }) {
  const [{ authAdmin }, _] = useStateValue();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [bloodBanks, setBloodBanks] = React.useState(null);
  const retrieveProfile = () => {
    fetch(`${API_URL}/bloodBank/${facilityId}`)
      .then((response) => response.json())
      .then((data) => setBloodBanks(data))
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  React.useEffect(() => {
    retrieveProfile();
  }, []);
  if (!authAdmin) {
    return navigate("/admin");
  }
  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Blood Bank
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Blood Bank
        </BootstrapDialogTitle>
        <DialogContent dividers>
          {bloodBanks !== null ? (
            bloodBanks.map((item) => (
              <div
                style={{
                  margin: 10,
                  padding: 10,
                  backgroundColor: "lightgrey",
                }}
              >
                <p>
                  <DonorDetailViewModal userId={item.donorId} />
                </p>
                <p>
                  Bag Number:{" "}
                  <span style={{ color: "purple", fontWeight: "bold" }}>
                    {item.bagNumber}
                  </span>
                </p>
                <p>
                  Blood Type?:{" "}
                  <span style={{ color: "purple", fontWeight: "bold" }}>
                    {item.boodType}
                  </span>
                </p>
                <p>
                  Date Collected:{" "}
                  <span style={{ color: "purple", fontWeight: "bold" }}>
                    {item.dateCollected}
                  </span>
                </p>
                <p>
                  Date Tested:{" "}
                  <span style={{ color: "purple", fontWeight: "bold" }}>
                    {item.dateTested}
                  </span>
                </p>
                <p>
                  Date Released:{" "}
                  <span style={{ color: "purple", fontWeight: "bold" }}>
                    {item.dateReleased}
                  </span>
                </p>
                <p>
                  Date Discarded:{" "}
                  <span style={{ color: "purple", fontWeight: "bold" }}>
                    {item.dateDiscarded}
                  </span>
                </p>
                <p>
                  Comment / Remarkd:{" "}
                  <span style={{ color: "purple", fontWeight: "bold" }}>
                    {item.comment}
                  </span>
                </p>
              </div>
            ))
          ) : (
            <div
              style={{
                padding: 2,
                minWidth: 300,
                marginLeft: 20,
                margin: "auto",
              }}
            >
              No Blood Bank Record.
            </div>
          )}
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
