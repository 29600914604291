import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";

export function useConsentModal() {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleDecline = () => {
    setOpen(false);
    return navigate("/decline");
  };
  return { open, handleClose, handleOpen, handleDecline };
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 200,
  bgcolor: "background.paper",
  border: "1px solid #ff7388",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
};

export default function ConsentModal({
  open,
  handleClose,
  handleOpen,
  handleDecline,
}) {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Consent
          </Typography>
          <hr />
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, textAlign: "justify" }}
          >
            The Nigerian Institute of Medical Research (NIMR) conducts important
            scientific research. This research enables us to understand donor
            need and improve transfusion services. We may have taken sample(s)
            from you for the purpose of blood donation. It is possible that the
            leftover of those samples (possibly together with some medical data)
            may be used at a later point in time for scientific research. Only
            authorized researchers have access to your data and samples. Each of
            them is bound by professional confidentiality and has also signed a
            confidentiality agreement. It is also possible that your medical
            data is used for imminent scientific research. In this case, your
            data will always be coded (your name will be replaced by a code,
            which makes you not directly identifiable) and whenever possible,
            your data will be completely anonymized. The link with your identity
            will be strictly separated. Your name or identifiable information
            will also never show up in any publications arising from such
            research. Prior to the start of any research, be it with left-over
            samples or only coded medical data, we must obtain approval from the
            NIMR Institutional Review Board (IRB). You have the right to refuse
            the later use of your samples and medical data in case you do not
            want this. In case you refuse the later use of your samples and
            medical data for scientific research, this will not influence the
            use of yoiur blood for transufion now or in the future. Possibly,
            researchers may discover something during such later research that
            is potentially hazardous to your health or the health of others. In
            this case, you will be informed about this out of moral and legal
            obligation. If you still have questions about the later use of your
            body samples or data, feel free to ask the Principal Investigator or
            the NIMR IRB secretary: The Chairman, NIMR Health Research and
            Ethical Committee, Nigerian Institute of Medical Research Yaba,
            Lagos. Phone-08023513399 Amoo Olufemi Samuel, Principal Investigator
            (08033367441) Statement of person giving consent: I have read and I
            now fully understand the purpose, risk and benefit of participating
            in future research. I hereby freely give my consent for my left-over
            sample or data to be used for future research.
          </Typography>
          <Stack
            spacing={2}
            direction={{ xs: "column", sm: "column", md: "row" }}
            justifyItems="space-between"
            mt={3}
          >
            <Button variant="outlined" onClick={handleDecline}>
              <img src="/close-icon.png" style={{ width: 40, height: 40 }} />
              <Typography
                id="modal-modal-title"
                variant="span"
                component="span"
                sx={{ ml: 2 }}
              >
                I Decline
              </Typography>
            </Button>
            <Button variant="contained" onClick={handleClose}>
              <img src="/thumb-up.png" style={{ width: 40, height: 40 }} />
              <Typography
                id="modal-modal-title"
                variant="span"
                component="span"
                sx={{ ml: 2 }}
              >
                I Consent
              </Typography>
            </Button>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}
