import React from "react";
import Header from "../components/Header";
import { useStateValue } from "../utils/app-state-context";
import { API_URL } from "../utils/api";
import FacilityDetailViewModal from "./FacilityDetailViewModal";
import DonorResultMedicalFacility from "./DonorResultMedicalFacility";
import { Field, Formik, Form } from "formik";
import * as Yup from "yup";
import FormControl from "@mui/material/FormControl";
import { Select } from "formik-mui";
import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import useRetrieve from "../utils/useRetrieve";
import { nigerianLgas, nigerianStates } from "../utils/stateAndLga";
import Chip from "@mui/material/Chip";
import Person2Icon from "@mui/icons-material/Person2";

export function FacilityInfo({id}){
  const facility = useRetrieve(`${API_URL}/facilityProfile/${id}`);
  return<Box sx={{color:"purple", fontWeight:"bold"}}>
    <p>Name: {facility && facility.facilityName}</p>
    <p>Type: {facility && facility.facilityType}</p>
    <p>Telephone: {facility && facility.telephone}</p>
    <p>Address Street: {facility && facility.addressStreet}</p>
    <p>Address Town: {facility && facility.addressTown}</p>
    <p>Address State: {facility && facility.addressState}</p>
    <p>LGA: {facility && facility.lga}</p>
    <p>Contact Person: {facility && facility.contactPerson}</p>
    <p>Email: {facility && facility.email}</p>
    {/* {id, facilityId, facilityName, privatePublic, facilityType, stateFederal, registered, registrationBody, registeredWithNBSC, bloodBankAvailable, telephone, email, addressStreet, addressTown, addressState, lga, contactPerson, verified}) */}
  </Box>
}
export default function MedicalFacilityBloodBankView() {
  const navigate = useNavigate();
  const [{ authFacility, facilityProfileState }, _] = useStateValue();
  const facilityProfile = useRetrieve(`${API_URL}/facilityProfile`);
  const bloodBanks = useRetrieve(`${API_URL}/bloodBank`);
  const [filter, setFilter] = React.useState("Current State");

  //   SELECT `id`, `facilityId`, `donorId`,
  // `bagNumber`, `boodType`, `dateCollected`,
  // `dateTested`, `dateReleased`,
  // `dateDiscarded`, `comment`
  // FROM `bloodbank` WHERE 1

  // SELECT `id`, `facilityId`, `facilityName`,
  // `privatePublic`, `registered`,
  // `registrationBody`, `bloodBankAvailable`,
  // `telephone`, `email`, `addressStreet`,
  // `addressTown`, `addressState`, `lga`,
  // `contactPerson`
  // FROM `facilityprofile` WHERE 1

  // SELECT `donorId`, `userId`, `createdBy`,
  // `fname`, `mname`, `gender`, `surname`,
  // `telephone`, `email`, `age`,
  // `occupation` FROM `donorprofile` WHERE 1
  const [rBloodTyp, setRBloodTyp] = React.useState();
  const [rState, setRState] = React.useState();
  const [rLga, setRLga] = React.useState();
  const [lstSearch, setLstSearch] = React.useState([]);
 
  const handleStateChange = (e) => {
    setRState(setRState);
    setLstSearch(bloodBanks);
  };
  const handleAllStates = () => {
    setFilter("All States");
  };
  const handleCurrentState = () => {
    setFilter("Current State");
  };
  const handleSearch = () => {
    const stateFacilities = facilityProfile && facilityProfile.filter(
      (item) =>
        item.addressState ===
        rState ||
        item.lga === rLga
    );
    const lstFacilityId = stateFacilities && stateFacilities.map((item) => item.facilityId);
    const lst = [];
    if (!lstFacilityId) return;
    for (const id of lstFacilityId) {
      lst.push(
        bloodBanks.filter(
          (item) => item.facilityId === id && item.boodType === rBloodTyp
        )
      );
    }
  
    // console.log("stateFacilities: ", stateFacilities);
    // console.log("lstFacilityId: ", lstFacilityId);
    // console.log("lst.flat(): ", lst.flat());
    // setLstSearch(bloodBanks);
    setLstSearch(lst.flat());
  };
 
  React.useEffect(()=>{
    handleSearch()
  },[ rBloodTyp,rState,rLga])
  if (!authFacility) {
    return navigate("/admin");
  }
  return (
    <>
      <Stack
        spacing={2}
        direction={{ xs: "column", sm: "column", md: "row" }}
        alignItems="center"
        sx={{ mb: 2 }}
      >
        <div>Search Filters:</div>
        <Stack direction="row" spacing={2}>
          <Chip
            icon={<Person2Icon />}
            label={
              facilityProfileState &&
              facilityProfileState.addressState + " State"
            }
            variant="standard"
            onClick={() => handleCurrentState()}
          />
          <Chip
            icon={<Person2Icon />}
            label="All States"
            variant="standard"
            onClick={() => handleAllStates()}
          />
        </Stack>
      </Stack>
      <Formik
        enableReinitialize={true}
        initialValues={{
          bloodType: "",
          lga: "",
        }}
        validationSchema={Yup.object({
          // addressState: Yup.string().required("*Required"),
          bloodType: Yup.string().required("*Required"),
          // lga: Yup.string().required("*Required"),
        })}
      >
        {() => (
          <Form>
            <Box
              sx={{
                borderRadius: 5,
              }}
            >
              <Typography component="div" noWrap style={{ marginBottom: 30 }}>
                {filter && "Search Blood Bank Based On "}
                {filter === "Current State" &&
                  facilityProfileState &&
                  facilityProfileState.addressState + " State"}
                {filter === "All States" && "All States"}
              </Typography>
              <Stack
                spacing={2}
                direction={{ xs: "column", sm: "column", md: "row" }}
                alignItems="center"
                alignContent="center"
                sx={{maxWidth:400}}
              >
                <FormControl fullWidth>
                  <Field
                    component={Select}
                    // formHelperText={{ children: "Please Select a Blood Type" }}
                    name="bloodType"
                    size="small"
                    label="Select Blood Type"
                    validate={(bloodType) =>
                      !bloodType ? "You must select a blood type" : ""
                    }
                    onChange={(e) => setRBloodTyp(e.target.value)}
                  >
                    <MenuItem value={"A"}>A</MenuItem>
                    <MenuItem value={"B"}>B</MenuItem>
                    <MenuItem value={"O"}>O</MenuItem>
                    <MenuItem value={"AB"}>AB</MenuItem>
                  </Field>
                </FormControl>
                {filter === "All States" && (
                  <FormControl fullWidth>
                    <Field
                      component={Select}
                      name="stateOfOrigin"
                      size="small"
                      label="Select a Nigerian State"
                      variant="outlined"
                      onChange={(e) => handleStateChange(e.target.value)}
                    >
                      {nigerianStates()}
                    </Field>
                  </FormControl>
                )}
                <FormControl fullWidth>
                  <Field
                    component={Select}
                    // formHelperText={{
                    //   children: "Enter a valid local goverment area",
                    // }}
                    fullWidth
                    name="lga"
                    size="small"
                    label="Select Local Government Area"
                    variant="outlined"
                    onChange={(e) => {
                      setRLga(e.target.value);

                    }
                    }
                  >
                    {facilityProfileState.addressState &&
                      nigerianLgas(facilityProfileState.addressState)}
                  </Field>
                </FormControl>
              </Stack>
            </Box>
          </Form>
        )}
      </Formik>
      {filter === "Current State" && (
        <Box sx={{ mt: 2 }}>
          Search results for{" "}
          {facilityProfileState && facilityProfileState.addressState} state:
        </Box>
      )}
      {filter === "All States" && (
        <Box sx={{ mt: 2 }}>
          Search results for All States
        </Box>
      )}
      
      {lstSearch.length > 0 ? (
        lstSearch.map((item) => (
          <div
            style={{
              margin: 10,
              padding: 10,
              borderBottom: "solid 1px grey",
            }}
          >
            {/* <DonorResultMedicalFacility userId={item.donorId} /> */}
            {/* <p>
                 Donor ID:{" "}
                <span style={{ color: "purple", fontWeight: "bold" }}>
                  {item.donorId}
                </span>
              </p> */}
            <p>
              Blood Type:{" "}
              <span style={{ color: "purple", fontWeight: "bold" }}>
                {item.boodType}
              </span>
            </p>
            
            <p>
              Bag Number:{" "}
              <span style={{ color: "purple", fontWeight: "bold" }}>
                {item.bagNumber}
              </span>
            </p>
           
            <p>
              Date Collected:{" "}
              <span style={{ color: "purple", fontWeight: "bold" }}>
                {item.dateCollected}
              </span>
            </p>
            <p>
              Date Tested:{" "}
              <span style={{ color: "purple", fontWeight: "bold" }}>
                {item.dateTested}
              </span>
            </p>
            <p>
              Comment / Remark:{" "}
              <span style={{ color: "purple", fontWeight: "bold" }}>
                {item.comment}
              </span>
            </p>
            <p>
             Medical Facility Details:{" "}
                <FacilityInfo id={item.facilityId}/>
            </p>
          </div>
        ))
      ) : (
        <div
          style={{
            padding: 2,
            marginLeft: 20,
            marginTop: 40,
            color: "maroon",
          }}
        >
          No search match for this state
        </div>
      )}
    </>
  );
}
