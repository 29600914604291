import React from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../utils/app-state-context";
import { API_URL } from "../utils/api";

export default function BlobankSubHeader() {
  const [{ donorId, authAdmin }, _] = useStateValue();
  const [donationHis, setDonationHis]= useStateValue();
  const navigate = useNavigate();
  React.useEffect(()=>{
    const getUnprocessedQus = async ()=>{  
      const response = await fetch(`${API_URL}/donorHealthStatus/${donorId}`, {
          method: "POST",
          headers: {
          "Content-Type": "application/json",
          },
          body: JSON.stringify({
              donorAccepted: ""
          }),
      });
      if (!response.ok) {
          throw new Error(response.status);
      }
      const res = await response.json();
      setDonationHis(res);
  }
  getUnprocessedQus()
  })
 
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-around",
        marginTop: 20,
        maxWidth: 500,
        margin: "auto",
        background: "grey",
      }}
    >
      <Button
        sx={{ textTransform: "none", color: "white" }}
        onClick={() => navigate("/blood-bank")}
      >
        Store Blood
      </Button>
      {/* <Button disabled={donationHis.length ===0}
        sx={{ textTransform: "none", color: "white" }}
        onClick={() => navigate("/donors-health-result")}
      >
        Enter Lab Result
      </Button> */}
      <Button
        sx={{ textTransform: "none", color: "white" }}
        onClick={() => navigate("/admin-retrieve-donation-history")}
      >
        Donation History
      </Button>
    </div>
  );
}
