import React from "react";
import { useStateValue } from "../utils/app-state-context";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import { API_URL } from "../utils/api";
import ShowHide from "../components/show-hide";
import Button from "@mui/material/Button";

export default function BloodBankRetrieveQuestionnaire() {
  const navigate = useNavigate();
  const [{ donorId, authAdmin }, _] = useStateValue();
  const [donationHis, setDonationHis] = React.useState(null);
  const [donorProfile, setDonorProfile] = React.useState(null);
  const getUnprocessedQus = async () => {
    const response = await fetch(`${API_URL}/donorHealthStatus/${donorId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        donorAccepted: "",
      }),
    });
    if (!response.ok) {
      throw new Error(response.status);
    }
    const res = await response.json();
    setDonationHis(res);
  };
  const retrieveDonorProfile = (donorId) => {
    fetch(`${API_URL}/dornorProfile/${donorId}`)
      .then((response) => response.json())
      .then((data) => setDonorProfile(data))
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  React.useEffect(() => {
    getUnprocessedQus();
    retrieveDonorProfile(donorId);
  }, []);
  // if (!authAdmin) {
  //   return navigate("/admin");
  // }
  return (
    <>
      <Header />
      <h3
        style={{
          margin: 10,
          padding: 10,
        }}
      >
        Donation Questionnaire for{" "}
        <span
          style={{
            fontFamily: "Mulish",
            color: "purple",
          }}
        >
          {donorProfile && `${donorProfile.fname}  ${donorProfile.surname}`}
        </span>
      </h3>

      <div>
        {donationHis && donationHis.donorAccepted === "" ? (
          <div
            style={{
              margin: 10,
              padding: 10,
              borderBottom: "solid 4px lightgrey",
            }}
          >
            <Button
              variant="contained"
              onClick={() =>
                navigate("/donors-health-result", {
                  state: {
                    userId: donationHis.userId,
                    dhsId: donationHis.dhsId,
                  },
                })
              }
            >
              Enter Lab Result
            </Button>
            <p style={{ maxWidth: 500 }}> User ID: {donationHis.userId}</p>

            <p style={{ maxWidth: 500 }}>
              {" "}
              Donation Date: {donationHis.donationDate}
            </p>
            <p style={{ maxWidth: 500 }}>
              {" "}
              Do You Feel Well Today: {donationHis.feelWellToday}
            </p>
            <p style={{ maxWidth: 500 }}>
              {" "}
              Discomfort After Donation : {donationHis.donationDiscomfort}
            </p>
            <p style={{ maxWidth: 500 }}> Tattooing: {donationHis.Tattooing}</p>
            <p style={{ maxWidth: 500 }}> Piercing: {donationHis.Piercing}</p>
            <p style={{ maxWidth: 500 }}>
              {" "}
              Dental Extraction: {donationHis.DentalExtraction}
            </p>
            <p style={{ maxWidth: 500 }}>
              {" "}
              HeartDisease: {donationHis.HeartDisease}
            </p>
            <p style={{ maxWidth: 500 }}>
              {" "}
              Cancer Malignant Disease: {donationHis.CancerMalignantDisease}
            </p>
            <p style={{ maxWidth: 500 }}> Diabetes: {donationHis.Diabetes}</p>
            <p style={{ maxWidth: 500 }}>
              {" "}
              SickleCell: {donationHis.SickleCell}
            </p>
            <p style={{ maxWidth: 500 }}>
              {" "}
              Hepatitis BC: {donationHis.HepatitisBC}
            </p>
            <p style={{ maxWidth: 500 }}> STD: {donationHis.STD}</p>
            <p style={{ maxWidth: 500 }}>
              {" "}
              Typhoid in the last 1 year: {donationHis.TyphoidLast1Yr}
            </p>
            <p style={{ maxWidth: 500 }}>
              {" "}
              Lung Disease: {donationHis.LungDisease}
            </p>
            <p style={{ maxWidth: 500 }}>
              {" "}
              Tuberculosis: {donationHis.Tuberculosis}
            </p>
            <p style={{ maxWidth: 500 }}>
              {" "}
              Allergic Disease: {donationHis.AllergicDisease}
            </p>
            <p style={{ maxWidth: 500 }}>
              {" "}
              Kidney Disease: {donationHis.KidneyDisease}
            </p>
            <p style={{ maxWidth: 500 }}> Epilepsy: {donationHis.Epilepsy}</p>
            <p style={{ maxWidth: 500 }}>
              {" "}
              Abnormal Bleeding Tendency:{" "}
              {donationHis.AbnormalBleedingTtendency}
            </p>
            <p style={{ maxWidth: 500 }}>
              {" "}
              Jaundice in the last 1 year: {donationHis.JaundiceLast1Yr}
            </p>
            <p style={{ maxWidth: 500 }}>
              {" "}
              Malaria in the last 6 Months: {donationHis.Malaria6Months}
            </p>
            <p style={{ maxWidth: 500 }}>
              {" "}
              Fainting Spells: {donationHis.FaintingSpells}
            </p>
            <p style={{ maxWidth: 500 }}>
              {" "}
              Antibiotics: {donationHis.Antibiotics}
            </p>
            <p style={{ maxWidth: 500 }}> Steroids: {donationHis.Steroids}</p>
            <p style={{ maxWidth: 500 }}> Aspirin: {donationHis.Aspirin}</p>
            <p style={{ maxWidth: 500 }}>
              {" "}
              Vaccinations: {donationHis.Vaccinations}
            </p>
            <p style={{ maxWidth: 500 }}> Alcohol: {donationHis.Alcohol}</p>
            <p style={{ maxWidth: 500 }}>
              {" "}
              Rabies Vaccine: {donationHis.rabiesVaccine}
            </p>
            <p style={{ maxWidth: 500 }}>
              {" "}
              Surgery / Blood Transfusion in the last 6 Months:{" "}
              {donationHis.surgeryBloodTransfusion6Mnth}
            </p>
            <p style={{ maxWidth: 500 }}>
              {" "}
              Menstruation: {donationHis.menstruation}
            </p>
            <p style={{ maxWidth: 500 }}> Pregnant: {donationHis.pregnant}</p>
            <p style={{ maxWidth: 500 }}>
              {" "}
              Abortionin the last 3 Months: {donationHis.abortion3Mths}
            </p>
            <p style={{ maxWidth: 500 }}>
              {" "}
              Do you have a child less than one year ago?{" "}
              {donationHis.childLessThan1}
            </p>
            <p style={{ maxWidth: 500 }}>
              {" "}
              Rheumatic Fever: {donationHis.rheumaticFever}
            </p>
            <p style={{ maxWidth: 500 }}>
              {" "}
              Chest Pain: {donationHis.chestPain}
            </p>
            <p style={{ maxWidth: 500 }}> Asthma: {donationHis.asthma}</p>
            <p style={{ maxWidth: 500 }}>
              {" "}
              In the past 12 months, have you been in close contact with a
              person with Hepatitis? {donationHis.closeContactHepatitis}
            </p>
            <p style={{ maxWidth: 500 }}>
              {" "}
              Have you ever been treated for pimples with drugs such as
              Roaccutane, Tigason, Pros car or Propecia?{" "}
              {donationHis.pimpleRoaccutaneTigason}
            </p>
            <p style={{ maxWidth: 500 }}>
              {" "}
              injectionPitutaryHormone: {donationHis.injectionPitutaryHormone}
            </p>
            <p style={{ maxWidth: 500 }}>
              {" "}
              Have you ever received a tissue or organ transplant e.g, Corneo,
              dura mater, kidney, liver, bone marrow?{" "}
              {donationHis.tissueOrgnaTransplant}
            </p>
            <p style={{ maxWidth: 500 }}>
              {" "}
              Have you or your close relatives had an unexplained brain
              condition or been diagnosed with a condition called
              Cruelzfeld-Jakob disease (human form of mac cow disease) ?{" "}
              {donationHis.cruelzfeldJakob}
            </p>
            <p style={{ maxWidth: 500 }}>
              {" "}
              If had malaria in the last two weeks what treatment did you
              receive: {donationHis.iFMalaria2WeeksWhaDrug}
            </p>
            <p style={{ maxWidth: 500 }}>
              {" "}
              In the past six months have you had more than one sex partner,
              engaged in casual sex with someone whose sexual background you
              don't know? {donationHis.past6MthsSexUnknownPart}
            </p>
            <p style={{ maxWidth: 500 }}>
              {" "}
              In the past five years, have you had male to male sex?{" "}
              {donationHis.past5YrsM2MSx}
            </p>
            <p style={{ maxWidth: 500 }}>
              {" "}
              In the past five years, have you had sex with a Male or female
              prostitute (escort or sex worker) or exchanged money, drugs, goods
              or favours in return for sex? {donationHis.past5YrsSxProstitute}
            </p>
            <p style={{ maxWidth: 500 }}>
              {" "}
              In the past five years, have you had sex with a Male or female
              prostitute (escort or sex worker) or exchanged money, drugs, goods
              or favours in return for sex? {donationHis.past5YrsSTD}
            </p>
            <p style={{ maxWidth: 500 }}>
              {" "}
              Are you HIV positive? {donationHis.HIVPositive}
            </p>
            <p style={{ maxWidth: 500 }}>
              {" "}
              In the past twelve months, have you had accidential exposure to
              blood or body fluids or been the victim of sexual assault?{" "}
              {donationHis.past12MthsSxAssault}
            </p>
            <p style={{ maxWidth: 500 }}>
              {" "}
              Have you ever injected yourself, or been injected with any drug or
              substance including steroids, not prescribed by a doctor?{" "}
              {donationHis.injectedUrself}
            </p>
            <p style={{ maxWidth: 500 }}>
              {" "}
              To your knowledge, does any of the above apply to your sex
              partner? {donationHis.appliedSxPartner}
            </p>
            <p style={{ maxWidth: 500 }}>
              {" "}
              donatTestd4HivAids: {donationHis.donatTestd4HivAids}
            </p>
            <p style={{ maxWidth: 500 }}>
              {" "}
              In the last four hours have you had meal or snack?{" "}
              {donationHis.inLast4HoursHadMealSnack}
            </p>
            <p style={{ maxWidth: 500 }}>
              {" "}
              In the past twelve months, have you suffered from nigth sweets,
              unintentional weigth loss, persistent fever, diarrhoea or swollen
              glands? {donationHis.past12MonSuffNigthSweatUnWeigtLossFeverDiSw}
            </p>
            <p style={{ maxWidth: 500 }}>
              {" "}
              Are you involved in a hazardous occupation such as driving a
              public or heavy duty , flying an aeroplane, working on
              scaffolding, etc that might endanger you or anyone else if you
              become ligtheaded or faint? {donationHis.hazardousOccupation}
            </p>
            <p style={{ maxWidth: 500 }}>
              {" "}
              Have you been receiving any medical treatment or taking any
              medication? {donationHis.anyMedication}
            </p>
            <p style={{ maxWidth: 500 }}>
              {" "}
              In the past four weeks, have you experienced vomitting or
              diarrhoea? {donationHis.vomittingDiarrhoea}
            </p>
            <p style={{ maxWidth: 500 }}>
              {" "}
              Do you have high(or low) blood pressure?{" "}
              {donationHis.lowHighBloodPressure}
            </p>
            <p style={{ maxWidth: 500 }}>
              {" "}
              Would you like to be informed about any abnormal test result at
              the address furnished by you? {donationHis.feedback}
            </p>
            <p style={{ maxWidth: 500 }}>
              {" "}
              Have you read and understood all the information presented and
              answered all the questions truthfully? As any incorrect statement
              or concealment may affect your health or may harm the recipient?{" "}
              {donationHis.readUnderstoodAll}
            </p>
            <ShowHide
              item={
                <>
                  <h3>Laboratory Results</h3>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    Donor Group Clinic: {donationHis.donorGrpClinic}
                  </p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    Clinic Staff Date: {donationHis.clinicStaffDate}
                  </p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    Donor Accepted: {donationHis.donorAccepted}
                  </p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    Collection Start Time: {donationHis.collectionStartTime}
                  </p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    Collection End Time: {donationHis.collectionEndTime}
                  </p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    Volume Donated: {donationHis.volumeDonated}
                  </p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    bloodGrp: {donationHis.bloodGrp}
                  </p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    Haemoglobin Level: {donationHis.HaemoglobinLev}
                  </p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    Phlebotomist Name: {donationHis.PhlebotomistName}
                  </p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    Assisttant Name: {donationHis.assisttantName}
                  </p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    Type Of Pack: {donationHis.typeOfPack}
                  </p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    Signature1: {donationHis.signature1}
                  </p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    Signature2: {donationHis.signature2}
                  </p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    No Record Available: {donationHis.noRecordAvailable}
                  </p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    Transfer: {donationHis.transfer}
                  </p>
                  <p style={{ maxWidth: 500 }}> Weight: {donationHis.weight}</p>
                  <p style={{ maxWidth: 500 }}> Pulse: {donationHis.pulse}</p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    Blood Pressure: {donationHis.bp}
                  </p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    Blood Type: {donationHis.bloodTypes}
                  </p>
                  <p style={{ maxWidth: 500 }}> hb: {donationHis.hb}</p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    Temperature: {donationHis.temperature}
                  </p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    Visitor: {donationHis.visitor}
                  </p>
                  <p style={{ maxWidth: 500 }}> Form: {donationHis.form}</p>
                  <p style={{ maxWidth: 500 }}>
                    {" "}
                    Remarks Donor Status: {donationHis.remarksDonorStatus}
                  </p>
                  <p style={{ maxWidth: 500 }}>
                    Donatn No: {donationHis.donatnNo}
                  </p>
                </>
              }
            />
          </div>
        ) : (
          <span style={{ color: "purple", fontWeight: "bold", marginLeft: 20 }}>
            No Unprocessed Questionnaire
          </span>
        )}
      </div>
    </>
  );
}
