import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { API_URL } from "../utils/api";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useStateValue } from "../utils/app-state-context";

export default function SelectDonor() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [_, dispatch] = useStateValue();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSelect = (userId) => {
    dispatch({
      type: "DONATE-BLOOD",
      payload: {
        donorId: userId,
        donorRole: "donor",
      },
    });
    setAnchorEl(null);
  };
  const [donors, setDonors] = React.useState(null);
  const retrieveDonors = async () => {
    try {
      const response = await fetch(`${API_URL}/dornorProfile`);
      const data = await response.json();
      setDonors(data);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  React.useEffect(() => {
    retrieveDonors();
  }, []);
  return (
    <div>
      <div
        style={{
          paddingLeft: 30,
          paddingRight: 30,
          border: "solid 1px rgb(37, 150, 190)",
          cursor: "pointer",
          borderRadius: 5,
        }}
        onClick={handleClick}
      >
        <Button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          sx={{ textTransform: "none" }}
          endIcon={<ArrowDropDownIcon />}
        >
          Select Donor
        </Button>
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {donors &&
          donors.map((item) => (
            <MenuItem onClick={() => handleSelect(item.userId)}>
              {`${item.fname} ${item.mname} ${item.surname}`}
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
}
