import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <Stack
        direction="row"
        p={2}
        sx={{
          bgcolor: "black",
          justifyContent: "space-around",
          justifyItems: "space-around",
          alignItems: "centre",
        }}
      >
        <Link
          to="/"
          style={{
            color: "white",
            textDecoration: "none",
            "&:hover": { textDecoration: "underline", color: "orange" },
          }}
        >
          Home
        </Link>
        <Link to="/about" style={{ color: "white" }}>
          About
        </Link>
        <Link to="/contact" style={{ color: "white" }}>
          Contact
        </Link>
      </Stack>
      <Box
        sx={{
          p: 3,
          bgcolor: "black",
          color: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        &copy; {new Date().getFullYear() !== 2023 && "2023 - "}{" "}
        {new Date().getFullYear()}
      </Box>
    </>
  );
}
