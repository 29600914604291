import Header from "../components/Header";
import Box from "@mui/material/Box";
import Typography from '@mui/material/Typography';
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";

export default function FacilityCreatedSuccessfully() {
    return<>
    <Header/>
    <Stack 
            p={2} 
            m={2} 
            spacing={2}
            justifyItems="center" 
            alignContent="center" 
            alignItems="center" 
            sx={{ maxWidth:400, margin:"auto"}}

            >
        <Box>
            <img src="/thumb-up.png" style={{width:100, height:100}}/>
        </Box>
        <Typography ariant="span" component="span" sx={{fontFamily:"fantasy", textAlign:"center"}}>
            Your profile has been created successfully. However, your account needs to be verified before you can use it.
            Verification period takes 3 days. You will be mailed about the status of your account. 
            <br/>
            Thank you. 
        </Typography>
       
    </Stack>
    </>
}