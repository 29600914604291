import { useRouteError } from "react-router-dom";
import { Box } from "@mui/system";
import Header from "../components/Header"

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
  <>
   <Header/>
   <Box
        sx={{
            p: 2,
            borderRadius: 5,
            width: "50%",
            margin: "auto",
        }}
        >
      <h1>Oops!</h1>
      <p>Something went wrong.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </Box>
  </>
  );
}