import React from "react";
import Header from "../components/Header";
import { useStateValue } from "../utils/app-state-context";
import { API_URL } from "../utils/api";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import useFetch from "use-http";

export default function DonorsProfileView() {
  const [{ authDonor, donorId }, _] = useStateValue();
  const navigate = useNavigate();
  const [userProfile, setUserProfile] = React.useState([]);
  // https://codesandbox.io/s/usefetch-request-response-managed-state-ruyi3?file=/src/index.js
  const { get, response, loading, error } = useFetch(API_URL);
  React.useEffect(() => {
    getProfile();
  }, []);
  async function getProfile() {
    const res = await get(`/dornorProfile/${donorId}`);
    if (response.ok) setUserProfile(res);
  }
  React.useEffect(() => {
    if (!authDonor) {
      navigate("/sign-in");
    }
  }, [authDonor]);
  return (
    <>
      <Header />
      {error && "Error!"}
      {loading && "Loading..."}
      {userProfile !== null && userProfile.length !== 0 ? (
        <div style={{ margin: 10, padding: 10 }}>
          <h2>Profile:</h2>
          <p>
            Name:{" "}
            <span
              style={{
                color: "purple",
                fontWeight: "bold",
                textTransform: "capitalize",
              }}
            >{`${userProfile.fname} ${userProfile.mname} `}</span>
            <span
              style={{
                color: "purple",
                fontWeight: "bold",
                textTransform: "uppercase",
              }}
            >{` ${userProfile.surname} `}</span>
          </p>
          <p>
            Gender:{" "}
            <span style={{ color: "purple", fontWeight: "bold" }}>
              {userProfile.gender}
            </span>
          </p>
          <p>
            Telephone:{" "}
            <span style={{ color: "purple", fontWeight: "bold" }}>
              {userProfile.telephone}
            </span>
          </p>
          <p>
            Email:{" "}
            <span style={{ color: "purple", fontWeight: "bold" }}>
              {userProfile.email}
            </span>
          </p>
          <p>
            Age:{" "}
            <span style={{ color: "purple", fontWeight: "bold" }}>
              {userProfile.age}
            </span>
          </p>
          <p>
            Occupation:{" "}
            <span style={{ color: "purple", fontWeight: "bold" }}>
              {userProfile.occupation}
            </span>
          </p>
          <p>
            Nationality:{" "}
            <span style={{ color: "purple", fontWeight: "bold" }}>
              {userProfile.nationality}
            </span>
          </p>
          <p>
            Date of Birth:{" "}
            <span style={{ color: "purple", fontWeight: "bold" }}>
              {userProfile.dob}
            </span>
          </p>
          <p>
            Marital Status:{" "}
            <span style={{ color: "purple", fontWeight: "bold" }}>
              {userProfile.maritalStatus}
            </span>
          </p>
          <p>
            Form of Identification:{" "}
            <span style={{ color: "purple", fontWeight: "bold" }}>
              {userProfile.formOfIdentification}
            </span>
          </p>
          <p>
            Postal Address:{" "}
            <span style={{ color: "purple", fontWeight: "bold" }}>
              {userProfile.postalAdd}
            </span>
          </p>
          <p>
            Home Address:{" "}
            <span style={{ color: "purple", fontWeight: "bold" }}>
              {userProfile.homeAdd}
            </span>
          </p>
          <p>
            Employer:{" "}
            <span style={{ color: "purple", fontWeight: "bold" }}>
              {userProfile.employer}
            </span>
          </p>
          <p>
            Place of Birth:{" "}
            <span style={{ color: "purple", fontWeight: "bold" }}>
              {userProfile.placeOfBirth}
            </span>
          </p>
          <p>
            Tribe:{" "}
            <span style={{ color: "purple", fontWeight: "bold" }}>
              {userProfile.tribe}
            </span>
          </p>
          <p>
            State of Origin:{" "}
            <span style={{ color: "purple", fontWeight: "bold" }}>
              {userProfile.stateOfOrigin}
            </span>
          </p>
          <p>
            How you would like to be reminded to donate blood?{" "}
            <span style={{ color: "purple", fontWeight: "bold" }}>
              {userProfile.howToBeReminded}
            </span>
          </p>
          <p>
            Have you donated before:{" "}
            <span style={{ color: "purple", fontWeight: "bold" }}>
              {userProfile.donatedB4}
            </span>
          </p>
          <p>
            Where did you donate?{" "}
            <span style={{ color: "purple", fontWeight: "bold" }}>
              {userProfile.whereDonatedAtAnotherService}
            </span>
          </p>
          <p>
            Have you ever been refused blood?{" "}
            <span style={{ color: "purple", fontWeight: "bold" }}>
              {userProfile.everBeenRefusedBlood}
            </span>
          </p>
          <p>
            Have you ever donated at another service?{" "}
            <span style={{ color: "purple", fontWeight: "bold" }}>
              {userProfile.donatedAtAnotherService}
            </span>
          </p>
          <p>
            When did you donate at another service?{" "}
            <span style={{ color: "purple", fontWeight: "bold" }}>
              {userProfile.whenDonatedAtAnotherService}
            </span>
          </p>
          <p>
            Number of previous donations:{" "}
            <span style={{ color: "purple", fontWeight: "bold" }}>
              {userProfile.noOfPrevoiusDonation}
            </span>
          </p>
          <p>
            Are you a student?{" "}
            <span style={{ color: "purple", fontWeight: "bold" }}>
              {userProfile.areUStudent}
            </span>
          </p>
          <p>
            How did you learn about donation?{" "}
            <span style={{ color: "purple", fontWeight: "bold" }}>
              {userProfile.howDidULearnAboutDonation}
            </span>
          </p>
        </div>
      ) : (
        userProfile == null && (
          <div style={{ marginLeft: 10 }}>
            No Record{" "}
            <Button onClick={() => navigate("/donor-profile")}>
              Crerate Profile
            </Button>
          </div>
        )
      )}
    </>
  );
}
