import React from "react";
import Header from "../components/Header";
import { useStateValue } from "../utils/app-state-context";
import { API_URL } from "../utils/api";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import useRetrieve from "../utils/useRetrieve";

export default function FacilityProfileView() {
  const [{ facilityId, authFacility }, _] = useStateValue();
  const facility = useRetrieve(`${API_URL}/facilityProfile/${facilityId}`);
  const user = useRetrieve(`${API_URL}/users/${facilityId}`);
  const navigate = useNavigate();
  React.useEffect(() => {
    if (!authFacility) {
      return navigate("/facility/sign-in-facility");
    }  
  }, [authFacility]);
  return (
    <>
      <Header />
      {facility !== null ? (
        <div
          style={{
            padding: 2,
            marginTop: 20,
            minWidth: 300,
            marginLeft: 10,
            margin: "auto",
          }}
        >
          <h2>
            {user && user.role === "bloodbank" ? "Blood Bank" : "Facility"}{" "}
            Profile
          </h2>
          <p>
            Facility's Name:{" "}
            <span style={{ color: "purple", fontWeight: "bold" }}>
              {facility.facilityName}
            </span>
          </p>
          <p>
            Facility's Type:{" "}
            <span style={{ color: "purple", fontWeight: "bold" }}>
              {facility.facilityType}
            </span>
          </p>
         
          <p>
            Sector:{" "}
            <span style={{ color: "purple", fontWeight: "bold" }}>
              {facility.privatePublic}
            </span>
          </p>
          <p>
           Is facility state or federal:{" "}
            <span style={{ color: "purple", fontWeight: "bold" }}>
              {facility.stateFederal}
            </span>
          </p>
          <p>
            Registered?:{" "}
            <span style={{ color: "purple", fontWeight: "bold" }}>
              {facility.registered}
            </span>
          </p>
          <p>
            Registered By:{" "}
            <span style={{ color: "purple", fontWeight: "bold" }}>
              {facility.registrationBody}
            </span>
          </p>
          <p>
           Is it registered with Nigeran Blood Service Commision(NBSC):{" "}
            <span style={{ color: "purple", fontWeight: "bold" }}>
              {facility.registeredWithNBSC}
            </span>
          </p>
          <p>
            Blood Bank Available:{" "}
            <span style={{ color: "purple", fontWeight: "bold" }}>
              {facility.bloodBankAvailable}
            </span>
          </p>
          <p>
            Telephone:{" "}
            <span style={{ color: "purple", fontWeight: "bold" }}>
              {facility.telephone}
            </span>
          </p>
          <p>
            Email:{" "}
            <span style={{ color: "purple", fontWeight: "bold" }}>
              {facility.email}
            </span>
          </p>
          <p>
            Local Government Area:{" "}
            <span style={{ color: "purple", fontWeight: "bold" }}>
              {facility.lga}
            </span>
          </p>
          <p>
            Address:{" "}
            <span style={{ color: "purple", fontWeight: "bold" }}>
              {`${facility.addressStreet}, ${facility.addressTown}`}
            </span>
          </p>
          <p>
            LGA & State:{" "}
            <span style={{ color: "purple", fontWeight: "bold" }}>
              {facility.lga + ", " + facility.addressState}
            </span>
          </p>
          <p>
            Contact Person:{" "}
            <span style={{ color: "purple", fontWeight: "bold" }}>
              {facility.contactPerson}
            </span>
          </p>
        </div>
      ) : (
        <div
          style={{
            padding: 2,
            minWidth: 300,
            marginLeft: 20,
            margin: "auto",
          }}
        >
          No Profile Record. &nbsp;&nbsp;Will you like to
          <Button
            size="small"
            onClick={() => navigate("/facility-profile", { replace: true })}
          >
            Create Profile Now ?
          </Button>
        </div>
      )}
    </>
  );
}
