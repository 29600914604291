import * as React from "react";
import { Box } from "@mui/system";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import { useStateValue } from "../utils/app-state-context";

export default function SiginUpFacilitySuccessful() {
  return (
    <>
      <Header />
      <Box
        sx={{
          p: 2,
          borderRadius: 5,
          m: 2,
          margin: "auto",
        }}
      >
        <Stack
          direction="column"
          spacing={2}
          alignItems="center"
          justifyContent="center"
          sx={{
            border: "solid 0.5px green",
            borderRadius: 5,
            p: 2,
            maxWidth: 300,
            margin: "auto",
          }}
        >
          <h2>Sign Up Successful! </h2>
          <img
            src="/done.png"
            alt=""
            style={{ height: "150px", width: "150px" }}
          />
          <Button
            variant="outlined"
            color="success"
            style={{
              marginTop: 20,
              paddingLeft: "40px",
              paddingRight: "40px",
              textTransform: "none",
              position: "relative",
            }}
          >
            <Link
              to="/facility/sign-in-facility"
              style={{ color: "green", textDecoration: "none" }}
            >
              Login to your account
            </Link>
          </Button>
        </Stack>
      </Box>
    </>
  );
}
