import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { API_URL } from "../utils/api";
import { useStateValue } from "../utils/app-state-context";
import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { retreieveLatestHealthStatus } from "../utils/retreieveLatestHealthStatus";
import { useFeedBack, FeedBack } from "../components/FeedBack";

export default function CustomizedMenus() {
  const { openSnack, setOpenSnack, msg, setMsg, handleCloseSnackbar } =
    useFeedBack();
  const [
    {
      disqualificationDate,
      profileNotCreated,
      activateProfileLink,
      userProfile,
      authDonor,
      authAdmin,
      authFacility,
      donorRole,
      facilityRole,
      adminRole,
      facilityProfileState,
      unprocessedQue,
    },
    dispatch,
  ] = useStateValue();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();
  const signOut = async () => {
    try {
      const response = await fetch(`${API_URL}/signOut`);
      if (!response.ok) {
        throw new Error(response.status);
      }
      const res = await response.json();

      if (res.logOutStatus) {
        dispatch({
          type: "AUTH-DONOR",
          payload: {
            authDonor: donorRole === "donor" && false,
            donorId: donorRole === "donor" && "",
            donorRole: donorRole === "donor" && "",

            authFacility: facilityRole === "facility" && false,
            facilityId: facilityRole === "facility" && "",
            facilityRole: facilityRole === "facility" && "",

            authAdmin: adminRole === "facility" && false,
            fadminId: adminRole === "facility" && "",
            adminRole: adminRole === "facility" && "",
          },
        });
        facilityRole === "facility" &&
          dispatch({
            type: "FACILITY-PROFILE",
            payload: {
              facilityProfileState: null,
              activateProfileLink: false,
            },
          });

        donorRole === "donor" &&
          dispatch({
            type: "USER-PROFILE",
            payload: {
              userProfile: null,
              unprocessedQue: null,
            },
          });
      }
      return facilityRole === "facility"
        ? navigate("/facility/sign-in-facility")
        : adminRole === "admin"
        ? navigate("/admin")
        : donorRole === "donor"
        ? navigate("/sign-in")
        : "";
    } catch (error) {
      console.log("error: ", error);
    }
  };
  return (
    <div>
      <IconButton
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{ color: "white" }}
      >
        <Tooltip title="Menu">
          <MoreVertIcon />
        </Tooltip>
      </IconButton>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {" "}
        <Box
          flexDirection="column"
          sx={{ display: { xs: "flex", sm: "flex", md: "none", lg: "none" } }}
        >
          {authAdmin && (
            <MenuItem onClick={handleClose}>
              <Button
                sx={{ textTransform: "none" }}
                color="primary"
                aria-label="admin-dashboard"
                component="label"
                onClick={() => navigate("/admin-dashboard")}
              >
                Dashboard
              </Button>
            </MenuItem>
          )}
          {authFacility && (
            <MenuItem onClick={handleClose}>
              <Button
                sx={{ textTransform: "none" }}
                color="primary"
                aria-label="facility-dashboard"
                component="label"
                disabled={profileNotCreated}
                onClick={() => navigate("/facility-dashboard")}
              >
                Dashboard
              </Button>
            </MenuItem>
          )}
          {authDonor && (
            <>
              <MenuItem onClick={handleClose}>
                <Button
                  sx={{ textTransform: "none" }}
                  disabled={userProfile == null}
                  aria-label="donor-dashboard"
                  component="label"
                  onClick={() => navigate("/donor-dashboard")}
                >
                  {" "}
                  Dashboard
                </Button>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Button
                  disabled={
                    userProfile == null ||
                    (unprocessedQue && unprocessedQue.donorAccepted === "")
                  }
                  sx={{ textTransform: "none" }}
                  onClick={async () => {
                    const res = await retreieveLatestHealthStatus(
                      userProfile.userId
                    );
                    if (res.length !== 0 && res.donorAccepted === "") {
                      setMsg(
                        `Your Previous Form is Being Processed. Please Wait for the Response`
                      );
                      setOpenSnack(true);
                    } else navigate("/consent");
                  }}
                >
                  Donation Form
                </Button>
              </MenuItem>
            </>
          )}
          {authFacility && (
            <React.Fragment>
              {/* <Button
                  variant="outlined"
                  size="small"
                  disabled={profileNotCreated}
                  onClick={() => navigate("/sign-up")}
                >
                  Create Donor
                </Button> */}
              {/* {facilityProfileState &&
                facilityProfileState.facilityType === "Blood Bank" && (
                  <Button
                    sx={{ ml: 1, textTransform: "none" }}
                    endIcon={<SearchIcon />}
                    size="small"
                    disabled={
                      profileNotCreated ||
                      (facilityProfileState &&
                        facilityProfileState.verified !== "Yes")
                    }
                    onClick={() => navigate("/search-donor")}
                  >
                    Process Questionnaire
                  </Button>
                )} */}
              {facilityProfileState &&
                facilityProfileState.facilityType !== "Blood Bank" && (
                  <Button
                    sx={{ ml: 1, textTransform: "none" }}
                    endIcon={<SearchIcon />}
                    size="small"
                    disabled={
                      profileNotCreated ||
                      (facilityProfileState &&
                        facilityProfileState.verified !== "Yes")
                    }
                    onClick={() => navigate("/medical-facility-bloodbank-view")}
                  >
                    Search Blood Banks
                  </Button>
                )}
            </React.Fragment>
          )}
        </Box>
        {!authAdmin && (
          <MenuItem onClick={handleClose}>
            {authFacility && (
              <Button
                sx={{ textTransform: "none" }}
                onClick={() => navigate("/facility-profile-view")}
                disabled={profileNotCreated && activateProfileLink}
              >
                Profile
              </Button>
            )}
            {authDonor && (
              <Button
                disabled={userProfile == null}
                sx={{ textTransform: "none" }}
                onClick={() => navigate("/donor-profile-view")}
              >
                Profile
              </Button>
            )}
          </MenuItem>
        )}
        <MenuItem onClick={handleClose}>
          <Button
            variant="secondary"
            sx={{ textTransform: "none" }}
            onClick={signOut}
          >
            Sign Out
          </Button>
        </MenuItem>
      </Menu>
      <FeedBack
        openSnack={openSnack}
        msg={msg}
        handleCloseSnackbar={handleCloseSnackbar}
      />
    </div>
  );
}
