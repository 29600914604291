import React from "react";
import { createHashRouter, RouterProvider } from "react-router-dom";
import Home from "./pages/Home";
import SignUp from "./pages/SignUp";
import SiginUpSuccessful from "./pages/SiginUpSuccessful";
import SignIn from "./pages/SignIn";
import DonorProfile from "./pages/DonorProfile";
import DonorsProfileView from "./pages/DonorsProfileView";
import DonorDashboard from "./pages/DonorDashboard";
import DonationForm2 from "./pages/DonationForm2";
import SignUpFacility from "./pages/SiginUpFacility";
import SiginInFacility from "./pages/SiginInFacility";
import SiginInAdmin from "./pages/SiginInAdmin";
import SiginUpFacilitySuccessful from "./pages/SiginUpFacilitySuccessful";
import FacilityDashBoard from "./pages/FacilityDashBoard";
import SearchDonor from "./pages/SearchDonor";
import FacilityProfile from "./pages/FacilityProfile";
import FacilityProfileView from "./pages/FacilityProfileView";
import Decline from "./pages/Decline";
import AdminDashboard from "./pages/AdminDashboard";
import AdminRetrieveDonationHistory from "./pages/AdminRetrieveDonationHistory";
import DonorsHealthResult from "./pages/DonorsHealthResult";
import AdminRetrieveFacilities from "./pages/AdminRetrieveFacilities";
import DonationFormSubmitted from "./pages/DonationFormSubmitted";
import BloodBank from "./pages/BloodBank";
import BloodBankView from "./pages/BloodBankView";
import About from "./pages/About";
import Contact from "./pages/Contact";
import VerificationStatus from "./pages/VerificationStatus";
import AdminVerification from "./pages/AdminVerification";
import FacilityCreatedSuccessfully from "./pages/FacilityCreatedSuccessfully";
import DonationHealthResultSubmitted from "./pages/DonationHealthResultSubmitted";
import Consent from "./pages/Consent";
import MedicalFacilityBloodBankView from "./pages/MedicalFacilityBloodBankView";
import BloodBankRetrieveQuestionnaire from "./pages/BloodBankRetrieveQuestionnaire";
import "./App.css";
import ErrorPage from "./pages/ErrorPage";
import { StateProvider } from "./utils/app-state-context";

const initialState = {
  facilityId: "",
  authFacility: false,
  adminId: "",
  authAdmin: false,
  donorId: "",
  authDonor: false,
  adminRole: "",
  facilityRole: "",
  donorRole: "",
  facilityProfile: null,
  disqualificationDate: "",
  profileNotCreated: false,
  activateProfileLink: false,
  userProfile: null,
  donorsHealthStatus: null,
  facilityProfileState: null,
  unprocessedQue: null,
  sugFa: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "DONATION-HISTORY":
      return { ...state, ...action.payload };
    case "DONATE-BLOOD":
      return { ...state, ...action.payload };
    case "AUTH-ADMIN":
      return { ...state, ...action.payload };
    case "AUTH-FACILITY":
      return { ...state, ...action.payload };
    case "AUTH-DONOR":
      return { ...state, ...action.payload };
    case "FACILITY-PROFILE":
      return { ...state, ...action.payload };
    case "SIGNUP-DONOR":
      return { ...state, ...action.payload };
    case "SET-DISQUALIFICATION-DATE":
      return { ...state, ...action.payload };
    case "PROFILE-NOT-CREATED":
      return { ...state, ...action.payload };
    case "USER-PROFILE":
      return { ...state, ...action.payload };
    case "DONORS-HEALTH-STATUS":
      return { ...state.donorsHealthStatus, ...action.payload };
    case "GET-UNPROCESSED-QUE":
      return { ...state.unprocessedQue, ...action.payload };
    case "SUggESTED-FA":
      return { ...state.sugFa, ...action.payload };
    default:
      return state;
  }
};

function App() {
  const router = createHashRouter([
    {
      path: "/",
      element: <Home />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/sign-up",
      element: <SignUp />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/sign-up-successful",
      element: <SiginUpSuccessful />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/sign-in",
      element: <SignIn />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/donor-profile",
      element: <DonorProfile />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/donor-profile-view",
      element: <DonorsProfileView />,
      errorElement: <ErrorPage />,
    },

    {
      path: "/donor-dashboard",
      element: <DonorDashboard />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/donation-form",
      element: <DonationForm2 />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/facility/sign-up",
      element: <SignUpFacility />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/facility/sign-in-facility",
      element: <SiginInFacility />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/admin",
      element: <SiginInAdmin />,
      errorElement: <ErrorPage />,
    },

    {
      path: "/facility/sign-up-facility-successful",
      element: <SiginUpFacilitySuccessful />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/facility-dashboard",
      element: <FacilityDashBoard />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/admin-dashboard",
      element: <AdminDashboard />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/decline",
      element: <Decline />,
      errorElement: <ErrorPage />,
    },

    {
      // auth, children, redirect
      path: "/search-donor",
      element: <SearchDonor />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/facility-profile",
      element: <FacilityProfile />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/facility-profile-view",
      element: <FacilityProfileView />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/admin-retrieve-donation-history",
      element: <AdminRetrieveDonationHistory />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/donors-health-result",
      element: <DonorsHealthResult />,
      errorElement: <ErrorPage />,
    },

    {
      path: "/admin-retrieve-facilities",
      element: <AdminRetrieveFacilities />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/donation-form-submitted",
      element: <DonationFormSubmitted />,
      errorElement: <ErrorPage />,
    },

    {
      path: "/blood-bank",
      element: <BloodBank />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/blood-bank-view",
      element: <BloodBankView />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/about",
      element: <About />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/contact",
      element: <Contact />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/verification-status",
      element: <VerificationStatus />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/admin-verification",
      element: <AdminVerification />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/facility-created-successfully",
      element: <FacilityCreatedSuccessfully />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/donation-health-result-submitted",
      element: <DonationHealthResultSubmitted />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/medical-facility-bloodbank-view",
      element: <MedicalFacilityBloodBankView />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/consent",
      element: <Consent />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/blood-bank-retrieve-donation-history",
      element: <BloodBankRetrieveQuestionnaire />,
      errorElement: <ErrorPage />,
    },
  ]);

  return (
    <StateProvider reducer={reducer} initialState={initialState}>
      <RouterProvider router={router} />
    </StateProvider>
  );
}

export default App;
