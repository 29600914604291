import * as React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Header from "../components/Header";
import { useStateValue } from "../utils/app-state-context";
import { retreieveLatestHealthStatus } from "../utils/retreieveLatestHealthStatus";
import { useLocation } from "react-router-dom";

export default function DonationFormSubmitted() {
  let location = useLocation();
  const [{ donorId }, dispatch] = useStateValue();
  React.useCallback(() => {
    retreieveLatestHealthStatus(donorId, dispatch);
  }, [donorId]);

  return (
    <>
      <Header />
      <Stack
        direction="column"
        spacing={2}
        alignItems="center"
        justifyContent="center"
        style={{ margin: 10 }}
      >
        <h3 style={{ color: "green" }}>
          Donation Form Submitted Successfully{" "}
        </h3>
        <img
          src="/done.png"
          alt=""
          style={{ height: "150px", width: "150px" }}
        />
        <Typography ariant="span" component="span" sx={{ textAlign: "center" }}>
          {`If you're not already at your donation place of choice, please walk up to ${location.state.sugFaState} and you'll be attended to appropriately.`}
        </Typography>
      </Stack>
    </>
  );
}
