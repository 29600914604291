import * as React from "react";
import { Box } from "@mui/system";
import IconButton from "@mui/material/IconButton";
// import useFetch from "./use-fetch";
import Button from "@mui/material/Button";
import { Field, Formik, Form } from "formik";
import * as Yup from "yup";
import { TextField } from "formik-mui";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import { FormHelperText } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { useStateValue } from "../utils/app-state-context";
import { API_URL } from "../utils/api";
import LoginIcon from "@mui/icons-material/Login";
import Header from "./../components/Header";

export default function SignIn() {
  const navigate = useNavigate();
  const [pvalues, setPvalues] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });
  const [{ authDonor }, dispatch] = useStateValue();
  const [errMsg, setErrMsg] = React.useState("");
  const hasDonorProfile = async (userId) => {
    try {
      const response = await fetch(`${API_URL}/dornorProfile/${userId}`);
      const data = await response.json();
      if (data) {
        dispatch({
          type: "USER-PROFILE",
          payload: {
            userProfile: data,
          },
        });
      }
      return data.userId;
    } catch (error) {
      console.error("hasdornorProfile Error:", error);
    }
  };

  const handleClickShowPassword = () => {
    setPvalues({
      ...pvalues,
      showPassword: !pvalues.showPassword,
    });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  React.useEffect(() => {
    if (authDonor) {
      return navigate("/donor-dashboard");
    }
  }, []);
  return (
    <>
      <Header />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignItems: "center",
          alignContent: "center",
          pt: 5,
        }}
      >
        <Formik
          enableReinitialize={true}
          initialValues={{
            role: "donor",
            username: "",
            password: "",
          }}
          validationSchema={Yup.object({
            username: Yup.string().email().required("*User Email is required"),
            password: Yup.string().required("*Password is required"),
          })}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              setSubmitting(true);
              const response = await fetch(`${API_URL}/signin`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({ ...values, role: "donor" }),
                // credentials:"include"
              });
              if (!response.ok) {
                throw new Error(response.status);
              }
              const res = await response.json();
              if (res.userId) {
                dispatch({
                  type: "AUTH-DONOR",
                  payload: {
                    authDonor: res.authDonor,
                    donorId: res.userId,
                    donorRole: res.role,
                    authFacility: false,
                    authAdmin: false,
                  },
                });
                const hasProfile = await hasDonorProfile(res.userId);
                return res.userId && res.role === "donor" && hasProfile
                  ? navigate("/donor-dashboard")
                  : navigate("/donor-profile");
              }
            } catch (error) {
              setSubmitting(false);
              setErrMsg(error);
            }
          }}
        >
          {({ submitForm, isSubmitting, values, handleChange }) => (
            <Form>
              <Stack
                spacing={2}
                alignItems="center"
                justifyContent="center"
                style={{ margin: 10 }}
              >
                {errMsg && errMsg.message && (
                  <FormHelperText
                    error
                    style={{
                      border: "solid 1px red",
                      borderRadius: 10,
                      padding: 10,
                      fontWeight: "bold",
                    }}
                  >
                    Error :{" "}
                    {Number(errMsg.message) == 404
                      ? "Username or Password is incorrect"
                      : errMsg.message}
                  </FormHelperText>
                )}

                <span>
                  <b>
                    <LoginIcon style={{ marginBottom: -8 }} /> Sign In Donor
                  </b>
                  . Don't have an account?
                  <Button onClick={() => navigate("/sign-up")}>Sign Up</Button>
                  {isSubmitting && <LinearProgress sx={{ my: 1 }} />}
                  <div
                    style={{
                      height: 3,
                      marginBottom: 20,
                      width: "100%",
                      backgroundColor: "black",
                    }}
                  >
                    {" "}
                  </div>
                </span>

                <Field
                  component={TextField}
                  fullWidth
                  name="username"
                  size="small"
                  label="User Email"
                  variant="standard"
                  margin="dense"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <Field
                  component={TextField}
                  fullWidth
                  name="password"
                  size="small"
                  label="Password"
                  variant="standard"
                  margin="dense"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type={pvalues.showPassword ? "text" : "password"}
                  value={values.password}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {pvalues.showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <FormHelperText error></FormHelperText>
              </Stack>
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                justifyContent="center"
              >
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={submitForm}
                  style={{
                    margin: 20,
                    background: "#0C2340",
                    paddingLeft: "40px",
                    paddingRight: "40px",
                    textTransform: "none",
                  }}
                >
                  {isSubmitting ? (
                    <span style={{ color: "yellow" }}>Submitting ...</span>
                  ) : (
                    "Sign In"
                  )}
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
}
