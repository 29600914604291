import * as React from "react";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";

export default function SiginUpSuccessful({username, password, createdBy}){
    const navigate = useNavigate();

    return(<>
        {/* <Header/> */}
        <Stack
                  direction="column"
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                  style={{ margin: 10, }}
                >
            <h3>Donor's Sign Up Successful! </h3>
            <img
            src="/done.png"
            alt=""
            style={{ height: "150px", width: "150px" }}
          />
            {/* {createdBy !== "Self" && <>
             <p style={{fontSize: 20}}> Donor's Username: <span style={{color: "green"}}>{username}</span></p>
             <p style={{fontSize: 20}}> Donor's Password: <span style={{color: "green"}}>{password}</span></p>
            </>} */}
           
            {createdBy === "Self" ? 
            <Button variant="contained" color="success" style={{marginTop:30}} onClick={()=>navigate("/sign-in", { replace: true })}>
               Sign In
             </Button>: 
            <Button variant="contained" color="success" style={{marginTop:30}} onClick={()=>navigate("/donor-profile", { replace: true })}>
                Create Profile
            </Button>
            }
           
            
        </Stack>
    </>)
}