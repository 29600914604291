import React from "react";

export default function useRetrieve(url) {
  const [result, setResult] = React.useState(null);

  React.useEffect(() => {
    fetch(url)
      .then((response) => response.json())
      .then((data) => setResult(data))
      .catch((error) => {
        console.error("Error:", error);
      });
  });
  return result;
}
