import React from "react";
import Header from "../components/Header";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { useStateValue } from "../utils/app-state-context";
import { API_URL } from "../utils/api";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import { retreieveLatestHealthStatus } from "../utils/retreieveLatestHealthStatus";
import Typography from "@mui/material/Typography";

const returnDate = (month) => {
  const date = new Date();
  const objectDate = new Date(date.setMonth(date.getMonth() + Number(month)));
  return `${objectDate.getDate()}/${
    objectDate.getMonth() + 1
  }/${objectDate.getFullYear()}`;
};

export default function DonorDashboard() {
  const navigate = useNavigate();
  const [donationHis, setDonationHis] = React.useState(null);
  const [facilities, setFacilities] = React.useState(null);
  const [sugFacility, setSugFacility] = React.useState();
  const [{ donorId, authDonor }, dispatch] = useStateValue();
  const retrieveDonationHistory = () => {
    fetch(`${API_URL}/donorHealthStatus/${donorId}`)
      .then((response) => response.json())
      .then((data) => setDonationHis(data))
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const retrieveFaclities = () => {
    fetch(`${API_URL}/facilityProfile`)
      .then((response) => response.json())
      .then((data) => setFacilities(data))
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  React.useEffect(() => {
    retrieveFaclities();
  }, []);
  React.useEffect(() => {
    retrieveDonationHistory();
  }, [donorId]);
  const suggestedFacilities = (facilityId) => {
    const res =
      facilities &&
      facilities.filter((item) => item.facilityId === Number(facilityId));
    const item = res[0];
    return (
      item &&
      `${item.facilityName} ${item.facilityType}, Address: ${item.addressStreet}, ${item.addressTown}, ${item.addressState}, Phone: ${item.telephone}`
    );
  };
  // React.useCallback(() => {
  //   retreieveLatestHealthStatus(donorId, dispatch);
  // }, [donorId]);

  React.useEffect(() => {
    if (!authDonor) {
      navigate("/sign-in");
    }
  }, [authDonor]);
  let disqualification = [];
  // React.useEffect(() => {
  //   dispatch({
  //     type: "SET-DISQUALIFICATION-DATE",
  //     payload: {
  //       disqualificationDate: disqualification[1],
  //     },
  //   });
  // }, [disqualification]);
  const lastDonationDate =
    donationHis !== null &&
    new Date(
      Math.max(...donationHis.map((elmt) => new Date(elmt.donationDate)))
    ).toLocaleDateString("pt-PT");
  const res =
    donationHis !== null &&
    donationHis.find(
      (elmt) =>
        new Date(elmt.donationDate).toLocaleDateString("pt-PT") ===
        lastDonationDate
    );

  const penalty = () =>
    res
      ? res.feelWellToday === "No"
        ? (disqualification = ["feelWellToday", res.donationDate])
        : res.Tattooing === "Yes"
        ? (disqualification = ["Tattoo", res.donationDate])
        : res.Piercing === "Yes"
        ? (disqualification = ["Ear Piercing", res.donationDate])
        : res.DentalExtraction === "Yes"
        ? (disqualification = ["Dental Extraction", res.donationDate])
        : res.HeartDisease === "Yes"
        ? (disqualification = ["Heart Disease", res.donationDate])
        : res.CancerMalignantDisease === "Yes"
        ? (disqualification = ["Cancer Malignant Disease", res.donationDate])
        : res.Diabetes === "Yes"
        ? (disqualification = ["Diabetes", res.donationDate])
        : res.SickleCell === "Yes"
        ? (disqualification = ["SickleCell", res.donationDate])
        : res.HepatitisBC === "Yes"
        ? (disqualification = ["Hepatitis B/C", res.donationDate])
        : res.STD === "Yes"
        ? (disqualification = ["STD", res.donationDate])
        : res.TyphoidLast1Yr === "Yes"
        ? (disqualification = ["Typhoid in the last 1 year", res.donationDate])
        : res.LungDisease === "Yes"
        ? (disqualification = ["Lung Disease", res.donationDate])
        : res.Tuberculosis === "Yes"
        ? (disqualification = ["Tuberculosis", res.donationDate])
        : res.AllergicDisease === "Yes"
        ? (disqualification = ["Allergic Disease", res.donationDate])
        : res.KidneyDisease === "Yes"
        ? (disqualification = ["Kidney Disease", res.donationDate])
        : res.Epilepsy === "Yes"
        ? (disqualification = ["Epilepsy", res.donationDate])
        : res.AbnormalBleedingTtendency === "Yes"
        ? (disqualification = ["Abnormal Bleeding Tendency", res.donationDate])
        : res.JaundiceLast1Yr === "Yes"
        ? (disqualification = ["Jaundice in the last 1 year", res.donationDate])
        : res.Malaria6Months === "Yes"
        ? (disqualification = [
            "malaria in the last 6 months",
            res.donationDate,
          ])
        : res.FaintingSpells === "Yes"
        ? (disqualification = ["fainting spells", res.donationDate])
        : res.Antibiotics === "Yes"
        ? (disqualification = ["antibiotics", res.donationDate])
        : res.Steroids === "Yes"
        ? (disqualification = ["steroids", res.donationDate])
        : res.Aspirin === "Yes"
        ? (disqualification = ["aspirin", res.donationDate])
        : res.Vaccinations === "Yes"
        ? (disqualification = ["Vaccinations", res.donationDate])
        : res.Alcohol === "Yes"
        ? (disqualification = ["alcohol", res.donationDate])
        : res.rabiesVaccine === "Yes"
        ? (disqualification = ["rabies vaccine", res.donationDate])
        : res.surgeryBloodTransfusion6Mnth === "Yes"
        ? (disqualification = [
            "Major Minor Blood Transfusion",
            res.donationDate,
          ])
        : res.menstruation === "Yes"
        ? (disqualification = ["menstruation", res.donationDate])
        : res.pregnant === "Yes"
        ? (disqualification = ["pregnant", res.donationDate])
        : res.abortion3Mths === "Yes"
        ? (disqualification = [
            "abortion in the last three months",
            res.donationDate,
          ])
        : res.childLessThan1 === "Yes"
        ? (disqualification = ["child less than 1 one year", res.donationDate])
        : null
      : null;
  penalty();
  // getTime(), getDate(), getHours(), getDay(), getMonth() and getYear()
  function DisqualificationCard({ disqualification }) {
    return (
      <Stack
        direction="row"
        style={{
          color: "red",
          padding: 5,
          margin: "auto",
          maxWidth: 350,
          boxShadow: "10px 6px 10px 2px red",
          borderRadius: 2,
          fontFamily: "Open Sans",
        }}
      >
        <img
          src="close-icon.png"
          alt=""
          width={40}
          height={40}
          style={{ margin: 3 }}
        />
        {disqualification}
      </Stack>
    );
  }
  function DonationPenaltyPanel({ disqualification }) {
    return (
      <div>
        {disqualification[0] === "feelWellToday" &&
          new Date(disqualification[1]).toLocaleDateString("pt-PT") ==
            new Date().toLocaleDateString("pt-PT") && (
            <DisqualificationCard
              disqualification={`You didn't feel well on ${lastDonationDate}. 
        Please come back when you feel well. Thank you.`}
            />
          )}
        {(disqualification[0] === "Tattoo" ||
          disqualification[0] === "Ear Piercing" ||
          disqualification[0] === "Dental Extraction") && (
          <DisqualificationCard
            disqualification={`You had ${
              disqualification[0]
            } in the last six months. Please come back
          in the next 6 months ${returnDate(6)}.`}
          />
        )}
        {(disqualification[0] === "Heart Disease" ||
          disqualification[0] === "Cancer Malignant Disease" ||
          disqualification[0] === "Diabetes" ||
          disqualification[0] === "SickleCell" ||
          disqualification[0] === "Hepatitis B/C" ||
          disqualification[0] === "STD" ||
          disqualification[0] === "Typhoid in the last 1 year" ||
          disqualification[0] === "Lung Disease" ||
          disqualification[0] === "Tuberculosis" ||
          disqualification[0] === "Allergic Disease" ||
          disqualification[0] === "Kidney Disease" ||
          disqualification[0] === "Epilepsy" ||
          disqualification[0] === "Abnormal Bleeding Tendency" ||
          disqualification[0] === "Jaundice in the last 1 year" ||
          disqualification[0] === "malaria in the last 6 months" ||
          disqualification[0] === "fainting spells") && (
          <DisqualificationCard
            disqualification={`You suffer from or have suffered from ${disqualification[0]}. Sorry you
          are not eligible to donate, thank you for your time.`}
          />
        )}

        {(disqualification[0] === "antibiotics" ||
          disqualification[0] === "steroids" ||
          disqualification[0] === "aspirin" ||
          disqualification[0] === "Vaccinations" ||
          disqualification[0] === "alcohol" ||
          disqualification[0] === "rabies vaccine") && (
          <DisqualificationCard
            disqualification={` You are taking or have taken ${disqualification[0]} in the past 72
          hours. You are not eligible to donate at the moment, check back after
          10 days of last dose or injection`}
          />
        )}

        {disqualification[0] === "Major Minor Blood Transfusion" && (
          <DisqualificationCard
            disqualification={`You had ${disqualification[0]}. You are not eligible to donate at the
        moment. Please check back in 3 months`}
          />
        )}

        {(disqualification[0] === "menstruation" ||
          disqualification[0] === "pregnant" ||
          disqualification[0] === "abortion in the last three months" ||
          disqualification[0] === "child less than 1 one year") && (
          <DisqualificationCard
            disqualification="You are not eligible to donate at the moment. 
          Please come back 
          after the child is past 1 year"
          />
        )}
      </div>
    );
  }
  return (
    <React.Fragment>
      <CssBaseline />
      <Header />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          alignContent: "center",
          margin: "auto",
        }}
      >
        <h4>Donor's Dashboard</h4>
      </Box>

      <Box>
        <div>
          {/* <DonationPenaltyPanel disqualification={disqualification} /> */}
          <h3
            style={{
              maxWidth: 300,
              marginTop: 20,
              marginBottom: 20,
              marginLeft: 10,
            }}
          >
            {donationHis !== null &&
              donationHis.length !== 0 &&
              "Donation History:"}
          </h3>
          {donationHis !== null && donationHis.length !== 0 ? (
            donationHis.map((item) => (
              <div
                style={{
                  margin: 10,
                  padding: 10,
                }}
              >
                <Accordion sx={{ maxWidth: 500 }} key={item.dhsId}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography> User ID: {item.userId}</Typography>
                    &nbsp;&nbsp;&nbsp;
                    <br />
                    <Typography> Donation Date: {item.donationDate}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <p style={{ maxWidth: 500 }}>
                      {" "}
                      Do You Feel Well Today: {item.feelWellToday}
                    </p>
                    <p style={{ maxWidth: 500 }}>
                      {" "}
                      Discomfort After Donation : {item.donationDiscomfort}
                    </p>
                    <p style={{ maxWidth: 500 }}>
                      {" "}
                      Tattooing: {item.Tattooing}
                    </p>
                    <p style={{ maxWidth: 500 }}> Piercing: {item.Piercing}</p>
                    <p style={{ maxWidth: 500 }}>
                      {" "}
                      Dental Extraction: {item.DentalExtraction}
                    </p>
                    <p style={{ maxWidth: 500 }}>
                      {" "}
                      HeartDisease: {item.HeartDisease}
                    </p>
                    <p style={{ maxWidth: 500 }}>
                      {" "}
                      Cancer Malignant Disease: {item.CancerMalignantDisease}
                    </p>
                    <p style={{ maxWidth: 500 }}> Diabetes: {item.Diabetes}</p>
                    <p style={{ maxWidth: 500 }}>
                      {" "}
                      SickleCell: {item.SickleCell}
                    </p>
                    <p style={{ maxWidth: 500 }}>
                      {" "}
                      Hepatitis BC: {item.HepatitisBC}
                    </p>
                    <p style={{ maxWidth: 500 }}> STD: {item.STD}</p>
                    <p style={{ maxWidth: 500 }}>
                      {" "}
                      Typhoid in the last 1 year: {item.TyphoidLast1Yr}
                    </p>
                    <p style={{ maxWidth: 500 }}>
                      {" "}
                      Lung Disease: {item.LungDisease}
                    </p>
                    <p style={{ maxWidth: 500 }}>
                      {" "}
                      Tuberculosis: {item.Tuberculosis}
                    </p>
                    <p style={{ maxWidth: 500 }}>
                      {" "}
                      Allergic Disease: {item.AllergicDisease}
                    </p>
                    <p style={{ maxWidth: 500 }}>
                      {" "}
                      Kidney Disease: {item.KidneyDisease}
                    </p>
                    <p style={{ maxWidth: 500 }}> Epilepsy: {item.Epilepsy}</p>
                    <p style={{ maxWidth: 500 }}>
                      {" "}
                      Abnormal Bleeding Tendency:{" "}
                      {item.AbnormalBleedingTtendency}
                    </p>
                    <p style={{ maxWidth: 500 }}>
                      {" "}
                      Jaundice in the last 1 year: {item.JaundiceLast1Yr}
                    </p>
                    <p style={{ maxWidth: 500 }}>
                      {" "}
                      Malaria in the last 6 Months: {item.Malaria6Months}
                    </p>
                    <p style={{ maxWidth: 500 }}>
                      {" "}
                      Fainting Spells: {item.FaintingSpells}
                    </p>
                    <p style={{ maxWidth: 500 }}>
                      {" "}
                      Antibiotics: {item.Antibiotics}
                    </p>
                    <p style={{ maxWidth: 500 }}> Steroids: {item.Steroids}</p>
                    <p style={{ maxWidth: 500 }}> Aspirin: {item.Aspirin}</p>
                    <p style={{ maxWidth: 500 }}>
                      {" "}
                      Vaccinations: {item.Vaccinations}
                    </p>
                    <p style={{ maxWidth: 500 }}> Alcohol: {item.Alcohol}</p>
                    <p style={{ maxWidth: 500 }}>
                      {" "}
                      Rabies Vaccine: {item.rabiesVaccine}
                    </p>
                    <p style={{ maxWidth: 500 }}>
                      {" "}
                      Surgery / Blood Transfusion in the last 6 Months:{" "}
                      {item.surgeryBloodTransfusion6Mnth}
                    </p>
                    <p style={{ maxWidth: 500 }}>
                      {" "}
                      Menstruation: {item.menstruation}
                    </p>
                    <p style={{ maxWidth: 500 }}> Pregnant: {item.pregnant}</p>
                    <p style={{ maxWidth: 500 }}>
                      {" "}
                      Abortionin the last 3 Months: {item.abortion3Mths}
                    </p>
                    <p style={{ maxWidth: 500 }}>
                      {" "}
                      Do you have a child less than one year ago?:{" "}
                      {item.childLessThan1}
                    </p>
                    <p style={{ maxWidth: 500 }}>
                      {" "}
                      Rheumatic Fever: {item.rheumaticFever}
                    </p>
                    <p style={{ maxWidth: 500 }}>
                      {" "}
                      Chest Pain: {item.chestPain}
                    </p>
                    <p style={{ maxWidth: 500 }}> Asthma: {item.asthma}</p>
                    <p style={{ maxWidth: 500 }}>
                      {" "}
                      In the past 12 months, have you been in close contact with
                      a person with Hepatitis?: {item.closeContactHepatitis}
                    </p>
                    <p style={{ maxWidth: 500 }}>
                      {" "}
                      Have you ever been treated for pimples with drugs such as
                      Roaccutane, Tigason, Pros car or Propecia?:{" "}
                      {item.pimpleRoaccutaneTigason}
                    </p>
                    <p style={{ maxWidth: 500 }}>
                      {" "}
                      injectionPitutaryHormone: {item.injectionPitutaryHormone}
                    </p>
                    <p style={{ maxWidth: 500 }}>
                      {" "}
                      Have you ever received a tissue or organ transplant e.g,
                      Corneo, dura mater, kidney, liver, bone marrow?:{" "}
                      {item.tissueOrgnaTransplant}
                    </p>
                    <p style={{ maxWidth: 500 }}>
                      {" "}
                      Have you or your close relatives had an unexplained brain
                      condition or been diagnosed with a condition called
                      Cruelzfeld-Jakob disease (human form of mac cow disease)
                      ?: {item.cruelzfeldJakob}
                    </p>
                    <p style={{ maxWidth: 500 }}>
                      {" "}
                      If had malaria in the last two weeks what treatment did
                      you receive: {item.iFMalaria2WeeksWhaDrug}
                    </p>
                    <p style={{ maxWidth: 500 }}>
                      {" "}
                      In the past six months have you had more than one sex
                      partner, engaged in casual sex with someone whose sexual
                      background you don't know?: {item.past6MthsSexUnknownPart}
                    </p>
                    <p style={{ maxWidth: 500 }}>
                      {" "}
                      In the past five years, have you had male to male sex?:{" "}
                      {item.past5YrsM2MSx}
                    </p>
                    <p style={{ maxWidth: 500 }}>
                      {" "}
                      In the past five years, have you had sex with a Male or
                      female prostitute (escort or sex worker) or exchanged
                      money, drugs, goods or favours in return for sex?:{" "}
                      {item.past5YrsSxProstitute}
                    </p>
                    <p style={{ maxWidth: 500 }}>
                      {" "}
                      In the past five years, have you had sex with a Male or
                      female prostitute (escort or sex worker) or exchanged
                      money, drugs, goods or favours in return for sex?:{" "}
                      {item.past5YrsSTD}
                    </p>
                    <p style={{ maxWidth: 500 }}>
                      {" "}
                      Are you HIV positive?: {item.HIVPositive}
                    </p>
                    <p style={{ maxWidth: 500 }}>
                      {" "}
                      In the past twelve months, have you had accidential
                      exposure to blood or body fluids or been the victim of
                      sexual assault?: {item.past12MthsSxAssault}
                    </p>
                    <p style={{ maxWidth: 500 }}>
                      {" "}
                      Have you ever injected yourself, or been injected with any
                      drug or substance including steroids, not prescribed by a
                      doctor?: {item.injectedUrself}
                    </p>
                    <p style={{ maxWidth: 500 }}>
                      {" "}
                      To your knowledge, does any of the above apply to your sex
                      partner?: {item.appliedSxPartner}
                    </p>
                    <p style={{ maxWidth: 500 }}>
                      {" "}
                      donatTestd4HivAids: {item.donatTestd4HivAids}
                    </p>
                    <p style={{ maxWidth: 500 }}>
                      {" "}
                      In the last four hours have you had meal or snack?:{" "}
                      {item.inLast4HoursHadMealSnack}
                    </p>
                    <p style={{ maxWidth: 500 }}>
                      {" "}
                      In the past twelve months, have you suffered from nigth
                      sweets, unintentional weigth loss, persistent fever,
                      diarrhoea or swollen glands?:{" "}
                      {item.past12MonSuffNigthSweatUnWeigtLossFeverDiSw}
                    </p>
                    <p style={{ maxWidth: 500 }}>
                      {" "}
                      Are you involved in a hazardous occupation such as driving
                      a public or heavy duty , flying an aeroplane, working on
                      scaffolding, etc that might endanger you or anyone else if
                      you become ligtheaded or faint?:{" "}
                      {item.hazardousOccupation}
                    </p>
                    <p style={{ maxWidth: 500 }}>
                      {" "}
                      Have you been receiving any medical treatment or taking
                      any medication?: {item.anyMedication}
                    </p>
                    <p style={{ maxWidth: 500 }}>
                      {" "}
                      In the past four weeks, have you experienced vomitting or
                      diarrhoea?: {item.vomittingDiarrhoea}
                    </p>
                    <p style={{ maxWidth: 500 }}>
                      {" "}
                      Do you have high(or low) blood pressure?:{" "}
                      {item.lowHighBloodPressure}
                    </p>
                    <p style={{ maxWidth: 500 }}>
                      {" "}
                      Would you like to be informed about any abnormal test
                      result at the address furnished by you?: {item.feedback}
                    </p>
                    <p style={{ maxWidth: 500 }}>
                      {" "}
                      Have you read and understood all the information presented
                      and answered all the questions truthfully? As any
                      incorrect statement or concealment may affect your health
                      or may harm the recipient?: {item.readUnderstoodAll}
                    </p>
                    <p style={{ maxWidth: 500 }}>
                      {" "}
                      Donation local government area : {item.donationLga}
                    </p>
                    <p style={{ maxWidth: 500 }}>
                      {" "}
                      Donation state : {item.donationState}
                    </p>
                    <p style={{ maxWidth: 500 }}>
                      {" "}
                      Suggested facilities :{" "}
                      {suggestedFacilities(item.suggestedFacilities)}
                    </p>
                  </AccordionDetails>
                </Accordion>
              </div>
            ))
          ) : (
            <Stack spacing={2} alignItems="center" justifyContent="center">
              <img
                src="/undraw_Dashboar.png"
                alt=""
                style={{ maxHeight: 400, maxWidth: 400, margin: "auto" }}
              />
              <div> Welcome to your Dashboard</div>
              <div>
                To Begin, <Link to="/consent">Fill Donation Form</Link>
              </div>
            </Stack>
          )}
        </div>
      </Box>
    </React.Fragment>
  );
}
