import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { useStateValue } from "../utils/app-state-context";
import { API_URL } from "../utils/api";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function BloodDonationHistoryModal({ userId }) {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [donationHis, setDonationHis] = React.useState(null);
  const retrieveDonationHistory = (donorId) => {
    fetch(`${API_URL}/donorHealthStatus/${userId}`)
      .then((response) => response.json())
      .then((data) => setDonationHis(data))
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  React.useEffect(() => {
    retrieveDonationHistory();
  }, []);
  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen} variant="contained">
        Donation History
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Donation History
        </BootstrapDialogTitle>
        <DialogContent dividers>
          {donationHis !== null && donationHis.length !== 0
            ? donationHis.map((item) => (
                <div
                  style={{
                    margin: 10,
                    padding: 10,
                    backgroundColor: "lightgrey",
                  }}
                >
                  <p>
                    Donation Date:{" "}
                    <span style={{ color: "purple", fontWeight: "bold" }}>
                      {item.donationDate}
                    </span>
                  </p>
                  <p>
                    When Last Did u donate:{" "}
                    <span style={{ color: "purple", fontWeight: "bold" }}>
                      {item.whenLast}
                    </span>
                  </p>
                  <p>
                    Donated Previously:{" "}
                    <span style={{ color: "purple", fontWeight: "bold" }}>
                      {item.donatedPrev}
                    </span>
                  </p>
                  <p>
                    Do You Feel Well Today:{" "}
                    <span style={{ color: "purple", fontWeight: "bold" }}>
                      {item.feelWellToday}
                    </span>
                  </p>
                  <p>
                    Discomfort After Donation :{" "}
                    <span style={{ color: "purple", fontWeight: "bold" }}>
                      {item.donationDiscomfort}
                    </span>
                  </p>
                  <p>
                    Have Any These:{" "}
                    <span style={{ color: "purple", fontWeight: "bold" }}>
                      {item.tattEarPierDentalEx}
                    </span>
                  </p>
                  <p>
                    You Suffer From The Following Diseases:{" "}
                    <span style={{ color: "purple", fontWeight: "bold" }}>
                      {item.sufferFromDisease}
                    </span>
                  </p>
                  <p>
                    On Rabies Vaccine?{" "}
                    <span style={{ color: "purple", fontWeight: "bold" }}>
                      {item.rabiesVaccine}
                    </span>
                  </p>
                </div>
              ))
            : "No Record"}
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
