import React, { Suspense } from "react";
import Skeleton from "@mui/material/Skeleton";
import ShowHide from "../components/show-hide";
import "./../site-styles.css";
import Header from "../components/Header";
import Box from "@mui/material/Box";

const CollaboratingTeam = React.lazy(() =>
  import("../components/CollaboratingTeam")
);
const Footer = React.lazy(() => import("../components/Footer"));

export function Skeleton2() {
  return (
    <>
      <Skeleton
        sx={{ height: 190, mb: 5 }}
        animation="wave"
        variant="rectangular"
      />
      <Skeleton
        sx={{ height: 190, mb: 5 }}
        animation="wave"
        variant="rectangular"
      />
    </>
  );
}

const About = () => {
  return (
    <>
      {/* Navigation Section */}
      <Header />
      <Box p={2}>
        {/* Hero Section */}
        <Box
          id="hero-section"
          sx={{
            display: "flex",
            justifyContent: "space-around",
            flexWrap: "wrap",
            alignItems: "flex-start",
            mt: { xs: 4, sm: 4, md: 8 },
            mb: { xs: 4, sm: 4, md: 8 },
          }}
        >
          <img
            src="/blood2.jpg"
            alt="Hero Image"
            style={{ width: 500, height: "100%", objectFit: "contain" }}
          />
          <Box sx={{ maxWidth: 500 }}>
            <h1 style={{ fontFamily: "Ovo" }}>Background information </h1>
            <p
              style={{
                marginBottom: 0,
                paddingBottom: 0,
                fontFamily: "Mulish",
                textAlign: "justify",
              }}
            >
              A voluntary donation of blood is considered as the backbone of
              blood safety and safe transfusion practices. As at 2023, World
              Health Organisation (WHO), reported that the blood donation rate
              in developing countries is 5 per 1000 people as compared to 33
              donations per 1000 people in developed countries. Hence, there is
              a critical need to improve the recruitment and retention of
              voluntary blood donor populations to ensure a sustainable blood
              transfusion practice.
              <ShowHide
                item={
                  <>
                    <span style={{ display: "block", marginTop: 14 }}>
                      Towards 100% voluntary blood donation is a global frame
                      work for action jointly created by World Health
                      Organisation and International Federation of Red Cross and
                      Red Crescent Societies in 2003 with the goal of creating
                      an enabling environment for 100% voluntary non-remunerated
                      blood donation. Nigeria as a giant of Africa is far from
                      achieving this goal as majority of blood used for
                      transfusion are sourced from commercial donor after 20
                      years of the global frame work creation. The blood banking
                      system in Nigeria is still poorly developed as a number of
                      centers depend on paid donors whose hematological and
                      infectious status may not be well characterised in most
                      cases prior to blood donations. Furthermore, Nigeria, as a
                      member nation of WHO has made little progress with
                      voluntary donor recruitment. Presently, only about 5% of
                      donor bloods used in Nigeria come from voluntary donors;
                      family replacements and paid donors are still the major
                      sources of donor blood procurement. The consequences of
                      this is that many lives may still not be saved due to lack
                      of blood for donation when most needed.
                    </span>
                    <p>
                      Another challenge with commercial blood donor is the non
                      quality of blood being donated and the possibility of
                      transmitting infections usually referred to as transfusion
                      transmitted diseases (TTD) many of which go undetected. To
                      bring a change in paradigm shift, innovative means of
                      addressing the aforementioned situation needed to be in
                      place. Hence, the blood donor Hub (BloodHub) is important
                      by deviating from the commercial blood donor depended
                      based blood banking system thereby reducing maternal and
                      under five children mortalities. The BloodHub will also
                      employ social approach serving as a prototype voluntary
                      blood donor and retainership platform.
                    </p>
                  </>
                }
              />
            </p>
          </Box>
        </Box>
        {/* Objectives Section */}
        <Box
          id="donate-section"
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column-reverse",
              sm: "column-reverse",
              md: "row",
            },
            justifyContent: "space-around",
            flexWrap: "wrap",
            alignItems: "center",
            mb: { xs: 4, sm: 4 },
          }}
        >
          <div style={{ maxWidth: 500 }}>
            <h1 style={{ fontFamily: "Ovo" }}>Objectives of this Platform </h1>
            <ul style={{ fontFamily: "Mulish", textAlign: "left" }}>
              <li>
                {" "}
                To recruit, retain donors and support existing blood donation
                system
              </li>
              <li> To monitor and evaluate donor selection process</li>
              <li>
                {" "}
                To enhance availability of quality blood for transfusion
                purposes
              </li>
              <li>
                {" "}
                To determine possible risk of allogeneic blood transfusion
              </li>
              <li>
                {" "}
                To enhance surveillance and early detection of blood transfusion
                related infectious diseases
              </li>
              <li>
                {" "}
                To determine the participants perception on blood donation
              </li>
            </ul>
          </div>
          <Box sx={{ width: { sm: "100%", xs: "100%", md: "30%" } }}>
            <img
              src="/blood4.jpg"
              alt=""
              style={{ width: "100%", height: "auto" }}
            />
          </Box>
        </Box>
        <Suspense fallback={<Skeleton2 />}>
          <CollaboratingTeam />
        </Suspense>
      </Box>
      <Suspense fallback={<Skeleton2 />}>
        <Footer />
      </Suspense>
    </>
  );
};
export default About;
