import React from "react";
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { useStateValue } from "../utils/app-state-context";
import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import Header from "../components/Header";
import { API_URL } from "../utils/api";
import AdminRetrieveFacilityBloodBanks from "./AdminRetrieveFacilityBloodBanks";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import Search from "../components/Search";

export default function AdminRetrieveFacilities() {
  const [{ donorId, adminId, authAdmin }, dispatch] = useStateValue();
  const [facilities, setFacilities] = React.useState(null);
  const [filterByState, setFilterByState] = React.useState(null);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  React.useEffect(() => {
    retrieveFaclities(donorId);
  }, []);
  const focusSearch = React.useRef(null);
  const [searchVal, setsearchVal] = React.useState("");
  const [searchRes, setsearchRes] = React.useState([]);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSearchByState = (value) => {
    const byState = facilities.filter((item) => item.addressState === value);
    setFilterByState(byState);
    setAnchorEl(null);
  };
  const retrieveFaclities = () => {
    fetch(`${API_URL}/facilityProfile`)
      .then((response) => response.json())
      .then((data) => setFacilities(data))
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  // PREVENTS RERENDER FLICKERING AS USER TYPES IN SEARCH
  const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };
  // useEffect - ONLY RERENDERS WHEN searchVal IS CHANGED
  React.useEffect(() => {
    let currentsearchVal = true;
    const loadSearch = async () => {
      if (!searchVal) return setsearchRes([]);
      await sleep(350);
      const lstSearch = searchVal.split(" ");
      if (currentsearchVal) {
        const lst = [];
        for (const element of lstSearch) {
          lst.push(
            facilities.filter(
              (item) =>
                new RegExp(element, "i").exec(item.addressTown) ||
                new RegExp(element, "i").exec(item.addressState) ||
                new RegExp(element, "i").exec(item.lga) ||
                new RegExp(element, "i").exec(item.facilityName)
            )
          );
        }
        setsearchRes(lst.flat());
      }
    };
    loadSearch();
    return () => {
      currentsearchVal = false;
    };
  }, [searchVal]);
  const handleSearch = (e) => {
    e.preventDefault();
    return setsearchVal(e.target.value);
  };
  // search by town, State,
  if (!authAdmin) {
    return navigate("/admin");
  }
  return (
    <>
      <Header />
      <Stack spacing={4} direction="row" sx={{ m: 2, mt: 4 }}>
        <TextField
          sx={{ maxWidth: 600 }}
          size="small"
          fullWidth
          id="outlined-search"
          type="search"
          placeholder="Search bloods banks and facilities"
          variant="outlined"
          ref={focusSearch}
          onChange={handleSearch}
          value={searchVal}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        {/* <Search
          dataSource={facilities}
          response={(searchRes) => {
            <span>{JSON.stringify(searchRes)}</span>;
          }}
        /> */}
        {/* <div>
      <Button 
        variant="contained" 
        onClick={handleClick} 
        endIcon={<ArrowDropDownIcon />}
        style={{
          background: "#ccd5ae",
          paddingLeft: "30px",
          paddingRight: "30px",
          textTransform: "none",
        }}
        >
        Filter By States
      </Button>  
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {facilities && facilities.length !== 0 && facilities.map((item)=>
        <MenuItem onClick={()=>handleSearchByState(item.addressState)}>
          {item.addressState}
        </MenuItem>)}
      </Menu>
      </div> */}
      </Stack>

      <div>
        {searchRes && searchRes.length !== 0 ? (
          searchRes.map((item) => (
            <div
              style={{
                margin: 10,
                padding: 10,
                backgroundColor: "lightgrey",
              }}
            >
              <p>
                <span style={{ color: "purple", fontWeight: "bold" }}>
                  <AdminRetrieveFacilityBloodBanks
                    facilityId={item.facilityId}
                  />
                </span>
              </p>
              <p>
                Name:{" "}
                <span style={{ color: "purple", fontWeight: "bold" }}>
                  {item.facilityName}
                </span>
              </p>
              <p>
                Sector:{" "}
                <span style={{ color: "purple", fontWeight: "bold" }}>
                  {item.privatePublic}
                </span>
              </p>
              <p>
                Registered?:{" "}
                <span style={{ color: "purple", fontWeight: "bold" }}>
                  {item.registered}
                </span>
              </p>
              <p>
                Registered By:{" "}
                <span style={{ color: "purple", fontWeight: "bold" }}>
                  {item.registrationBody}
                </span>
              </p>
              <p>
                Blood Bank Available:{" "}
                <span style={{ color: "purple", fontWeight: "bold" }}>
                  {item.bloodBankAvailable}
                </span>
              </p>
              <p>
                Telephone:{" "}
                <span style={{ color: "purple", fontWeight: "bold" }}>
                  {item.telephone}
                </span>
              </p>
              <p>
                Email:{" "}
                <span style={{ color: "purple", fontWeight: "bold" }}>
                  {item.email}
                </span>
              </p>
              <p>
                Local Government Area:{" "}
                <span style={{ color: "purple", fontWeight: "bold" }}>
                  {item.lga}
                </span>
              </p>
              <p>
                Address:{" "}
                <span style={{ color: "purple", fontWeight: "bold" }}>
                  {`${item.addressStreet}, ${item.addressTown}`}
                </span>
              </p>
              <p>
                State:{" "}
                <span style={{ color: "purple", fontWeight: "bold" }}>
                  {item.addressState}
                </span>
              </p>
              <p>
                Contact Person:{" "}
                <span style={{ color: "purple", fontWeight: "bold" }}>
                  {item.contactPerson}
                </span>
              </p>
            </div>
          ))
        ) : (
          <span></span>
        )}
      </div>
    </>
  );
}
