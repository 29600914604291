import * as React from "react";
import { Box } from "@mui/system";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import { Field, Formik, Form } from "formik";
import * as Yup from "yup";
import { TextField } from "formik-mui";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import { FormHelperText } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { useStateValue } from "../utils/app-state-context";
import Header from "./Header";
import { API_URL } from "../utils/api";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { Select } from "formik-mui";
import Chip from "@mui/material/Chip";
import Person2Icon from "@mui/icons-material/Person2";

export default function SiginInFacilityAdmin({ role }) {
  const [pvalues, setPvalues] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });
  const [{ authDonor }, dispatch] = useStateValue();
  const [loginStatus, setLoginStatus] = React.useState("");
  const enforceConstraintAndRedirect = (hasProfile) => {
    dispatch({
      type: "PROFILE-NOT-CREATED",
      payload: {
        profileNotCreated: true,
        activateProfileLink: hasProfile !== undefined ? true : false,
      },
    });
    console.log("hasProfile from enforceConstraintAndRedirect", hasProfile);
    navigate("/facility-profile");
  };
  const hasFacilityProfile = async (facilityId) => {
    try {
      const response = await fetch(`${API_URL}/facilityProfile/${facilityId}`);
      const data = await response.json();
      if (data) {
        dispatch({
          type: "FACILITY-PROFILE",
          payload: {
            facilityProfileState: data,
            facilityId: data.facilityId,
          },
        });
      }
      return data.verified;
    } catch (error) {
      console.error("hasFacilityProfile Error:", error);
    }
  };
  const facilityExistsAndVerified = () => {
    dispatch({
      type: "PROFILE-NOT-CREATED",
      payload: {
        profileNotCreated: false,
      },
    });
    return navigate("/facility-dashboard");
  };

  const login = async (data) => {
    try {
      const response = await fetch(`${API_URL}/signin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...data }),
      });

      if (!response.ok) {
        throw new Error(response.status);
      }
      if (
        response.status === 404 ||
        response.status === 401 ||
        response.status === 500
      ) {
        setLoginStatus(response.status);
      }
      const res = await response.json();
      const isRoleAdmin = {
        authAdmin: true,
        adminId: res.userId,
        adminRole: "admin",
        authFacility: false,
        authfacilityId: "",
        facilityRole: "",
        authDonor: false,
        donorId: "",
        donorRole: "",
      };
      const isRoleFacility = {
        authAdmin: false,
        adminId: "",
        adminRole: "",
        authFacility: true,
        authfacilityId: res.userId,
        facilityRole: "facility",
        authDonor: false,
        donorId: "",
        donorRole: "",
      };
      // authAdmin
      if (res.userId) {
        dispatch({
          type: "AUTH-FACILITY",
          payload: res.role === "admin" ? isRoleAdmin : isRoleFacility,
        });
      }

      const hasProfile = await hasFacilityProfile(res.userId);
      return res.userId && res.role === "facility" && hasProfile === "Yes"
        ? facilityExistsAndVerified()
        : res.userId && res.role === "facility" && hasProfile === "No"
        ? navigate("/verification-status", { state: { status: "No" } })
        : res.userId && res.role === "facility" && hasProfile === ""
        ? navigate("/verification-status", { state: { status: "inprogress" } })
        : res.userId && res.role === "admin"
        ? navigate("/admin-dashboard")
        : enforceConstraintAndRedirect(hasProfile);
    } catch (error) {
      setLoginStatus(error);
    }
  };
  const handleClickShowPassword = () => {
    setPvalues({
      ...pvalues,
      showPassword: !pvalues.showPassword,
    });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const navigate = useNavigate();
  // React.useEffect(() => {
  //   if (authDonor) {
  //     return navigate("/donor-dashboard");
  //   }
  // }, []);

  return (
    <React.Fragment>
      <Header />
      <Box
        sx={{
          maxWidth: 400,
          margin: "auto",
          marginTop: 5,
          borderRadius: 5,
          p: 1,
        }}
      >
        {role === "admin" && (
          <Box
            sx={{
              maxWidth: "30%",
              margin: "auto",
              mb: 5,
            }}
          >
            <img src="/male_avatar.png" style={{ maxWidth: "100%" }} />
          </Box>
        )}
        {role === "facility" && (
          <div
            style={{
              backgroundImage: "url(/blood4.jpg)",
              backgroundRepeat: "no-repeat",
              height: 100,
              backgroundSize: "cover",
            }}
          >
            <Box
              sx={{
                position: "relative",
                width: "100%",
                height: 100,
                color: "white",
                bgcolor: "rgba(0, 0, 0,0.6)",
                "&:hover": { bgcolor: "rgba(0, 0, 0,.8)" },
                borderRadius: 2,
              }}
            >
              <span
                style={{
                  color: "rgba(242, 242, 242, 0.9)",
                  fontSize: 18,
                  fontWeight: "bold",
                  position: "relative",
                  top: "40%",
                  left: "5%",
                }}
              >
                Medical Facility & Blood Bank Sign In
              </span>
            </Box>
          </div>
        )}
        <Formik
          enableReinitialize={true}
          initialValues={{
            role,
            username: "",
            password: "",
          }}
          validationSchema={Yup.object({
            username: Yup.string().email().required("*Username is required"),
            password: Yup.string().required("*Password is required"),
          })}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            try {
              login(values);
            } catch (error) {
              setSubmitting(false);
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ submitForm, isSubmitting, values, handleChange }) => (
            <Form>
              <Box>
                {" "}
                {isSubmitting && <LinearProgress sx={{ my: 2 }} />}
                <Stack
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                  style={{ margin: 10 }}
                >
                  {role === "facility" && (
                    <div style={{ marginTop: 20, marginBottom: 30 }}>
                      <span style={{ color: "maroon" }}>
                        Don't have an account?
                      </span>{" "}
                      &nbsp;&nbsp;{" "}
                      <Chip
                        icon={<Person2Icon />}
                        label="Sign Up"
                        variant="standard"
                        onClick={() => navigate("/facility/sign-up")}
                      />
                    </div>
                  )}
                  {loginStatus && loginStatus.message && (
                    <span style={{ color: "red" }}>
                      Error: Username or Password is Wrong
                    </span>
                  )}

                  {loginStatus &&
                  loginStatus === "TypeError: Failed to fetch" ? (
                    <span style={{ color: "red", textAlign: "center" }}>
                      Server Connection Error
                    </span>
                  ) : loginStatus === "Error: 404" ? (
                    <span style={{ color: "red" }}>
                      Error. Username or password is wrong.
                    </span>
                  ) : (
                    ""
                  )}
                  <Field
                    component={TextField}
                    fullWidth
                    name="username"
                    size="small"
                    type="email"
                    label="Username(User Email)"
                    variant="standard"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <Field
                    component={TextField}
                    fullWidth
                    name="password"
                    size="small"
                    label="Password"
                    variant="standard"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type={pvalues.showPassword ? "text" : "password"}
                    value={values.password}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {pvalues.showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                  style={{ margin: 10 }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={submitForm}
                    style={{
                      margin: 20,
                      background: "#0C2340",
                      paddingLeft: "40px",
                      paddingRight: "40px",
                      textTransform: "none",
                    }}
                  >
                    {isSubmitting ? "Submitting..." : "Sign In"}
                  </Button>
                </Stack>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </React.Fragment>
  );
}
