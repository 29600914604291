import { API_URL } from "./api";

export const retreieveLatestHealthStatus = async (donorId) => {
  const response = await fetch(`${API_URL}/donorHealthStatus/${donorId}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      donorAccepted: "",
    }),
  });
  if (!response.ok) {
    throw new Error(response.status);
  }
  const res = await response.json();
  return res;
  // dispatch({
  //   type: "GET-UNPROCESSED-QUE",
  //   payload: {
  //     unprocessedQue: res,
  //   },
  // });
  // console.log("GET-UNPROCESSED-QUE: ", res);
};
