import React, {useState} from 'react'

export default function ShowHide(props){
    const { color, fweight, item } = props;
    const styles = {
        container: {
            fontWeight: fweight,
            color
        }
    };
    const [show, setShow] = useState(false)
    const [text, setText] = useState(' ... more')
    const visibility =()=>{
        setShow(!show)
        show? setText('... more') : setText('... less')
    }
    return<>
    
    {show? <span style={styles.container}>&nbsp;{item}&nbsp;</span>: ''} 
    <span onClick={visibility} style={{color:'blue',cursor:'pointer'}}>
    {text}</span>
    
    </>
}
