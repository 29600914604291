import React from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { API_URL } from "../utils/api";
import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { useStateValue } from "../utils/app-state-context";
import Stack from "@mui/material/Stack";
import MedicalFacilityBloodBankView from "./MedicalFacilityBloodBankView";

function Ui({
  fname,
  mname,
  surname,
  gender,
  telephone,
  email,
  age,
  occupation,
  userId,
  dispatch,
  navigate,
}) {
  const [{ authAdmin }, _] = useStateValue();
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <span
          style={{ fontWeight: "bold" }}
        >{`${fname} ${mname} ${surname}`}</span>
      </AccordionSummary>
      <AccordionDetails>
        <span>{`Gender: ${gender}, Telephone: ${telephone}, Email: ${email},`}</span>
        <br />
        <span>{`Age: ${age}, Occupation: ${occupation},`}</span>
        <br />

        {!authAdmin && (
          <>
            <Button
              variant="outlined"
              size="small"
              sx={{ mt: 2, ml: 1, textTransform: "none" }}
              onClick={() => {
                if (userId) {
                  dispatch({
                    type: "DONATE-BLOOD",
                    payload: {
                      donorId: userId,
                      donorRole: "donor",
                    },
                  });
                }
                navigate("/blood-bank");
              }}
            >
              Store Blood
            </Button>
            <Button
              variant="outlined"
              size="small"
              sx={{ mt: 2, ml: 1, textTransform: "none" }}
              onClick={() => {
                if (userId) {
                  dispatch({
                    type: "DONATE-BLOOD",
                    payload: {
                      donorId: userId,
                      donorRole: "donor",
                    },
                  });
                }
                navigate("/blood-bank-retrieve-donation-history");
              }}
            >
              Open Questionnaire
            </Button>
          </>
        )}
        <Button
          variant="outlined"
          size="small"
          sx={{ ml: 2, mt: 2, textTransform: "none" }}
          onClick={() => {
            if (userId) {
              dispatch({
                type: "DONATION-HISTORY",
                payload: {
                  donorId: userId,
                  donorRole: "donor",
                },
              });
            }
            navigate("/admin-retrieve-donation-history");
          }}
        >
          Donation History
        </Button>
      </AccordionDetails>
    </Accordion>
  );
}

export default function SearchDonor() {
  const [{ facilityProfileState, authfacilityId }, dispatch] = useStateValue();
  const [donors, setDonors] = React.useState(null);
  const [donorHealthStatus, setDonorHealthStatus] = React.useState(null);
  const navigate = useNavigate();

  const retrieveDonors = async () => {
    try {
      const response = await fetch(`${API_URL}/dornorProfile`);
      const data = await response.json();
      setDonors(data);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const getUnprocessedQusLst4Fac = async () => {
    try {
      const response = await fetch(`${API_URL}/donorHealthStatus`);
      const data = await response.json();
      setDonorHealthStatus(data);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  React.useEffect(() => {
    retrieveDonors();
    getUnprocessedQusLst4Fac();
  }, []);
  const filteredUnprocessedDonor = (variant) => {
    const unprocessedDonors =
      donorHealthStatus &&
      donorHealthStatus.filter((item) =>
        variant === "unpro"
          ? Number(item.suggestedFacilities) === Number(authfacilityId) &&
            item.donorAccepted === ""
          : Number(item.suggestedFacilities) === Number(authfacilityId) &&
            item.donorAccepted !== ""
      );
    const userIdLst =
      unprocessedDonors && unprocessedDonors.map((item) => item.userId);
    const lst = [];
    if (userIdLst) {
      for (let i = 0; i < userIdLst.length; i++) {
        for (let j = 0; j < donors.length; j++) {
          if (donors && Number(donors[j].userId) === Number(userIdLst[i])) {
            lst.push(donors[j]);
          }
        }
      }
    }
    return lst;
  };

  const [searchVal, setSearchVal] = React.useState([]);
  const handleChange = (e) => {
    let targetVal = e.target.value.toUpperCase().trim();
    const results =
      filteredUnprocessedDonor("unpro") &&
      filteredUnprocessedDonor("unpro")
        .filter(
          ({ fname, mname, surname }) =>
            fname.toUpperCase() == targetVal ||
            mname.toUpperCase() == targetVal ||
            surname.toUpperCase() == targetVal
        )
        .map(
          ({
            userId,
            fname,
            mname,
            gender,
            surname,
            telephone,
            email,
            age,
            occupation,
          }) => (
            <Ui
              fname={fname}
              mname={mname}
              surname={surname}
              gender={gender}
              telephone={telephone}
              email={email}
              age={age}
              occupation={occupation}
              userId={userId}
              dispatch={dispatch}
              navigate={navigate}
            />
          )
        );
    results && setSearchVal(results);
  };
  const fullList =
    filteredUnprocessedDonor("unpro") !== null &&
    filteredUnprocessedDonor("unpro").map(
      ({
        userId,
        fname,
        mname,
        gender,
        surname,
        telephone,
        email,
        age,
        occupation,
      }) => (
        <Ui
          fname={fname}
          mname={mname}
          surname={surname}
          gender={gender}
          telephone={telephone}
          email={email}
          age={age}
          occupation={occupation}
          userId={userId}
          dispatch={dispatch}
          navigate={navigate}
        />
      )
    );

  const sideBarList =
    filteredUnprocessedDonor("pro") !== null &&
    filteredUnprocessedDonor("pro").map(
      ({
        userId,
        fname,
        mname,
        gender,
        surname,
        telephone,
        email,
        age,
        occupation,
      }) => (
        <Ui
          fname={fname}
          mname={mname}
          surname={surname}
          gender={gender}
          telephone={telephone}
          email={email}
          age={age}
          occupation={occupation}
          userId={userId}
          dispatch={dispatch}
          navigate={navigate}
        />
      )
    );
  // if (!authAdmin) {
  //   return navigate("/admin");
  // }
  return (
    <Box sx={{ mx: 1 }}>
      <Header />
      {facilityProfileState &&
        facilityProfileState.facilityType !== "Blood Bank" && (
          <MedicalFacilityBloodBankView />
        )}
      {facilityProfileState &&
        facilityProfileState.facilityType === "Blood Bank" && (
          <Stack
            direction={{
              xs: "column-reverse",
              sm: "column-reverse",
              md: "row",
            }}
          >
            {sideBarList && (
              <Stack>
                <Box sx={{ m: 1 }}>Processed Questionnaires</Box>
                {sideBarList}
              </Stack>
            )}
            <Box sx={{ maxWidth: 500, m: "auto" }}>
              <p>Donation Questionnaires Awaiting Processing</p>
              <TextField
                id="standard-basic"
                label="Search donors by name"
                variant="standard"
                multiline
                fullWidth
                margin="dense"
                sx={{ mb: 3 }}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment onClick={handleChange} position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
              {searchVal !== undefined && searchVal.length > 0
                ? searchVal
                : fullList}
              {fullList && fullList.length === 0 && (
                <span>No Donation Questionnaires Awaiting Processing</span>
              )}
            </Box>
          </Stack>
        )}
    </Box>
  );
}
