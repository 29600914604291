import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { API_URL } from "../utils/api";
import BloodDonationHistoryModal from "./BloodDonationHistoryModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

export default function DonorDetailViewModal({ userId }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    return setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const [donor, setDonors] = React.useState(null);
  const retrieveProfile = () => {
    fetch(`${API_URL}/dornorProfile/${userId}`)
      .then((response) => response.json())
      .then((data) => setDonors(data))
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  React.useEffect(() => {
    retrieveProfile();
  }, []);

  return (
    <div>
      <Button onClick={handleOpen} style={{ textTransform: "none" }}>
        Donor's Details
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Donor's Details
            <img
              src="/close-icon.png"
              style={{
                height: 25,
                weight: 25,
                float: "right",
                cursor: "pointer",
              }}
              alt="X"
              onClick={handleClose}
            />
          </Typography>
          {donor !== null ? (
            <div
              style={{
                padding: 2,
                minWidth: 300,
                marginLeft: 10,
                margin: "auto",
              }}
            >
              <p>
                Donor Name:{" "}
                <span style={{ color: "purple", fontWeight: "bold" }}>
                  {`${donor.fname} ${donor.mname} ${donor.surname}`}
                </span>
              </p>
              <p>
                Gender:{" "}
                <span style={{ color: "purple", fontWeight: "bold" }}>
                  {donor.gender}
                </span>
              </p>
              <p>
                Telephone No:{" "}
                <span style={{ color: "purple", fontWeight: "bold" }}>
                  {donor.telephone}
                </span>
              </p>
              <p>
                Email: :{" "}
                <span style={{ color: "purple", fontWeight: "bold" }}>
                  {donor.email}
                </span>
              </p>
              <p>
                Age:{" "}
                <span style={{ color: "purple", fontWeight: "bold" }}>
                  {donor.age} years
                </span>
              </p>
              <p>
                Occupation:{" "}
                <span style={{ color: "purple", fontWeight: "bold" }}>
                  {donor.occupation}
                </span>
              </p>
              <p>
                <BloodDonationHistoryModal userId={donor.userId} />
              </p>
            </div>
          ) : (
            <div
              style={{
                padding: 2,
                minWidth: 300,
                marginLeft: 20,
                margin: "auto",
              }}
            >
              No Donor Record.
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
}
